const ROUTE_CHILD: Record<
	string,
	{
		parent: string;
		child: string;
	}
> = {
	"/dashboard/services-management/categories-addition": {
		parent: "Quản lý danh sách danh mục",
		child: "Thêm danh mục mới",
	},
	"/dashboard/services-management/services-addition": {
		parent: "Quản lý dịch vụ",
		child: "Thêm dịch vụ mới",
	},
    "/dashboard/services-management/categories-editing/": {
        parent: "Chi tiết danh mục",
        child: "Cập nhật danh mục",
    },
    "/dashboard/services-management/services-editing/": {
        parent: "Chi tiết danh mục",
        child: "Cập nhật dịch vu",
	},
    "/dashboard/services-management/": {
		parent: "Quản lý dịch vụ",
		child: "Chi tiết danh mục",
	},
	"/dashboard/service-order/": {
		parent: "Quản lý danh sách đơn dịch vụ",
		child: "Chi tiết đơn",
	},
} as const;

export const findRouteChild = (pathname: string) => {
  for (const route in ROUTE_CHILD) {
    if (pathname.startsWith(route)) {
      return ROUTE_CHILD[route];
    }
  }
  return null;
}
import {
  TYPE_SPECIAL_ITEMS,
  TYPE_SPECIAL_ITEMS_ICON,
} from "constants/servicesAddition";
import { selectionType } from "constants/servicesAddition";

type specialItemSelectProps = {
  typeSpecialItemSelected: selectionType;
  onChangeTypeSpecialItem: (
    newTypeSpecialItem: selectionType
  ) => void;
};

export const SpecialItemSelect = ({
  typeSpecialItemSelected,
  onChangeTypeSpecialItem,
}: specialItemSelectProps) => {
  const handleTypeSpecialItemChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedIndex = event.target.selectedIndex;
    const selectedTypeSpecialItem = TYPE_SPECIAL_ITEMS[selectedIndex];
    onChangeTypeSpecialItem(selectedTypeSpecialItem);
  };
  return (
    <div className="relative">
      <select
        id="countries"
        value={typeSpecialItemSelected.value}
        className=" bg-yellow-light text-sm rounded-md w-full py-1 px-8 focus:border-none min-w-48"
        onChange={handleTypeSpecialItemChange}
      >
        {TYPE_SPECIAL_ITEMS.map((typeSpecialItem, indexTypeSpecialItem) => (
          <option
            value={typeSpecialItem.value}
            className="bg-white text-black"
            key={`${typeSpecialItem.value}-${indexTypeSpecialItem}`}
          >
            {typeSpecialItem.label}
          </option>
        ))}
      </select>
      <div className=" absolute top-1 left-2 text-black">
        {TYPE_SPECIAL_ITEMS_ICON[typeSpecialItemSelected.value]}
      </div>
    </div>
  );
};

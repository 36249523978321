import { ApiHelper, detoiApi } from "businessLogic/redux/adminApi";
import { ADMIN_CATEGORY_KEY } from "pages/AdminPermission";

type SetRoleAdminProps = {
    adminId: string;
    roleName: ADMIN_CATEGORY_KEY;
}

const api = detoiApi.injectEndpoints({
    endpoints: (builder) => ({
        getAdminAll: builder.query({
            query: () => ApiHelper.get(`/api/v1/admin/all`, {}),
        }),
        setRoleAdmin: builder.mutation({
            query: (data: SetRoleAdminProps) => ApiHelper.post(`/api/v1/admin/role-granting`, { data })
        }),
    }),
});

export const {
    useGetAdminAllQuery,
    useSetRoleAdminMutation,
    endpoints: {
        getAdminAll,
        setRoleAdmin
    }
} = api;

import { ReactNode } from "react";

type subtitleProps = {
	children: ReactNode;
	color?: "primary" | "black" | "rose";
	style?: string;
	align?: "center" | "left" | "right" | "justify";
};

export const Subtitle = ({
	children,
	style = "",
	color = "primary",
	align = "left",
}: subtitleProps) => {
	return (
		<p className={`text-${color} text-lg font-bold text-${align} ${style}`}>
			{children}
		</p>
	);
};

import {
  Subtitle,
  VerticalSpacer,
  Paragraph,
  NormalInput,
} from "presentational/atoms";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { serviceType } from "services/lib/servicesService/type";

type props = {
  control: Control<any>;
  errors: FieldErrors<serviceType>;
};

export const PriceSection = ({ control, errors }: props) => {
  return (
    <div>
      <Subtitle color="black">Giá sàn</Subtitle>
      <VerticalSpacer size="l" />
      <div className="border border-red-lightShade border-solid w-full rounded-md p-3">
        <Paragraph color="blueShade-mediumLightShade" fontWeight="semibold">
          Giá sàn của dịch vụ
        </Paragraph>
        <VerticalSpacer size="m" />
        <div className="relative">
          <Controller
            name="basePrice"
            control={control}
            render={({ field }) => (
              <NormalInput
                value={field.value}
                onChange={field.onChange}
                placeholder="Nhập giá sàn"
                isIcon={true}
              />
            )}
          />
          <i className="fi fi-rs-dollar absolute right-2 top-1" />
        </div>
        <div className={`${errors.basePrice ? "block mt-1" : "hidden"}`}>
          <Paragraph color="rose" size="xs" fontWeight="semibold">
            {errors.basePrice?.message}
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

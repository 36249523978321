import { ApiHelper, detoiApi } from "businessLogic/redux/adminApi";

export type overviewParams = {
    "income": {
        "month": number,
        "year": number
    },
    "discount": {
        "month": number,
        "year": number
    },
    "newUsers": {
        "month": number,
        "year": number
    },
    "overview": {
        "month": number,
        "year": number
    }
}

const api = detoiApi.injectEndpoints({
    endpoints: (builder) => ({
        getOverview: builder.mutation({
            query: (data: overviewParams) => ApiHelper.post(`/api/v1/admin/overview`, {data}),
        }),
    }),
});

export const {
    useGetOverviewMutation,
    endpoints: { getOverview }
} = api;
import React from "react";

type avatarProps = {
  link: string;
  size?: "sm" | "md" | "lg" | "base" | "xs";
};

const sizeMap = {
  xs: 1.25,
  sm: 1.5,
  base: 2,
  md: 2.5,
  lg: 3,
};

export const Avatar: React.FC<avatarProps> = ({ link, size = "md" }) => {
  const pixelSize = sizeMap[size];
  return (
    <img
      className="rounded-full object-cover"
      src={link}
      alt=""
      style={{ width: `${pixelSize}rem`, height: `${pixelSize}rem` }}
    />
  );
};

import { BaseBtn } from "presentational/atoms/button";
import { SidebarLinks } from "./SidebarLinks";
import { sideBarRoute } from "constants/sideBarRoute";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import { useSession } from "businessLogic/hook";

export const Sidebar = () => {
  const navigate = useNavigate();
  const { signOut, servicesPermission } = useSession();
  return (
    <div className={`flex min-h-full flex-col bg-black pb-10  px-10 w-[17vw]`}>
      <div className={`mt-5 mb-8 flex justify-center`}>
        <img
          src="/assets/logo.png"
          alt="logo"
          onClick={() => navigate(PAGES.BASE)}
          className="cursor-pointer"
        />
      </div>
      <ul className="mb-auto pt-1 h-px">
        <SidebarLinks routes={sideBarRoute.filter(r =>servicesPermission[r.shortName as string] === true)} />
      </ul>
      <BaseBtn title={"Đăng xuất"} onPress={signOut} color="white" />
    </div>
  );
};

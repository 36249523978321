import { TYPE_SPECIAL_ITEMS, selectionType } from "constants/servicesAddition";
import {
  BaseBtn,
  NormalInput,
  Paragraph,
  VerticalSpacer,
} from "presentational/atoms";
import { SpecialItemSelect } from "presentational/atoms/select/SpecialItemSelect";
import { DraggableInputQuestionGroup } from "../inputGroup";
import { useManageServiceRequirements } from "businessLogic/context";
import { infoType } from "services/entities/index";

type specialSelectButtonGroupProps = {
  onChangeTypeInfo: (
    newTypeSpecialItem: selectionType,
    infoIndex: number
  ) => void;
  infoItem: infoType;
  specialSelectId: string;
  optionIndex: number;
  infoIndex: number;
};

export const SpecialSelectButtonGroup = ({
  onChangeTypeInfo,
  infoItem,
  specialSelectId,
  optionIndex,
  infoIndex,
}: specialSelectButtonGroupProps) => {
  const { onChangeValue } = useManageServiceRequirements();
  if(infoItem.type !== 'button_group') return null;
  const { buttons, label, type, validation } = infoItem;
  const swapButtons = (destinationIndex: number, sourceIndex: number) => {
    onChangeValue({
      type: "special-swap-buttonGroup-button",
      destinationIndex,
      sourceIndex,
      serviceRequirementId: specialSelectId,
      infoIndex,
      optionIndex,
    });
  };

  const removeButton = (buttonIndex: number) => {
    onChangeValue({
      type: "special-remove-buttonGroup-button",
      serviceRequirementId: specialSelectId,
      infoIndex,
      optionIndex,
      buttonIndex,
    });
  };

  const updateButton = (buttonIndex: number, newContent: string) => {
    onChangeValue({
      type: "special-update-buttonGroup-button",
      serviceRequirementId: specialSelectId,
      infoIndex,
      optionIndex,
      buttonIndex,
      newButtonContent: newContent,
    });
  };

  const addButton = () => {
    onChangeValue({
      type: "special-add-buttonGroup-button",
      serviceRequirementId: specialSelectId,
      infoIndex,
      optionIndex,
    });
  };
  return (
    <div className="shadow-md border border-gray-light border-solid w-full rounded-md p-6 pr-0 flex items-center justify-between">
      <div className="w-4/5">
        <div className="flex gap-14 justify-start items-end pr-10">
          <SpecialItemSelect
            typeSpecialItemSelected={TYPE_SPECIAL_ITEMS[1]}
            onChangeTypeSpecialItem={(value) =>
              onChangeTypeInfo(value, infoIndex)
            }
          />
          <div className="flex gap-4 items-center w-48">
            <Paragraph color="blueShade-mediumLightShade" fontWeight="semibold">
              Label
            </Paragraph>
            <NormalInput
              value={label}
              onChange={(value) =>
                onChangeValue({
                  type: "special-update-buttonGroup-label",
                  serviceRequirementId: specialSelectId,
                  infoIndex,
                  optionIndex,
                  newLabel: value,
                })
              }
              placeholder="Nhập label"
            />
          </div>
        </div>

        <VerticalSpacer size="xxl" />
        <div>
          <Paragraph
            color="blueShade-mediumLightShade"
            align="left"
            fontWeight="semibold"
          >
            Buttons
          </Paragraph>
          <DraggableInputQuestionGroup
            buttons={buttons}
            swapButtons={swapButtons}
            removeButton={removeButton}
            updateButton={updateButton}
            addButton={addButton}
          />
        </div>
      </div>
      <div className="h-full flex-1 w-fit gap-5 flex items-center justify-center ">
        <BaseBtn
          title=""
          onPress={() => {
            onChangeValue({
              type: "special-update-option-info-duplicate",
              serviceRequirementId: specialSelectId,
              infoIndex,
              optionIndex,
            });
          }}
          icon={<i className="fi fi-rr-duplicate" />}
        />
        <BaseBtn
          title=""
          onPress={() => {
            onChangeValue({
              type: "special-update-option-info-remove",
              serviceRequirementId: specialSelectId,
              infoIndex,
              optionIndex,
            });
          }}
          icon={<i className="fi fi-ss-trash" />}
        />
      </div>
    </div>
  );
};

import { Center, VStack, Text } from '@chakra-ui/react'

export const ErrorItem = () => {
  return (
    <Center minH={200}>
      <VStack gap={4}>
        <Text>Something went wrong</Text>
        <i className="fi fi-sr-not-found text-gray-light text-3xl" ></i>
      </VStack>
    </Center>
  )
}

import React, { useEffect } from 'react';

type props = {
  textInput: string;
  onChange: (value: string) => void;
  onAdd: () => void;
  placeholder?: string
};

export const TagInput: React.FC<props> = ({ textInput, onChange, onAdd, placeholder }) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onAdd();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [onAdd]);

  return (
    <div className="relative w-full">
      <input
        className="border rounded w-full py-2 pl-3 pr-7 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-red-lightShade"
        type="text"
        value={textInput}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder || ''}
      />
      <div
        className="absolute inset-y-0 end-0 flex items-center pe-3 cursor-pointer"
        onClick={onAdd}
      >
        <i className="fi fi-br-plus" />
      </div>
    </div>
  );
};

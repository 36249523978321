import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import { Paragraph } from "presentational/atoms";
import { ReactNode } from "react";

type complaintDetailTableProps = {
  title: string[];
  content: string[];
};

export const ComplaintDetailTable = ({
  title,
  content,
}: complaintDetailTableProps) => {
  return (
    <TableContainer className="bg-white w-full rounded-md shadow-md">
      <Table>
        <Thead className="bg-blueShade-lightShade">
          <Tr>
            {title.map((item) => (
              <Th textTransform="none" textAlign={"center"} paddingX="0">
                <Paragraph fontWeight="bold" size="xs" align="center">
                  {item}
                </Paragraph>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          <Tr className="hover:bg-blueShade-lightShade cursor-pointer">
            {content.map((rowContent) => {
              return (
                <Td border="none" className="text-sm" textAlign="center">
                  {rowContent}
                </Td>
              );
            })}
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

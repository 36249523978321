import { AxiosError } from 'axios'
import { ADMIN_CATEGORY_KEY } from 'pages/AdminPermission'
import { createContext } from 'react'

export type User = {
  fullName: string,
  avatar: string,
  phone: string,
  id: string,
  role: ADMIN_CATEGORY_KEY
}

export type SignInCredentials = {
  email: string
  password: string
}

export type servicesPermissionType = {
  [key: string]: boolean;
} & {
  overview: boolean,
  userManagement: boolean,
  order: boolean,
  complaint: boolean,
  service: boolean,
  adminPermission: boolean,
  payment: boolean
}

export type AuthContextData = {
  user?: User
  isAuthenticated: boolean
  loadingUserData: boolean
  signIn: (credentials: SignInCredentials, callback: () => void) => any
  signOut: () => void
  servicesPermission: servicesPermissionType
}

export const AuthContext = createContext({} as AuthContextData)


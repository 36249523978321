import { useManageServiceRequirements } from "businessLogic/context";
import { AVAILABLE_FA_ICON } from "constants/FAIcon";
import {
  TYPE_SERVICES_ADDITION,
  TYPE_VALIDATION,
  TYPE_VALIDATION_NUMBER,
  TYPE_VALIDATION_TEXT,
  serviceRequirementOptions,
} from "constants/servicesAddition";
import { initValidationMin } from "helpers/InitServiceRequirement";
// import { initItemInputMethod } from "helpers/InitServiceRequirement";
import {
  BaseBtn,
  BorderBottomInput,
  Divider,
  IconSelect,
  NormalInput,
  NormalSelect,
  NormalSwitch,
  Paragraph,
  TypeServiceSelect,
  VerticalSpacer,
} from "presentational/atoms";
import React from "react";
import { useMemo } from "react";
import { toast } from "react-toastify";
import { IUIServiceRequirement } from "services/entities/index";
// import { inputServiceRequirement } from "services/lib/servicesService/type";

type servicesInputCreationProps = {
  curretnServiceRequirement: IUIServiceRequirement;
  errors: string[];
};

export const ServicesInputCreation = ({
  curretnServiceRequirement,
  errors,
}: servicesInputCreationProps) => {
  const {
    id,
    inputMethod,
    isValidate,
    label,
    placeholder,
    labelIcon,
    isRequired,
  } = curretnServiceRequirement;

  const { onChangeValue, onChangeType, onDuplicate, onRemove } =
    useManageServiceRequirements();
  const typeSelected: serviceRequirementOptions = useMemo(() => {
    return (
      TYPE_SERVICES_ADDITION.find(
        (service) => service.value === inputMethod.method.name
      ) ?? TYPE_SERVICES_ADDITION[0]
    );
  }, [inputMethod]);
  const isEnableAddValidate = useMemo(() => {
    return inputMethod.validation.every(
      (item) =>
        item.name !== "required" && item.message && item.message.trim().length
    );
  }, [inputMethod]);
  return (
    <div>
      <div className="flex gap-20 justify-between">
        <div className="w-1/3">
          <BorderBottomInput
            value={label}
            onChange={(value) => {
              onChangeValue({
                type: "input-update-label",
                serviceRequirementId: id,
                newLabel: value,
              });
            }}
            placeholder="Nhập tiêu đề"
          />
        </div>
        <div className="flex gap-8 items-end">
          <div className="flex gap-3 items-center">
            <Paragraph fontWeight="semibold">Required</Paragraph>
            <NormalSwitch
              isValidate={isRequired === true}
              setIsValidate={(isRequired) => {
                onChangeValue({
                  type: "input-update-required",
                  isRequired,
                  serviceRequirementId: id,
                });
              }}
            />
          </div>
          <TypeServiceSelect
            typeServiceSelected={typeSelected}
            onChangeType={onChangeType}
            idServiceRequirement={id}
          />
        </div>
      </div>

      <VerticalSpacer size="xxl" />

      <div className="flex gap-24 w-full">
        <div className="flex gap-4 items-center">
          <Paragraph color="blueShade-mediumLightShade" fontWeight="semibold">
            Icon
          </Paragraph>
          <IconSelect
            options={AVAILABLE_FA_ICON}
            value={labelIcon ?? undefined}
            onChange={(name) => {
              onChangeValue({
                type: "input-update-labelIcon",
                serviceRequirementId: id,
                newLabelIcon: name
              });
            }}
          />
        </div>
        <div className="gap-4 flex flex-1 items-center">
          <Paragraph color="blueShade-mediumLightShade" fontWeight="semibold">
            Placeholder
          </Paragraph>
          <NormalInput
            value={placeholder}
            onChange={(value) => {
              onChangeValue({
                type: "input-update-placeholder",
                serviceRequirementId: id,
                newPlaceholder: value,
              });
            }}
            placeholder="Nhập placeholder"
          />
        </div>
      </div>
      <VerticalSpacer size="xxl" />

      <div>
        <div className="flex justify-between items-center">
          <Paragraph
            color="blueShade-mediumLightShade"
            align="left"
            fontWeight="semibold"
          >
            Validation
          </Paragraph>
          <BaseBtn
            title={
              <Paragraph
                color={
                  !isValidate || !isEnableAddValidate ? "gray-medium" : "black"
                }
                fontWeight="semibold"
              >
                Thêm validate
              </Paragraph>
            }
            onPress={() => {
              if (curretnServiceRequirement.inputMethod.validation.length < 2) {
                onChangeValue({
                  type: "input-add-validation",
                  serviceRequirementId: id,
                  newInputMethod: initValidationMin,
                });
              } else {
                toast.warning("Chỉ có thể thêm tối đa 2 validate");
              }
            }}
            disabled={!isValidate || !isEnableAddValidate}
          />
        </div>

        <VerticalSpacer size="s" />
        {isValidate ? (
          curretnServiceRequirement.inputMethod.validation.map((itemMethod, validateIndex) => {
            return (
              <React.Fragment key={validateIndex}>
                <div className="flex gap-8 items-end">
                  <div className="w-1/6">
                    <NormalSelect
                      options={TYPE_VALIDATION}
                      value={curretnServiceRequirement.inputMethod.dataType}
                      onChange={(option) => {
                        onChangeValue({
                          type: "input-update-validation-dataType",
                          serviceRequirementId: id,
                          validationId: itemMethod.id,
                          newDataType: option.value as any,
                        });
                      }}
                    />
                  </div>
                  <div className="flex gap-5 items-end">
                    <NormalSelect
                      options={
                        curretnServiceRequirement.inputMethod.dataType ===
                        "text"
                          ? TYPE_VALIDATION_TEXT
                          : TYPE_VALIDATION_NUMBER
                      }
                      value={itemMethod.name}
                      onChange={(option) => {
                        onChangeValue({
                          type: "input-update-validation-name",
                          serviceRequirementId: id,
                          validationId: itemMethod.id,
                          newName: option.value as any,
                        });
                      }}
                    />
                    <div className="w-16">
                      <BorderBottomInput
                        size="xs"
                        value={(itemMethod.name !== "required"
                          ? itemMethod?.value
                          : 0
                        ).toString()}
                        onChange={(value) => {
                          onChangeValue({
                            type: "input-update-validation-value",
                            serviceRequirementId: id,
                            validationId: itemMethod.id,
                            newValue: Number(value),
                          });
                        }}
                        placeholder="Nhập value"
                      />
                    </div>
                  </div>
                  <div className="flex items-end gap-10 flex-1 justify-end">
                    <div className="w-3/4 flex gap-4 items-end">
                      <Paragraph
                        color="blueShade-mediumLightShade"
                        fontWeight="semibold"
                      >
                        Message
                      </Paragraph>
                      <BorderBottomInput
                        size="xs"
                        value={itemMethod.message}
                        onChange={(value) => {
                          onChangeValue({
                            type: "input-update-validation-message",
                            serviceRequirementId: id,
                            validationId: itemMethod.id,
                            newMessage: value,
                          });
                        }}
                        placeholder="Nhập message"
                      />
                    </div>
                    <div className="cursor-pointer">
                      <BaseBtn
                        title={<i className="fi fi-br-cross" />}
                        onPress={() => {
                          onChangeValue({
                            type: "input-remove-validation",
                            validationId: itemMethod.id,
                            serviceRequirementId: id,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <VerticalSpacer size="m" />
              </React.Fragment>
            );
          })
        ) : (
          <></>
        )}

        <VerticalSpacer size="xxl" />
        <Divider color="red-lightShade" direction="horizontal" />
        <VerticalSpacer size="xxl" />
        <div className="flex justify-between">
          <div>
            <div className={`${errors.length > 0 ? "block mt-1" : "hidden"}`}>
              <Paragraph color="rose" size="xs" fontWeight="semibold">
                Lỗi: {errors.join(", ")}
              </Paragraph>
            </div>
          </div>
          <div className="flex gap-5 items-center justify-end">
            <BaseBtn
              title=""
              onPress={() => {
                onDuplicate(id);
              }}
              icon={<i className="fi fi-rr-duplicate text-lg" />}
            />
            <BaseBtn
              title=""
              onPress={() => {
                onRemove(id);
              }}
              icon={<i className="fi fi-ss-trash text-lg" />}
            />
            <div className="h-5">
              <Divider color="black" direction="vertical" />
            </div>
            <Paragraph fontWeight="semibold">Validate</Paragraph>
            <NormalSwitch
              isValidate={isValidate === true}
              setIsValidate={(isValidate) => {
                if (isValidate) {
                  onChangeValue({
                    type: "input-update-validate",
                    serviceRequirementId: id,
                    isValidate,
                  });
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};


export type orderStatusType = {
  Id: string,
  Name: string,
  Icon: string,
  textColor: string,
  backgroundColor: string,
}

export const SERVICES_STATUS: orderStatusType[] = [
  {
    Id: "a888efc3-1d7b-445a-b38c-758737b67bad",
    Name: "Đơn vừa tạo",
    Icon: "faWandMagicSparkles",
    textColor: "#519790",
    backgroundColor: "#AEFFF7",
  },
  {
    Id: "a53e9887-2186-4ff8-a009-f7706c800b52",
    Name: "Đang ghép cặp",
    Icon: "faHandshake",
    textColor: "#FC9D24",
    backgroundColor: "#FDE8C7",
  },
  {
    Id: "8a9f22f1-3c67-49f7-bd84-ec290e4a37fd",
    Name: "Chưa hoạt động",
    Icon: "faRoadCircleXmark",
    textColor: "#587F40",
    backgroundColor: "#D2E2C8",
  },
  {
    Id: "3f98b502-7245-4e86-b7b4-7db05357a1f8",
    Name: "Đang di chuyển",
    Icon: "faPersonRunning",
    textColor: "#A2970C",
    backgroundColor: "#FAF28C",
  },
  {
    Id: "0815e9cf-98c7-4a6a-b27b-43d9b8d1f5a2",
    Name: "Đang vận chuyển",
    Icon: "faTruckFast",
    textColor: "#48A9F5",
    backgroundColor: "#EAF5FD",
  },
  {
    Id: "083cdec1-ae32-475e-8c64-62599d6fe6c1",
    Name: "Đang làm việc",
    Icon: "faPersonDigging",
    textColor: "#C278CD",
    backgroundColor: "#F3E5F5",
  },
  {
    Id: "a7d1f0e8-2a6f-4b9d-aa1c-10f58a1d5d32",
    Name: "Đã hoàn thành",
    Icon: "faPersonCircleCheck",
    textColor: "#61BA65",
    backgroundColor: "#D7F0D8",
  },
  {
    Id: "0774e101-f1a3-4186-af1e-af95a26e9ead",
    Name: "Đơn đã hủy",
    Icon: "faHandshakeSimpleSlash",
    textColor: "#F1635C",
    backgroundColor: "#FDE1E1",
  },
];

export const PROVEN_STATUS: Record<string, string> = {
  Default: "Freelancer post proven bình thường",
  CantProven: "Tôi không thể chụp ảnh hoặc ghi hình",
  CustomerDenyProven: "Khách hàng không muốn chụp ảnh hoặc ghi hình",
  ServiceDontNeedProven: "Dịch vụ này không cần minh chứng",
}

export const AccountDrawer = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed left-0  top-0 z-40 h-screen w-screen flex">
      <div
        id="drawer-navigation"
        className="p-4  bg-white w-56 shadow-md h-full"
      >
        <h5>Menu</h5>
      </div>
      <div
        className="flex-1 bg-black opacity-40 h-full w-full"
        onClick={onClose}
      />
    </div>
  );
};

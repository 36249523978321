import {
  ImageInput,
  Paragraph,
  Subtitle,
  VerticalSpacer,
} from "presentational/atoms";
import React, { useState } from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { serviceType } from "services/lib/servicesService/type";

type props = {
  control: Control<any>;
  errors: FieldErrors<serviceType>;
};
export const ImageSection = ({ control, errors }: props) => {
  const [isExceedSizeLimit, setIsExceedSizeLimit] = useState<boolean>(false);
  return (
    <React.Fragment>
      <Subtitle color="black">Ảnh dịch vụ</Subtitle>

      <VerticalSpacer size="m" />
      <Controller
        name="image"
        control={control}
        render={({ field }) => (
          <ImageInput
            source={field.value}
            setIsExceedSizeLimit={setIsExceedSizeLimit}
            onChange={field.onChange}
          />
        )}
      />

      <VerticalSpacer size="m" />
      <div
        className={`${
          isExceedSizeLimit || errors.image ? "visible" : "invisible"
        }`}
      >
        <Paragraph color="rose" size="xs" fontWeight="semibold">
          {errors.image
            ? errors.image.message
            : "Ảnh có kích thước tối đa là 1MB"}
        </Paragraph>
      </div>
    </React.Fragment>
  );
};

import { IUIServiceRequirement, infoType } from "services/entities/index";
import { v4 as uuid } from "uuid";
import { validationType } from "validators/yup";


export const initInputServiceRequirement = (): IUIServiceRequirement => {
    return {
        id: uuid(),
        inputMethod: {
            dataType: 'number',
            method: {
                name: 'input'
            },
            validation: [{
                id: uuid(),
                name: 'min',
                value: 0,
                message: ''
            }]
        },
        label: '',
        labelIcon: null,
        placeholder: '',
        key: uuid()
    }
}

export const initDropdownServiceRequirement = (): IUIServiceRequirement => {
    return {
        id: uuid(),
        inputMethod: {
            dataType: 'number',
            method: {
                name: 'select',
                options: [{
                    id: uuid(),
                    name: '',
                    description: ''
                }]
            },
            validation: []
        },
        label: '',
        labelIcon: null, // temporary change
        placeholder: '',
        key: uuid()
    }
}

export const initAddToListServiceRequirement = (): IUIServiceRequirement => {
    return {
        id: uuid(),
        inputMethod: {
            dataType: 'number',
            method: {
                name: 'add_to_list'
            },
            validation: [{
                id: uuid(),
                name: 'min',
                value: 0,
                message: ''
            }]
        },
        label: '',
        labelIcon: null, // temporary change
        placeholder: '',
        key: uuid()
    } 
}


export const initSpecialSelectServiceRequirement = (): IUIServiceRequirement => {
    return {
        id: uuid(),
        inputMethod: {
            dataType: 'number',
            method: {
                name: 'special_itemselect',
                options: [
                    {
                        name: '',
                        info: [
                            {
                                type: 'stepper',
                                label: '',
                                mask: '',
                                defaultValue: 0,
                                validation: [{
                                    id: uuid(),
                                    name: 'min',
                                    value: 0,
                                    message: '1'
                                }, {
                                    id: uuid(),
                                    name: 'max',
                                    value: 0,
                                    message: '2'
                                }]
                            }
                        ]
                    }
                ]
            },
            validation: []
        },
        label: '',
        labelIcon: null, // temporary change
        placeholder: '',
        key: uuid()
    }
}

export const initDropdownOption = {
    id: uuid(),
    name: '',
    description: ''
}

export const initSpecialOptionInfoStepper: infoType = {
    type: 'stepper',
    label: "",
    mask: "",
    defaultValue: 0,
    validation: [{
        id: uuid(),
        name: 'min',
        value: 0,
        message: '1'
    },
    {
        id: uuid(),
        name: 'max',
        value: 0,
        message: '2'

    }],
};

export const initSpecialOptionInfoButtonGroup: infoType = {
    type: 'button_group',
    label: "",
    buttons: [''],
    validation: [
        {
            id: uuid(),
            name: "required",
            message: '',
        }
    ],
    defaultValue: 1
};


export const initValidationRequired: { id: string } & validationType = {
    id: uuid(),
    name: 'required',
    message: ''
}

export const initValidationMin: { id: string } & validationType = {
    id: uuid(),
    name: 'min',
    value: 0,
    message: ''
}

export const initValidationMax: { id: string } & validationType = {
    id: uuid(), 
    name: 'max',
    value: 0,
    message: ''
} 
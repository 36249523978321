import { useManageServiceRequirements } from "businessLogic/context";
import { BaseBtn, Tittle, VerticalSpacer } from "presentational/atoms";
import {
  ActiveSection,
  PriceSection,
  ImageSection,
  KeywordSection,
  NameAndDescriptionSection,
  RequestServicesSection,
  AddressRequirementSection,
} from "presentational/organisms";
import { useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { serviceType } from "services/lib/servicesService/type";
import { serviceSchema, useYupValidationResolver } from "validators/yup";
import VisibilitySensor from "react-visibility-sensor";
import { ValidateServiceRequirements } from "helpers/ValidateServiceRequirements";
import { useCreateServiceMutation } from "../business-layer/services/lib/apis";
import { useUpload } from "../business-layer/business-logic/context/useUpload";
import { generateRandomID } from "helpers/generateRandomID";
import { error } from "console";
import { useNavigate, useParams } from "react-router-dom";
import { PAGES } from "constants/pages";
import { Center, Modal, ModalContent, ModalOverlay, Spinner, useDisclosure } from "@chakra-ui/react";

const ServicesAddition = () => {
  const { id } = useParams<{ id: string }>();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const formResolver = useYupValidationResolver(serviceSchema);
  const { serviceRequirements, onAdd } = useManageServiceRequirements();
  const [createService] = useCreateServiceMutation();
  const { onUpload } = useUpload();
  const notifyError = (messageError?: string) =>
    toast.error(
      messageError
        ? messageError
        : "Lỗi thiếu thông tin hoặc không đúng định dạng"
    );
  const notifySuccess = () => toast.success("Tạo dịch vụ thành công");
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<serviceType>({
    resolver: formResolver,
  });

  const [serviceRequirementsError, setServiceRequirementsError] = useState<
    Record<string, string[]>
  >({});

  const existRequirementServiceError = useMemo(() => {
    const serviceRequirementsError =
      ValidateServiceRequirements(serviceRequirements);
    return Object.values(serviceRequirementsError).some(
      (contentError) => contentError.length !== 0
    );
  }, [serviceRequirements]);
  const navigate = useNavigate();
  const onSubmitSuccess: SubmitHandler<serviceType> = async (data) => {
    setServiceRequirementsError(
      ValidateServiceRequirements(serviceRequirements)
    );
    if (existRequirementServiceError) {
      notifyError("Lỗi service requirement, vui lòng kiểm tra lại...");
      return;
    }
    onOpen()

    try {
      const { path } = await onUpload({
        uri: data.image,
        name: data.name,
        path: `/service/${generateRandomID()}`,
        type: "JPEG",
      });

      await createService({
        ...data,
        image: path,
        serviceCategoryId: id,
        requirements: serviceRequirements,
      });
      notifySuccess();
      onClose()

      navigate(PAGES.SERVICE_MANAGEMENT, { replace: true })
      window.location.reload();
    } catch (error) {
      notifyError("Tạo dịch vụ mới thất bại");
    }
  };

  const onSubmitFail = () => {
    setServiceRequirementsError(
      ValidateServiceRequirements(serviceRequirements)
    );

    if (errors || existRequirementServiceError) {
      let messageError = undefined;
      if (errors) {
        messageError =
          errors.name ?? errors.description ?? errors.keys ?? errors.image;
      }
      notifyError(messageError?.message);
    }
  };

  const [isEnableAddServiceRequirement, setIsEnableAddServiceRequirement] =
    useState<boolean>(false);
  return (
    <div className="bg-silver p-5 pb-10">
      <ToastContainer />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Center>
            <Spinner size="xl" />
          </Center>
        </ModalContent>
      </Modal>
      <form
        className="bg-white rounded-lg shadow-md p-4 mb-10 mt-3 relative"
        onSubmit={handleSubmit(onSubmitSuccess, onSubmitFail)}
      >
        <Tittle align="left" color="black">
          Thêm dịch vụ mới
        </Tittle>
        <VerticalSpacer size="xl" />
        <div className="addition-container">
          <div className="column-first-addition w-full">
            <NameAndDescriptionSection control={control} errors={errors} />
          </div>
          <div className="column-second-addition w-full flex flex-col justify-between h-full">
            <PriceSection control={control} errors={errors} />
            <VerticalSpacer size="m" />
            <ActiveSection control={control} />
          </div>

          <div className="column-first-addition ">
            <KeywordSection control={control} errors={errors} />
          </div>
          <div className="column-second-addition w-full">
            <AddressRequirementSection control={control} />
          </div>
          <div className="column-first-addition ">
            <ImageSection control={control} errors={errors} />
          </div>
        </div>
        <VerticalSpacer size="xl" />

        <div className="addition-container">
          <VisibilitySensor
            partialVisibility
            onChange={(isVisible: boolean) => {
              setIsEnableAddServiceRequirement(isVisible);
            }}
          >
            <RequestServicesSection
              serviceRequirements={serviceRequirements}
              serviceRequirementsError={serviceRequirementsError}
            />
          </VisibilitySensor>
        </div>
        {isEnableAddServiceRequirement ? (
          <div className="text-right fixed bottom-7 right-8">
            <BaseBtn
              title=""
              icon={<i className="fi fi-sr-add text-3xl" />}
              onPress={onAdd}
            />
          </div>
        ) : (
          <></>
        )}
        <VerticalSpacer size="xl" />
        <div className="column-full-addition">
          <BaseBtn
            title="Xác nhận tạo mới dịch vụ"
            onPress={() => { }}
            backgroundColor="blueShade-shade"
            color="white"
            padding="10px 15vw"
            radius="rounded"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default ServicesAddition;

import {AxiosError, AxiosRequestConfig} from "axios";
import {BaseQueryFn} from "@reduxjs/toolkit/query";
import {createApi} from "@reduxjs/toolkit/query/react";
import { ApiInstance } from "./instance";
// import { ApiTags } from "./apiTag";

export const API_URL = process.env.REACT_APP_API_URL ?? 'http://example'

const parseToConfig = (method: AxiosRequestConfig['method']) => (url: AxiosRequestConfig['url'], config?: AxiosRequestConfig) => ({ method, url, ...config })
export const ApiHelper = {
  get: parseToConfig('GET'),
  post: parseToConfig('POST'),
  delete: parseToConfig('DELETE'),
  put: parseToConfig('PUT'),
  patch: parseToConfig('PATCH'),
};

const axiosBaseQuery = (): BaseQueryFn<AxiosRequestConfig,
  unknown,
  unknown
> =>
  async (axiosRequestConfig) => {
	try {
	  const result = await ApiInstance.getInstance()(axiosRequestConfig)
	  return {data: result.data}
	} catch (axiosError) {
	  let err = axiosError as AxiosError
	  //todo: handle 401 case
	  return {
		error: {
		  status: err.response?.status,
		  data: err.response?.data || err.message,
		},
	  }
	}
  }

export const detoiApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
  reducerPath: 'detoiApi',
  // tagTypes: Object.values(ApiTags),
});


import { client, isCancel } from '../../services/config/axios';
import { ZodSchema } from 'zod';

const unknownError: string = 'Unexpected error occurred';
export class Services {
  abortController?: AbortController;

  cancelRequest(): void {
    this.abortController && this.abortController.abort();
  }

  handleError(error: any): Error {
    if (isCancel(error)) {
      console.error('Cancel request: ', JSON.stringify(error));
      this.cancelRequest();
      return error;
    }
    if (error?.name && error.name === 'ZodError') {
      console.error('ZodError: ', JSON.stringify({ error }));
      return new Error(
        `Zod Error: ${error?.response?.data?.message ?? unknownError}`
      );
    } else {
      const message = error?.response?.data?.message;
      return new Error(message ?? unknownError);
    }
  }

  async fetchApi<U extends ZodSchema<any>, T>({
    method,
    url,
    schema,
    params,
    data,
    headers = {},
    signal,
    transformResponse,
    isProduction,
    isUseProxy,
    withCredentials = false,
  }: {
    method: string;
    url: string;
    schema: U;
    params?: any;
    data?: any;
    headers?: any;
    signal: AbortSignal;
    transformResponse: (res: T) => void;
    isProduction?: boolean;
    isUseProxy?: boolean;
    withCredentials?: boolean;
  }): Promise<any> {
    const response = await client({
      method,
      url: isUseProxy ? url : process.env.REACT_APP_API_URL + url,
      data,
      params,
      headers,
      signal,
      withCredentials,
    });
    const dataResponse = schema.parse(response.data);

    return transformResponse(dataResponse);
  }
}

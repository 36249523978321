import {
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  ModalBody,
  ModalFooter,
  Button,
  Center,
  Textarea,
} from "@chakra-ui/react";

type confirmBanModalProps = {
  isOpen: boolean;
  onClose: () => void;
  callBack: () => void;
};

export const ConfirmBanModal = ({
  isOpen,
  onClose,
  callBack,
}: confirmBanModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalBody className="my-10">
          <Text className=" text-center text-xl font-thin">
            Bạn có chắc chắn muốn cấm tài khoản?
          </Text>
          <Textarea
            placeholder='Nhập lí do cấm tài khoản'
            size='sm'
            className="mt-10"
          />
        </ModalBody>

        <ModalFooter className="w-full">
          <Center gap={5} className="w-full">
            <Button onClick={onClose} bg="gray" color="white">
              Đóng
            </Button>
            <Button
              onClick={() => {
                callBack();
                onClose();
              }}
              bg="#E11D48"
              color="white"
            >
              Cấm
            </Button>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

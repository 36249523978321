import { VStack, HStack, Flex, Text, Center } from "@chakra-ui/react";
import React, { useState } from "react";
import { freelancerStatictisOrder, IAddress } from "services/entities";
import Chart from "react-apexcharts";
import { getPieChartProperties } from "helpers/ChartProperties";

const VIETNAMIZED_TITLE = {
  Success: "Thành công" ,
  Failed: "Thất bại",
  Uncomplete: "Chưa hoàn thành"
}

export const FreelancerStatisticOrderSection = React.memo(
  ({statictisOrder}: {statictisOrder: freelancerStatictisOrder[]}) => {
    const onChartStatictisOrder = React.useMemo(() => {
      const labels: string[] = [];
      const series: number[] = [];
      // eslint-disable-next-line array-callback-return
      statictisOrder.map((service) => {
        labels.push(VIETNAMIZED_TITLE[service.title] ?? service.title);
        series.push(service.rate);
      });

      return getPieChartProperties(labels, series, "")
    }, [statictisOrder]);
    return (
      <VStack
        className="bg-silver border border-solid shadow-md w-full"
        borderRadius={5}
        p={5}
        gap={5}
        alignItems={"flex-start"}
      >
        <HStack>
          <i className="fi fi-ss-chart-pie-simple-circle-dollar" />
          <Text>Thống kê đơn</Text>
        </HStack>
        <Center>
          {onChartStatictisOrder ? <Chart
            options={onChartStatictisOrder.options as any}
            series={onChartStatictisOrder.series ?? []}
            type="pie"
            width="100%"
            height="100%"
          /> : null}
        </Center>

      </VStack>
    );
  }
);


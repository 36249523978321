import { BaseBtn } from "../button";
import { Paragraph } from "../text";

type props = {
  tags: string[];
  onRemoveTag: (index: number) => void;
};

export const TagSelection = ({ tags, onRemoveTag }: props) => {
  return (
    <div className="flex flex-wrap gap-2">
      {tags.map((tag, indexTag) => (
        <span className="inline-flex items-center px-2 py-1 me-2 text-sm font-medium text-blue-800 bg-violet rounded">
          <Paragraph color="black" size="sm" fontWeight="normal">
            {tag}
          </Paragraph>
          <BaseBtn
            title=""
            onPress={() => onRemoveTag(indexTag)}
            icon={
              <i className="fi fi-bs-cross inline-flex items-center p-1 ms-2 text-xs" />
            }
          />
        </span>
      ))}
    </div>
  );
};

import { Box, Center, Flex } from "@chakra-ui/react";
import React from "react";
import {
  ResponsiveContainer,
  StackedCarousel,
} from "react-stacked-center-carousel";
import "./ImageOrderSection.css";
import { IServiceProven } from "services/entities";
import { baseCdnImage } from "helpers/baseCdnImage";


export const OrderImageSection = ({ proven }: { proven: IServiceProven | null }) => {
  const [centerSlideDataIndex, setCenterSlideDataIndex] = React.useState(0);
  const onCenterSlideDataIndexChange = (newIndex: number) => {
    setCenterSlideDataIndex(newIndex);
  };
  const ref = React.useRef<StackedCarousel | undefined>();
 
  return (
    <div className="w-full relative">{
      proven?.mediaType === 'image' ?
        <React.Fragment>
          <ResponsiveContainer
            carouselRef={ref}
            render={(parentWidth, carouselRef) => {
              let currentVisibleSlide = 5;
              if (parentWidth <= 720) currentVisibleSlide = 1;

              // Calculate the number of visible slides based on the number of items available
              const maxVisibleSlide = Math.min(5, proven?.data?.length || 0);

              // Ensure that currentVisibleSlide is not greater than maxVisibleSlide
              if (currentVisibleSlide > maxVisibleSlide) {
                currentVisibleSlide = maxVisibleSlide;
              }

              return (
                <StackedCarousel
                  data={proven!.data.map(baseCdnImage)}
                  ref={carouselRef}
                  carouselWidth={parentWidth}
                  slideWidth={500}
                  slideComponent={Card}
                  maxVisibleSlide={maxVisibleSlide}  // Use the adjusted number of visible slides
                  currentVisibleSlide={currentVisibleSlide}  // Use the adjusted currentVisibleSlide
                  onActiveSlideChange={onCenterSlideDataIndexChange}
                />
              );
            }}
          />


          <Flex justifyContent="center" gap={7} alignItems="center" mt={10}>
            <Box
              cursor="pointer"
              onClick={() => {
                ref.current?.goBack();
              }}
            >
              <i className="fi fi-rr-arrow-left text-gray-light text-lg"></i>
            </Box>

            <Pagination centerSlideDataIndex={centerSlideDataIndex} images={proven!.data} />

            <Box
              cursor="pointer"
              onClick={() => {
                ref.current?.goNext();
              }}
            >
              <i className="fi fi-rr-arrow-right text-gray-light text-lg"></i>
            </Box>
          </Flex>
        </React.Fragment> :
        proven?.mediaType === 'video' ?
          <Center>
            <video controls className="h-60 w-96" src={baseCdnImage(proven.data[0])}></video>
          </Center>
          : null
    }


    </div>
  );
};



export const Card = React.memo(function (props: {
  data: string[];
  dataIndex: number;
}) {
  const { data, dataIndex } = props;
  return (
    <div
      style={{
        width: "100%",
        height: 300,
        userSelect: "none",
      }}
      className="my-slide-component"
    >
      <img
        className="rounded-lg w-full h-full object-cover "
        draggable={false}
        alt={data[dataIndex]}
        src={data[dataIndex]}
      />
    </div>
  );
});


export function Pagination(props: { centerSlideDataIndex: number, images: string[] }) {
  return (
    <Flex justifyContent="center" gap={3}>
      {props.images.map((_, index) => {
        const isCenterSlide = props.centerSlideDataIndex === index;
        return (
          <div
            style={{
              height: 8,
              width: 8,
              borderRadius: "100%",
              background: isCenterSlide ? "#7B61FF" : "#999999",
              border: "none",
            }}
          />
        );
      })}
    </Flex>
  );
}

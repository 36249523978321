import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Text,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Flex,
  Image,
  VStack,
  HStack,
  Divider,
  Center,
  Tooltip,
} from "@chakra-ui/react";
import { baseCdnImage } from "helpers/baseCdnImage";
import React, { ReactNode } from "react";
import { IFreelancerAccountDetail, IRequestFreelancer } from "services/entities";

type licenseModalProps = {
  isOpenViewDetail: boolean;
  onCloseViewDetail: () => void;
  account: IRequestFreelancer;
};

export const InfoItem = React.memo(
  ({ title, content }: { title: string; content: ReactNode }) => {
    return (
      <HStack alignItems={"flex-start"} >
        <Text className="text-blueShade-mediumLightShade" w={16} >
          {title}
        </Text>
          <Text w={64} whiteSpace={"wrap"} isTruncated>{content}</Text>
      </HStack>
    );
  }
);

export const LicenseModal = ({
  isOpenViewDetail,
  onCloseViewDetail,
  account,
}: licenseModalProps) => {
  return (
    <Modal isOpen={isOpenViewDetail} onClose={onCloseViewDetail} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={"center"} color={"#624DE3"} fontSize={"large"}>
          THÔNG TIN CHI TIẾT
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex gap={10}>
            <Image
              src={baseCdnImage(account.account.avatar)}
              className="w-28 h-28 object-cover rounded-full"
            />
            <VStack alignItems={"flex-start"}>
              <InfoItem title="Họ và tên:" content={account.account.fullName} />
              <InfoItem title="Giới tính:" content={
                // account.account.gender === "Male" ? "Nam" : 
                "Nữ"} />
              <InfoItem title="Liên hệ:" content={account.account.phone} />
              <InfoItem
                title="Địa chỉ:"
                content={`${account.address.addressLine}, ${account.address.district}, ${account.address.ward}, ${account.address.province}`}
              />
            </VStack>
            <VStack alignItems={"flex-start"}>
              <InfoItem
                title="Ngày sinh:"
                content={account.account.dateOfBirth}
              />
              <InfoItem title="CMND:" content={account.identityNumber} />
            </VStack>
          </Flex>

          <VStack mt={10} alignItems={"flex-start"}>
            <Text>Hình ảnh CMND/CCCD</Text>
            <Flex className="w-full" justifyContent={"space-between"} gap={5}>
              <Image
                className=" max-w-80 object-cover h-52 "
                alt="Lỗi CMND"
                src={baseCdnImage(account.identityCardImage)}
              />
              <Image
                className="object-cover max-w-80 h-52 "
                alt="Lỗi CMND"
                src={baseCdnImage(account.identityCardImageBack)}
              />
            </Flex>
          </VStack>
        </ModalBody>

        <ModalFooter className="w-full">
          <Center gap={5} className="w-full">
            <Button onClick={onCloseViewDetail} bg="#00A006" color="white">
              Cấp phép
            </Button>
            <Button onClick={onCloseViewDetail} bg="#E11D48" color="white">
              Từ chối
            </Button>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

import React, { FC, ChangeEvent } from "react";

type NormalTextAreaProps = {
  placeholder?: string;
  rows: number;
  value: string;
  onChange: (value: string) => void;
};

export const NormalTextArea: FC<NormalTextAreaProps> = ({
  rows,
  placeholder,
  value,
  onChange,
}) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    onChange(value);
  };

  return (
    <textarea
      id="message"
      rows={rows}
      className="p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-red-lightShade resize-none h-auto"
      placeholder={placeholder || ""}
      value={value}
      onChange={handleChange}
    />
  );
};

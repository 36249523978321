import type { ReactNode } from "react";
import { PAGES } from "./pages";

export type routeType = {
  secondary?: any;
  name: any;
  path: string;
  icon: ReactNode | null;
  children: routeType[] | null;
  description?: string;
  shortName: string
};

export const sideBarRoute: routeType[] = [
  {
    name: "Tổng quan",
    path: PAGES.OVERVIEW,
    icon: <i className="fi fi-sr-bank" />,
    children: null,
    description: "Tổng quan hệ thống",
    shortName: 'overview'
  },
  {
    name: "Người dùng",
    path: "/user",
    icon: <i className="fi fi-sr-users" />,
    children: [
      {
        name: "Quản lí",
        path: PAGES.USER_MANAGEMENT,
        icon: undefined,
        children: null,
        description: "Quản lý người dùng",
        shortName: 'userManagement',

      },
      {
        name: "Cấp phép",
        path: PAGES.USER_LICENSES,
        icon: undefined,
        children: null,
        description: "Cấp phép tài khoản",
        shortName: 'userManagement',

      },
      {
        name: "Tin nhăn",
        path: PAGES.USER_CHAT,
        icon: undefined,
        children: null,
        description: "Tin nhắn",
        shortName: 'userManagement',

      },
    ],
    shortName: 'userManagement',
  },
  {
    name: "Đơn dịch vụ",
    icon: <i className="fi fi-sr-form" />,
    path: PAGES.SERVICE_ORDER,
    children: null,
    description: "Quản lý dịch vụ",
    shortName: 'order'
  },
  {
    name: "Khiếu nại",
    icon: <i className="fi fi-sr-file-exclamation" />,
    path: PAGES.COMPLAINT,
    children: null,
    description: "Khiếu nại",
    shortName: 'complaint'

  },
  {
    name: "Quản lý dịch vụ",
    icon: <i className="fi fi-br-sitemap" />,
    path: PAGES.SERVICE_MANAGEMENT,
    children: null,
    description: "Quản lý dịch vụ",
    shortName: 'service'

  },
  {
    name: "Lịch sử thanh toán",
    icon: <i className="fi fi-sr-expense" />,
    path: PAGES.HISTORY_PAYMENT,
    children: null,
    description: "Lịch sử thanh toán",
    shortName: 'payment'
  },
  {
    name: "Cấp quyền admin",
    icon: <i className="fi fi-sr-practice" />,
    path: PAGES.ADMIN_PERMISSION,
    children: null,
    description: "Cấp quyền admin",
    shortName: 'adminPermission'
  },

];
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Box,
  Text,
} from "@chakra-ui/react";
import { formatDate } from "helpers/DateTime";
import { baseCdnImage } from "helpers/baseCdnImage";
import { userType } from "pages/UsersManagement";
import TableRow from "presentational/atoms/table/TableRow";
import { IFreelancerAccountDetail } from "services/entities";

type tableFreelancerBodyProps = {
  account: IFreelancerAccountDetail;
  goToUserDetail: () => void;
  setUserIdBanning: () => void;
  onOpenConfirmBan: () => void;
  onChangeCheckbox: () => void;
  checked: boolean;
  handleUnbanAccount: (id: string) => void

};

export const TableFreelancerBody = ({
  account,
  goToUserDetail,
  setUserIdBanning,
  onOpenConfirmBan,
  onChangeCheckbox,
  checked,
  handleUnbanAccount
}: tableFreelancerBodyProps) => {
  return (
    <tr className="py-4  odd:border-y odd:border-gray-light cursor-pointer">
      <TableRow
        listData={[
          // <>
          //   <span className="hover:bg-softBlueberry w-9 h-9 rounded-full flex items-center justify-center">
          //     <input
          //       type="checkbox"
          //       className="w-3.5 h-3.5 cursor-pointer"
          //       checked={checked}
          //       onChange={onChangeCheckbox}
          //     />
          //   </span>
          // </>,
          <>
            <span className="flex items-center justify-center">
              <img
                src={baseCdnImage(account.account.avatar)}
                alt="avatar"
                className=" rounded-full h-16 w-16 cursor-pointer object-cover"
              />
            </span>
          </>,
          <>
            <span className="font-semibold" onClick={goToUserDetail}>
              <span className="line-clamp-1 font-medium text-sm ">
                <b>Tên:</b>{" "}
                <span className="font-normal">{account.account.fullName}</span>
              </span>
              <span className="line-clamp-1 font-medium text-sm ">
                <b>Ngày sinh:</b>{" "}
                <span className="font-normal">
                  {account.account.dateOfBirth
                    ? formatDate(account.account.dateOfBirth).dateMonthYear
                    : "Chưa cập nhật"}
                </span>
              </span>
              <span className="line-clamp-1 font-medium text-sm ">
                <b>Giới tính</b>{" "}
                <span className="font-normal">
                  {account.account.gender === "Male" ? "Nam" : "Nữ"}
                </span>
              </span>
            </span>
          </>,
          <>
            <span className="ml-1 font-medium text-sm" onClick={goToUserDetail}>
              {account.account.phone}
            </span>
          </>,
          <>
            <span className="ml-1 font-medium text-sm" onClick={goToUserDetail}>
              {account?.address?.district}, {account?.address?.ward},{" "}
              {account?.address?.province}
            </span>
          </>,
          // <>
          //   <span className="font-medium text-sm" onClick={goToUserDetail}>
          //     <span className="line-clamp-1">
          //       <i className="fi fi-sr-memo-circle-check"></i>{" "}
          //       {account.orderCount}
          //     </span>
          //     <span className="text-rose line-clamp-1">
          //       <i className="fi fi-sr-heart text-rose"></i> {account.loveCount}
          //     </span>
          //   </span>
          // </>,
          <>
            <span className="ml-1 text-base font-bold" onClick={goToUserDetail}>
              <i className="fi fi-sr-star text-yellow-dark"></i>{" "}
              <span className="ml-1">{account.rating}</span>
            </span>
          </>,
          <>
            {account.account.isActive ? (
              <Menu>
                {({ isOpen }) => (
                  <>
                    <MenuButton isActive={isOpen} as={Button} bg="transparent">
                      • • •
                    </MenuButton>
                    <MenuList>
                      <MenuItem>
                        <Text className="font-bold hover:underline text-base">
                          Đánh gậy
                        </Text>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setUserIdBanning();
                          onOpenConfirmBan();
                        }}
                      >
                        <Text className="font-bold text-rose hover:underline text-base">
                          Cấm tài khoản
                        </Text>
                      </MenuItem>
                    </MenuList>
                  </>
                )}
              </Menu>
            ) : (
              <Box className="py-2 bg-rose rounded w-24" onClick={()=>handleUnbanAccount(account.account.id)}>
                <Text className="text-white">Gỡ lệnh cấm</Text>
              </Box>
            )}
          </>,
        ]}
      />
    </tr>
  );
};

import {
	TYPE_SERVICES_ADDITION,
	TYPE_SERVICES_ADDITION_ICON,
	serviceRequirementOptions,
} from "constants/servicesAddition";
import { inputMethodTypeName } from "services/entities/index";
 
type typeServiceSelectProps = {
	typeServiceSelected: serviceRequirementOptions;
	onChangeType: (
		newTypeService: inputMethodTypeName,
		idServiceRequirement: string
	) => void;
	idServiceRequirement: string;
};

export const TypeServiceSelect = ({
	typeServiceSelected,
	onChangeType,
	idServiceRequirement,
}: typeServiceSelectProps) => {
	const handleTypeServiceChange = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		const selectedIndex = event.target.selectedIndex;
		const selectedTypeService = TYPE_SERVICES_ADDITION[selectedIndex];
		onChangeType(selectedTypeService.value, idServiceRequirement);
	};
	return (
		<div className="relative">
			<select
				id="countries"
				value={typeServiceSelected.value}
				className=" bg-blueShade-shade text-sm rounded-md font-semibold w-full py-2 px-8 focus:border-none min-w-44 text-white flex-col justify-center"
				onChange={handleTypeServiceChange}
			>
				{TYPE_SERVICES_ADDITION.map((typeService, indexTypeService) => (
					<option
						value={typeService.value}
						className="bg-white text-black"
						key={`${typeService.value}-${indexTypeService}`}
					>
						{typeService.label}
					</option>
				))}
			</select>
			<div className="absolute top-2 left-2 text-white">
				{TYPE_SERVICES_ADDITION_ICON[typeServiceSelected.value]}
			</div>
		</div>
	);
};

type menuButtonProps = {
  onToggle: () => void;
  children: React.ReactNode;
}

export const MenuButton: React.FC<menuButtonProps> = ({ onToggle, children }) => {
  return (
    <div
      onClick={onToggle}
      className="w-full"
    >
      {children}
    </div>
  );
};

import { BaseBtn, Tittle } from "presentational/atoms";
import { SubmitHandler, useForm } from "react-hook-form";
import { VerticalSpacer } from "presentational/atoms/spacer/VerticalSpacer";
import { ToastContainer, toast } from "react-toastify";
import {
  ActiveCategorySection,
  ImageCategorySection,
  KeywordCategorySection,
  NameAndDescriptionCategorySection,
} from "presentational/organisms";
import { categoryType } from "services/lib/categoriesService/type";
import { categorySchema, useYupValidationResolver } from "validators/yup";
import { useCreateCategoryMutation } from "../business-layer/services/lib/apis";
import { useUpload } from "../business-layer/business-logic/context/useUpload";
import { generateRandomID } from "helpers/generateRandomID";
import { PAGES } from "constants/pages";
import { useNavigate } from "react-router-dom";
import { useDisclosure, Modal, ModalOverlay, ModalContent, Center, Spinner } from "@chakra-ui/react";

const CategoriesAddition = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const notifyError = (messageError?: string) =>
    toast.error(
      messageError
        ? messageError
        : "Lỗi thiếu thông tin hoặc không đúng định dạng"
    );
  const notifySuccess = () => toast.success("Tạo danh mục thành công");
  const { onUpload } = useUpload();
  const formResolver = useYupValidationResolver(categorySchema);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<categoryType>({
    resolver: formResolver,
  });
  const [addTagToMetric] = useCreateCategoryMutation();
  const navigate = useNavigate();
  const onSubmitSuccess: SubmitHandler<categoryType> = async (data) => {
    try {
      onOpen()
      const { path } = await onUpload({
        uri: data.image,
        name: data.name,
        path: `/category/${generateRandomID()}`,
        type: "JPEG",
      });

      await addTagToMetric({ ...data, image: path });
      notifySuccess();
      onClose()
      navigate(PAGES.SERVICE_MANAGEMENT, { replace: true });
      window.location.reload();
    } catch (error) {
      notifyError();
    }
  };

  const onSubmitFail = () => {
    if (errors) {
      const messageError =
        errors.name ?? errors.description ?? errors.keys ?? errors.image;
      notifyError(messageError?.message);
    }
  };
  return (
    <div className="bg-silver p-5 pb-10">
      <ToastContainer />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Center>
            <Spinner size="xl" />
          </Center>
        </ModalContent>
      </Modal>
      <form
        className="bg-white rounded-lg shadow-md p-4"
        onSubmit={handleSubmit(onSubmitSuccess, onSubmitFail)}
      >
        <Tittle align="left" color="black">
          Thêm danh mục mới
        </Tittle>
        <VerticalSpacer size="xl" />
        <div className="addition-container">
          <div className="column-first-addition w-full">
            <NameAndDescriptionCategorySection
              control={control}
              errors={errors}
            />
          </div>

          <div className="column-second-addition w-full ">
            {/* <ClassCategorySection
              control={control}
              error={errors.className ? errors.className.message : undefined}
            />
            <VerticalSpacer size="m" /> */}
            <ActiveCategorySection control={control} />
          </div>

          <div className="column-first-addition ">
            <div className="border border-red-lightShade border-solid w-full rounded-md p-3">
              <KeywordCategorySection control={control} errors={errors} />
            </div>
          </div>
          <div className="column-first-addition ">
            <ImageCategorySection control={control} errors={errors} />
          </div>
          <div className="column-first-addition mt-5">
            <BaseBtn
              title="Xác nhận tạo mới danh mục"
              color="white"
              isFullWidth
              padding="15px"
              radius="rounded"
              backgroundColor="blueShade-shade"
              type="submit"
              onPress={() => { }}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CategoriesAddition;

import React, { useEffect } from "react";

type Props = {
  options: {
    label: string;
    value: string;
  }[];
  value?: string;
  onChange: (option: { label: string; value: string }) => void;
};

export const NormalSelect: React.FC<Props> = ({ options, value, onChange }) => {
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = options.find(
      (option) => option.value === event.target.value
    );
    if (selectedOption) {
      onChange(selectedOption);
    }
  };
  useEffect(() => {
    if (value === undefined) onChange(options[0]);
  }, [onChange, options, value]);
  return (
    <div className="w-full">
      <select
        onChange={handleSelectChange}
        id="countries"
        value={value ?? options[0].value}
        className="bg-white border border-red-lightShade text-gray-900 text-sm rounded-md w-full px-2 py-1 focus:outline-none"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

import { ApiHelper, detoiApi } from "businessLogic/redux/adminApi";
import { categoryType } from "../categoriesService/type";

const api = detoiApi.injectEndpoints({
    endpoints: (builder) => ({
        getCategories: builder.query({
            query: () => ApiHelper.get(`/api/v1/service-category`, {}),
        }),
        getDetailCategory: builder.query({
            query: (id: string) => ApiHelper.get(`/api/v1/service-category/detail?id=${id}`, {}),
        }),
        updateCategory: builder.mutation({
            query: (data: categoryType) => ApiHelper.put(`/api/v1/service-category`, { data }),
        }),
        createCategory: builder.mutation({
            query: (data: categoryType) => ApiHelper.post('/api/v1/service-category', { data }),
        }),
    }),
});

export const {
    useGetCategoriesQuery,
    useGetDetailCategoryQuery,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    endpoints: { getCategories, getDetailCategory, createCategory, updateCategory }
} = api;
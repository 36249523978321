import { Subtitle, VerticalSpacer } from "presentational/atoms";
import React from "react";
import {
  ServicesInputCreation,
  ServicesDropdownCreation,
  ServicesAddToListCreation,
  ServicesSpecialSelectCreation,
} from "../servicesCreation";
import { IUIServiceRequirement } from "services/entities/index";


type props = {
  serviceRequirements: IUIServiceRequirement[];
  serviceRequirementsError: Record<string, string[]>;
};
export const RequestServicesSection = ({
  serviceRequirements,
  serviceRequirementsError,
}: props) => {
  return (
    <React.Fragment>
      <div className="column-full-addition">
        <Subtitle color="black">Tạo yêu cầu cho dịch vụ</Subtitle>
        <VerticalSpacer size="l" />
        {serviceRequirements?.map((serviceRequirement, indexTypeService) => {
          let serviceComponent = null;

          switch (serviceRequirement.inputMethod.method.name) {
            case 'input':
              serviceComponent = (
                <ServicesInputCreation
                  curretnServiceRequirement={
                    serviceRequirement
                  }
                  errors={serviceRequirementsError[serviceRequirement.id] ?? []}
                />
              );
              break;
            case 'select':
              serviceComponent = (
                <ServicesDropdownCreation
                  curretnServiceRequirement={
                    serviceRequirement
                  }
                  errors={serviceRequirementsError[serviceRequirement.id] ?? []}
                />
              );
              break;
            case 'add_to_list':
              serviceComponent = (
                <ServicesAddToListCreation
                  curretnServiceRequirement={
                    serviceRequirement 
                  }
                  errors={serviceRequirementsError[serviceRequirement.id] ?? []}
                />
              );
              break;
            case 'special_itemselect':
              serviceComponent = (
                <ServicesSpecialSelectCreation
                  curretnServiceRequirement={serviceRequirement}
                  errors={serviceRequirementsError[serviceRequirement.id] ?? []}
                />
              );
              break;
            default:
              <div />;
              break;
          }

          return (
            <React.Fragment key={serviceRequirement.id}>
              <div
                className="shadow-md border border-red-lightShade border-solid w-full rounded-md p-6"
                key={indexTypeService}
              >
                {serviceComponent}
              </div>
              <VerticalSpacer size="xl" />
            </React.Fragment>
          );
        })}
      </div>
    </React.Fragment>
  );
};

const verticalSpacerStyles: Record<string, string> = {
  'xs': 'my-1',
  's': 'my-2',
  'm': 'my-3',
  'l': 'my-4',
  'xl': 'my-6',
  'xxl': 'my-8',
  'xxxl': 'my-10',
};

interface VerticalSpacerProps {
  size: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl';
}

export const VerticalSpacer: React.FC<VerticalSpacerProps> = ({ size }) => {
  return <div className={verticalSpacerStyles[size]} />;
};

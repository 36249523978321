import { useManageServiceRequirements } from "businessLogic/context";
import { contentAction } from "constants/servicesAddition";
import { initDropdownOption } from "helpers/index";
import { BaseBtn, BorderBottomInput, Paragraph } from "presentational/atoms";
import React from "react";
import { useRef, useState } from "react";
import { IOption } from "services/entities/index";

type draggableItemProps = {
  isDeletable?: boolean;
  index: number;
  isAutoFocus?: boolean;
  typeDisplay?: "input" | "text";
  onRemove?: () => void;
  onChange?: (contentAction: contentAction) => void;
  option?: IOption;
  serviceRequirementId: string;
};

const DraggableItem = ({
  isDeletable = false,
  isAutoFocus = false,
  index,
  onChange,
  typeDisplay = "input",
  onRemove,
  option,
  serviceRequirementId,
}: draggableItemProps) => {
  return (
    <div className="flex w-full items-end gap-10">
      <div className="flex items-end gap-3 w-full">
        <Paragraph size="base">{index + 1}.</Paragraph>
        {typeDisplay === "input" ? (
          <BorderBottomInput
            autoFocus={isAutoFocus}
            placeholder="Thêm option"
            size="sm"
            value={option?.name}
            onChange={(value: string) => {
              if (onChange)
                onChange({
                  type: "dropdown-update-option-name",
                  optionIndex: index,
                  serviceRequirementId,
                  newName: value,
                });
            }}
          />
        ) : (
          <Paragraph size="sm">{option?.name ?? ""}</Paragraph>
        )}
      </div>
      <div className="flex items-end gap-3 w-full">
        <Paragraph color="blueShade-mediumLightShade" fontWeight="semibold">
          Description
        </Paragraph>
        {typeDisplay === "input" ? (
          <BorderBottomInput
            placeholder="Thêm mô tả"
            size="sm"
            value={option?.description}
            onChange={(value: string) => {
              if (onChange)
                onChange({
                  type: "dropdown-update-option-description",
                  optionIndex: index,
                  serviceRequirementId,
                  newDescription: value,
                });
            }}
          />
        ) : (
          <Paragraph size="sm">{option?.description ?? ""}</Paragraph>
        )}
      </div>
      <div className={`${isDeletable ? "" : "invisible"}`}>
        <BaseBtn
          title=""
          icon={<i className="fi fi-br-cross" />}
          onPress={() => {
            if (onRemove) {
              onRemove();
            }
          }}
        />
      </div>
    </div>
  );
};

type props = {
  options: IOption[];
  serviceRequirementId: string;
  isEnableAddOptions: boolean;
};

export const DraggableInputGroup = ({
  options,
  serviceRequirementId,
  isEnableAddOptions,
}: props) => {
  const { onChangeValue } = useManageServiceRequirements();
  const [indexOptionSelected, setIndexOptionSelected] = useState<number>(-1);
  const [dragging, setDragging] = useState<number>();

  const dragOption = useRef<number>(-1);
  const draggedOverOption = useRef<number>(0);
  function handleSort() {
    const optionsClone = [...options];
    const temp = optionsClone[dragOption.current];
    optionsClone[dragOption.current] = optionsClone[draggedOverOption.current];
    optionsClone[draggedOverOption.current] = temp;
    onChangeValue({
      type: "dropdown-swap-option",
      destinationIndex: dragOption.current,
      sourceIndex: draggedOverOption.current,
      serviceRequirementId: serviceRequirementId,
    });
    setDragging(undefined);
  }

  const onRemoveItem = (optionIndex: number) => {
    onChangeValue({
      type: "dropdown-remove-option",
      optionIndex,
      serviceRequirementId: serviceRequirementId,
    });
  };

  return (
    <div className="flex flex-col items-center gap-2">
      {options.map((option, optionIndex) => {
        return (
          <div
            key={option.id}
            onClick={() => setIndexOptionSelected(optionIndex)}
            className="relative w-full"
            draggable
            onDragStart={() => {
              setDragging(optionIndex);
              dragOption.current = optionIndex;
            }}
            onDragEnter={() => (draggedOverOption.current = optionIndex)}
            onDragEnd={handleSort}
            onDragOver={(e) => e.preventDefault()}
          >
            <div
              className={`absolute -left-5 bottom-0 ${
                dragging === optionIndex ? "" : "invisible"
              }`}
            >
              <i className="fi fi-br-arrows" />
            </div>
            <DraggableItem
              key={optionIndex}
              index={optionIndex}
              isAutoFocus={true}
              option={option}
              isDeletable={true}
              onRemove={() => onRemoveItem(optionIndex)}
              typeDisplay={indexOptionSelected === optionIndex ? "input" : "text"}
              serviceRequirementId={serviceRequirementId}
              onChange={onChangeValue}
            />
          </div>
        );
      })}
      <div
        className="w-full relative "
        onClick={() => {
          if (!isEnableAddOptions) return;
          onChangeValue({
            type: "dropdown-add-option",
            serviceRequirementId: serviceRequirementId,
            newOption: initDropdownOption
          });
        }}
      >
        <DraggableItem
          index={options.length}
          serviceRequirementId=""
        />
        <div className="absolute w-full h-full top-0 bg-transparent" />
      </div>
    </div>
  );
};

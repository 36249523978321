import { ApiHelper, detoiApi } from "businessLogic/redux/adminApi";

const api = detoiApi.injectEndpoints({
    endpoints: (builder) => ({
        getListCustomer: builder.query({
            query: ({ status, page, pageSize = 5 }: { status?: string, page?: number, pageSize?: number }) => ApiHelper.get(`/api/v1/admin/all-customer`, {
                params: {
                    status,
                    page,
                    pageSize
                }
            }),
        }),
        getDetailCustomer: builder.query({
            query: (id: string) => ApiHelper.get(`/api/v1/admin/detail-customer?id=${id}`,),
        }),
    }),
});

export const {
    useGetListCustomerQuery,
    useGetDetailCustomerQuery,
    endpoints: { getListCustomer, getDetailCustomer }
} = api;
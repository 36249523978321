type props ={
  isValidate: boolean,
  setIsValidate: (value: boolean) => void
}

export const NormalSwitch = ({isValidate, setIsValidate}: props) => {


  const toggleClass = "transform translate-x-5";

  return (
    <div
      className={`w-10 h-4 flex items-center rounded-full p-1 cursor-pointer ${isValidate? 'bg-green-light' : 'bg-gray-light'}`}
      onClick={() => {
        setIsValidate(!isValidate);
      }}
    >
      <div
        className={
          "bg-white w-3 h-3 rounded-full shadow-md " +
          (isValidate ? toggleClass : "")
        }
      />
    </div>
  );
};

import {
  IUIServiceRequirement,
  infoType,
} from "services/entities/index";

import { v4 as uuid } from "uuid";

export const cloneServiceRequirement = (
  originServiceRequirement: IUIServiceRequirement
): IUIServiceRequirement | null => {
  const baseClone: IUIServiceRequirement = {
    ...originServiceRequirement,
    id: uuid(),
    inputMethod: {
      ...originServiceRequirement.inputMethod,
      validation: originServiceRequirement.inputMethod.validation.map(
        (validation) => ({ ...validation, id: uuid() })
      ),
    },
  };

  switch (originServiceRequirement.inputMethod.method.name) {
    case 'input':
    case 'add_to_list':
    case 'special_itemselect':
      return baseClone;

    case 'select':
      return {
        ...baseClone,
        inputMethod: {
          ...baseClone.inputMethod,
          method: {
            ...originServiceRequirement.inputMethod.method,
            options: originServiceRequirement.inputMethod.method.options.map(
              (option) => ({ ...option, id: uuid() })
            ),
          },
        },
      };

    default:
      return null;
  }
};

export const cloneSpecialItemSelectOptionInfo = (
  stepperItem: infoType
): infoType => {
  return {
    ...stepperItem,
    validation: stepperItem.validation.map((validation) => ({
      ...validation,
      id: uuid(),
    })),
  };
};
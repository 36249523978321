export const AVAILABLE_FA_ICON = [
    {
      name: "fa-house",
      Component: <i className="fa-solid fa-house"></i>,
    },
    {
      name: "fa-clock",
      Component: <i className="fa-solid fa-clock"></i>,
    },
    {
      name: "fa-user",
      Component: <i className="fa-solid fa-user"></i>,
    },
    {
      name: "fa-image",
      Component: <i className="fa-solid fa-image"></i>,
    },
    {
      name: "fa-phone",
      Component: <i className="fa-solid fa-phone"></i>,
    },
    {
      name: "fa-envelope",
      Component: <i className="fa-solid fa-envelope"></i>,
    },
    {
      name: "fa-star",
      Component: <i className="fa-solid fa-star"></i>,
    },
    {
      name: "fa-heart",
      Component: <i className="fa-solid fa-heart"></i>,
    },
    {
      name: "fa-wand-magic-sparkles",
      Component: <i className="fa-solid fa-wand-magic-sparkles"></i>,
    },
    {
      name: "fa-music",
      Component: <i className="fa-solid fa-music"></i>,
    },
    {
      name: "fa-location-dot",
      Component: <i className="fa-solid fa-location-dot"></i>,
    },
    {
      name: "fa-camera-retro",
      Component: <i className="fa-solid fa-camera-retro"></i>,
    },
    {
      name: "fa-cloud",
      Component: <i className="fa-solid fa-cloud"></i>,
    },
    {
      name: "fa-comment",
      Component: <i className="fa-solid fa-comment"></i>,
    },
    {
      name: "fa-truck-fast",
      Component: <i className="fa-solid fa-truck-fast"></i>,
    },
    {
      name: "fa-pen-nib",
      Component: <i className="fa-solid fa-pen-nib"></i>,
    },
    {
      name: "fa-face-smile",
      Component: <i className="fa-solid fa-face-smile"></i>,
    },
    {
      name: "fa-paperclip",
      Component: <i className="fa-solid fa-paperclip"></i>,
    },
    {
      name: "fa-shield-halved",
      Component: <i className="fa-solid fa-shield-halved"></i>,
    },
    {
      name: "fa-bell",
      Component: <i className="fa-solid fa-bell"></i>,
    },
    {
      name: "fa-cart-shopping",
      Component: <i className="fa-solid fa-cart-shopping"></i>,
    },
    {
      name: "fa-clipboard",
      Component: <i className="fa-solid fa-clipboard"></i>,
    },
    {
      name: "fa-bolt",
      Component: <i className="fa-solid fa-bolt"></i>,
    },
    {
      name: "fa-circle-info",
      Component: <i className="fa-solid fa-circle-info"></i>,
    },
    {
      name: "fa-car",
      Component: <i className="fa-solid fa-car"></i>,
    },
    {
      name: "fa-ghost",
      Component: <i className="fa-solid fa-ghost"></i>,
    },
    {
      name: "fa-mug-hot",
      Component: <i className="fa-solid fa-mug-hot"></i>,
    },
    {
      name: "fa-umbrella",
      Component: <i className="fa-solid fa-umbrella"></i>,
    },
    {
      name: "fa-gift",
      Component: <i className="fa-solid fa-gift"></i>,
    },
    {
      name: "fa-film",
      Component: <i className="fa-solid fa-film"></i>,
    },
    {
      name: "fa-trash",
      Component: <i className="fa-solid fa-trash"></i>,
    },
    {
      name: "fa-lock",
      Component: <i className="fa-solid fa-lock"></i>,
    },
    {
      name: "fa-headphones",
      Component: <i className="fa-solid fa-headphones"></i>,
    },
    {
      name: "fa-book",
      Component: <i className="fa-solid fa-book"></i>,
    },
    {
      name: "fa-droplet",
      Component: <i className="fa-solid fa-droplet"></i>,
    },
    {
      name: "fa-fire",
      Component: <i className="fa-solid fa-fire"></i>,
    },
    {
      name: "fa-plane",
      Component: <i className="fa-solid fa-plane"></i>,
    },
    {
      name: "fa-comments",
      Component: <i className="fa-solid fa-comments"></i>,
    },
    {
      name: "fa-key",
      Component: <i className="fa-solid fa-key"></i>,
    },
    {
      name: "fa-gears",
      Component: <i className="fa-solid fa-gears"></i>,
    },
    {
      name: "fa-paper-plane",
      Component: <i className="fa-solid fa-paper-plane"></i>,
    },
    {
      name: "fa-code",
      Component: <i className="fa-solid fa-code"></i>,
    },
    {
      name: "fa-truck",
      Component: <i className="fa-solid fa-truck"></i>,
    },
    {
      name: "fa-tree",
      Component: <i className="fa-solid fa-tree"></i>,
    },
    {
      name: "fa-paint-roller",
      Component: <i className="fa-solid fa-paint-roller"></i>,
    },
    {
      name: "fa-brush",
      Component: <i className="fa-solid fa-brush"></i>,
    },
    {
      name: "fa-flask",
      Component: <i className="fa-solid fa-flask"></i>,
    },
    {
      name: "fa-briefcase",
      Component: <i className="fa-solid fa-briefcase"></i>,
    },
    {
      name: "fa-bath",
      Component: <i className="fa-solid fa-bath"></i>,
    },
    {
      name: "fa-snowflake",
      Component: <i className="fa-solid fa-snowflake"></i>,
    },
    {
      name: "fa-palette",
      Component: <i className="fa-solid fa-palette"></i>,
    },
    {
      name: "fa-gamepad",
      Component: <i className="fa-solid fa-gamepad"></i>,
    },
    {
      name: "fa-sun",
      Component: <i className="fa-solid fa-sun"></i>,
    },
    {
      name: "fa-fish",
      Component: <i className="fa-solid fa-fish"></i>,
    },
    {
      name: "fa-bug",
      Component: <i className="fa-solid fa-bug"></i>,
    },
    {
      name: "fa-shirt",
      Component: <i className="fa-solid fa-shirt"></i>,
    },
    {
      name: "fa-bag-shopping",
      Component: <i className="fa-solid fa-bag-shopping"></i>,
    },
    {
      name: "fa-stethoscope",
      Component: <i className="fa-solid fa-stethoscope"></i>,
    },
    {
      name: "fa-phone-volume",
      Component: <i className="fa-solid fa-phone-volume"></i>,
    },
    {
      name: "fa-credit-card",
      Component: <i className="fa-solid fa-credit-card"></i>,
    },
    {
      name: "fa-street-view",
      Component: <i className="fa-solid fa-street-view"></i>,
    },
    {
      name: "fa-database",
      Component: <i className="fa-solid fa-database"></i>,
    },
    {
      name: "fa-mobile",
      Component: <i className="fa-solid fa-mobile"></i>,
    },
    {
      name: "fa-flag",
      Component: <i className="fa-solid fa-flag"></i>,
    },
    {
      name: "fa-user-nurse",
      Component: <i className="fa-solid fa-user-nurse"></i>,
    },
    {
      name: "fa-video-slash",
      Component: <i className="fa-solid fa-video-slash"></i>,
    },
    {
      name: "fa-laptop",
      Component: <i className="fa-solid fa-laptop"></i>,
    },
    {
      name: "fa-restroom",
      Component: <i className="fa-solid fa-restroom"></i>,
    },
    {
      name: "fa-language",
      Component: <i className="fa-solid fa-language"></i>,
    },
    {
      name: "fa-moon",
      Component: <i className="fa-solid fa-moon"></i>,
    },
    {
      name: "fa-wallet",
      Component: <i className="fa-solid fa-wallet"></i>,
    },
    {
      name: "fa-award",
      Component: <i className="fa-solid fa-award"></i>,
    },
    {
      name: "fa-motorcycle",
      Component: <i className="fa-solid fa-motorcycle"></i>,
    },
    {
      name: "fa-audio-description",
      Component: <i className="fa-solid fa-audio-description"></i>,
    },
    {
      name: "fa-seedling",
      Component: <i className="fa-solid fa-seedling"></i>,
    },
    {
      name: "fa-wrench",
      Component: <i className="fa-solid fa-wrench"></i>,
    },
    {
      name: "fa-trophy",
      Component: <i className="fa-solid fa-trophy"></i>,
    },
    {
      name: "fa-hammer",
      Component: <i className="fa-solid fa-hammer"></i>,
    },
    {
      name: "fa-robot",
      Component: <i className="fa-solid fa-robot"></i>,
    },
    {
      name: "fa-hospital",
      Component: <i className="fa-solid fa-hospital"></i>,
    },
    {
      name: "fa-virus",
      Component: <i className="fa-solid fa-virus"></i>,
    },
    {
      name: "fa-route",
      Component: <i className="fa-solid fa-route"></i>,
    },
    {
      name: "fa-plug",
      Component: <i className="fa-solid fa-plug"></i>,
    },
    {
      name: "fa-user-tie",
      Component: <i className="fa-solid fa-user-tie"></i>,
    },
    {
      name: "fa-church",
      Component: <i className="fa-solid fa-church"></i>,
    },
    {
      name: "fa-baby",
      Component: <i className="fa-solid fa-baby"></i>,
    },
    {
      name: "fa-wind",
      Component: <i className="fa-solid fa-wind"></i>,
    },
    {
      name: "fa-lightbulb",
      Component: <i className="fa-solid fa-lightbulb"></i>,
    },
    {
      name: "fa-puzzle-piece",
      Component: <i className="fa-solid fa-puzzle-piece"></i>,
    },
    {
      name: "fa-headset",
      Component: <i className="fa-solid fa-headset"></i>,
    },
    {
      name: "fa-microphone-slash",
      Component: <i className="fa-solid fa-microphone-slash"></i>,
    },
    {
      name: "fa-umbrella-beach",
      Component: <i className="fa-solid fa-umbrella-beach"></i>,
    },
    {
      name: "fa-dumbbell",
      Component: <i className="fa-solid fa-dumbbell"></i>,
    },
    {
      name: "fa-weight-hanging",
      Component: <i className="fa-solid fa-weight-hanging"></i>,
    },
    {
      name: "fa-water-ladder",
      Component: <i className="fa-solid fa-water-ladder"></i>,
    },
    {
      name: "fa-volume-xmark",
      Component: <i className="fa-solid fa-volume-xmark"></i>,
    },
    {
      name: "fa-volleyball",
      Component: <i className="fa-solid fa-volleyball"></i>,
    },
    {
      name: "fa-vest",
      Component: <i className="fa-solid fa-vest"></i>,
    },
    {
      name: "fa-utensils",
      Component: <i className="fa-solid fa-utensils"></i>,
    },
    {
      name: "fa-user-shield",
      Component: <i className="fa-solid fa-user-shield"></i>,
    },
    {
      name: "fa-truck-ramp-box",
      Component: <i className="fa-solid fa-truck-ramp-box"></i>,
    },
    {
      name: "fa-truck-plane",
      Component: <i className="fa-solid fa-truck-plane"></i>,
    },
    {
      name: "fa-trash-can-arrow-up",
      Component: <i className="fa-solid fa-trash-can-arrow-up"></i>,
    },
    {
      name: "fa-tractor",
      Component: <i className="fa-solid fa-tractor"></i>,
    },
    {
      name: "fa-tooth",
      Component: <i className="fa-solid fa-tooth"></i>,
    },
    {
      name: "fa-toilet",
      Component: <i className="fa-solid fa-toilet"></i>,
    },
    {
      name: "fa-temperature-high",
      Component: <i className="fa-solid fa-temperature-high"></i>,
    },
    {
      name: "fa-table-tennis-paddle-ball",
      Component: <i className="fa-solid fa-table-tennis-paddle-ball"></i>,
    },
    {
      name: "fa-square-person-confined",
      Component: <i className="fa-solid fa-square-person-confined"></i>,
    },
    {
      name: "fa-shield-virus",
      Component: <i className="fa-solid fa-shield-virus"></i>,
    },
    {
      name: "fa-spider",
      Component: <i className="fa-solid fa-spider"></i>,
    },
    {
      name: "fa-shield-dog",
      Component: <i className="fa-solid fa-shield-dog"></i>,
    },
    {
      name: "fa-shield-cat",
      Component: <i className="fa-solid fa-shield-cat"></i>,
    },
    {
      name: "fa-shield-heart",
      Component: <i className="fa-solid fa-shield-heart"></i>,
    },
    {
      name: "fa-screwdriver-wrench",
      Component: <i className="fa-solid fa-screwdriver-wrench"></i>,
    },
    {
      name: "fa-rectangle-ad",
      Component: <i className="fa-solid fa-rectangle-ad"></i>,
    },
    {
      name: "fa-people-carry-box",
      Component: <i className="fa-solid fa-people-carry-box"></i>,
    },
    {
      name: "fa-paw",
      Component: <i className="fa-solid fa-paw"></i>,
    },
    {
      name: "fa-mosquito",
      Component: <i className="fa-solid fa-mosquito"></i>,
    },
    {
      name: "fa-map-location-dot",
      Component: <i className="fa-solid fa-map-location-dot"></i>,
    },
    {
      name: "fa-location-arrow",
      Component: <i className="fa-solid fa-location-arrow"></i>,
    },
    {
      name: "fa-kitchen-set",
      Component: <i className="fa-solid fa-kitchen-set"></i>,
    },
    {
      name: "fa-graduation-cap",
      Component: <i className="fa-solid fa-graduation-cap"></i>,
    },
    {
      name: "fa-gas-pump",
      Component: <i className="fa-solid fa-gas-pump"></i>,
    },
    {
      name: "fa-cubes",
      Component: <i className="fa-solid fa-cubes"></i>,
    },
    {
      name: "fa-cloud-sun-rain",
      Component: <i className="fa-solid fa-cloud-sun-rain"></i>,
    },
    {
      name: "fa-cart-flatbed",
      Component: <i className="fa-solid fa-cart-flatbed"></i>,
    },
    {
      name: "fa-bone",
      Component: <i className="fa-solid fa-bone"></i>,
    },
  ];
  
import { HStack } from "@chakra-ui/react";

export const RatingStars = ({ rating }: { rating: number }) => {

  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 >= 0.5;
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);
  return (
    <HStack spacing={1}>
      {Array(fullStars)
        .fill(0)
        .map((_, i) => (
          <i className="fi fi-ss-star text-yellow-dark" key={i} />
        ))}
      {halfStar && (
        <i className="fi fi-ts-star-sharp-half-stroke text-yellow-dark" />
      )}
      {Array(emptyStars)
        .fill(0)
        .map((_, i) => (
          <i className="fi fi-rs-star text-yellow-dark" key={i} />
        ))}
    </HStack>
  );
};

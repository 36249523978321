import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import { useState, useEffect, useMemo, ChangeEvent, useCallback } from "react";

import { Paragraph, Tittle } from "presentational/atoms";
import {
  FilterOrderModal,
  OrderTable,
  Pagination,
} from "presentational/molecules";
import { SERVICES_STATUS } from "constants/serviceOrder";
import { IAdminOrderDetail } from "services/entities";
import { useGetListOrderQuery } from "../business-layer/services/lib/apis";
import { LoadingItem, ErrorItem } from "presentational/organisms";
import { pagingType } from "services/lib/general/type";

export type basicFilter = {
  fromDateOrder: string;
  toDateOrder: string;

  category: string;
  service: string;

  minPrice?: number;
  maxPrice?: number;
  statusOrderId: string;
  placeOrder: string;
};

export type advanceFilter = {
  district: string;
  ward: string;

  idCustomer: string;
  idFreelancer: string;
};

export const ServiceOrder = () => {
  const [dataPaging, setDataPaging] = useState<pagingType>();
  const [indexStatusSelected, setIndexStatusSelected] = useState<number>(0);
  const { data, isError, isFetching } = useGetListOrderQuery({
    page: dataPaging?.currentPage ?? 1,
    status: indexStatusSelected === 0 ? undefined : SERVICES_STATUS[indexStatusSelected - 1].Name
  });
  const [filteredOrders, setFilteredOrders] = useState<IAdminOrderDetail[]>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    if (!data || isError) return;
    const { data: dataOrder, ...paginData } = data;
    setDataPaging(paginData);
    // const filterOrder: IAdminOrderDetail[][] = [];
    // SERVICES_STATUS.forEach((service) => {
    //   const ordersFilterByStatus = dataOrder.filter(
    //     (order: any) => order.serviceStatus === service.Id
    //   );
    //   filterOrder.push(ordersFilterByStatus);
    // });

    // filterOrder.unshift([...filterOrder].flat());
    setFilteredOrders(dataOrder ?? []);
  }, [data, isError]);

  // const numberOrdersFiltered = useMemo(() => {
  //   if (!filteredOrders) return 0;
  //   return indexStatusSelected === 0
  //     ? filteredOrders.flat().length
  //     : filteredOrders[indexStatusSelected - 1].length ?? 0;
  // }, [filteredOrders, indexStatusSelected]);

  const [basicFilter, setBasicFilter] = useState<basicFilter>({
    fromDateOrder: "",
    toDateOrder: "",
    category: "",
    service: "",
    minPrice: undefined,
    maxPrice: undefined,
    statusOrderId: "",
    placeOrder: "",
  });

  const [advanceFilter, setAdvanceFilter] = useState<advanceFilter>({
    district: "",
    ward: "",
    idCustomer: "",
    idFreelancer: "",
  });

  const filterOrderByToolbox = useCallback(
    (orders: IAdminOrderDetail[]): IAdminOrderDetail[] => {
      const newOrdersFiltered = orders?.filter((order) => {
        // if (
        // !isMoreThan(
        //   order.createdAt.toString(),
        //   convertToDDMMYYYY(basicFilter.fromDateOrder)
        // ) ||
        // !isLessThan(
        //   order.createdAt.toString(),
        //   convertToDDMMYYYY(basicFilter.toDateOrder)
        // ) ||
        //   !isEqual(order.serviceCategory.id, basicFilter.category) ||
        //   !isMoreThan(order.estimatedPrice, basicFilter.minPrice) ||
        //   !isLessThan(order.estimatedPrice, basicFilter.maxPrice) ||
        //   (order?.freelancer &&
        //     !isEqual(order?.freelancer?.id, advanceFilter.idFreelancer))
        // ) {
        //   return false;
        // }
        return true;
      });
      return newOrdersFiltered;
    },
    [advanceFilter, basicFilter]
  );
  const [inputSearch, setInputSearch] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputSearch(event.target.value);
  };

  const searchedAndFilterededOrders = useMemo(() => {
    if (!filteredOrders || !dataPaging) return [];
    return filterOrderByToolbox(filteredOrders)
      .filter((order) => order?.id.includes(searchValue))
  }, [filteredOrders, dataPaging, filterOrderByToolbox, indexStatusSelected, searchValue]);

  const handleSearch = () => {
    setSearchValue(inputSearch);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  if (isFetching) {
    return <LoadingItem />;
  }

  if (isError) {
    return <ErrorItem />;
  }

  return (
    <div className="bg-silver p-5 pb-10 max-h-[90vh] overflow-y-scroll overflow-x-hidden">
      <Flex justify="space-between">
        <Tittle align="left" color="black">
          Quản lý danh sách đơn dịch vụ
        </Tittle>
        <Flex gap={5}>
          <InputGroup size="sm" w={300}>
            <InputLeftElement
              cursor="pointer"
              children={<i className="fi fi-bs-search" />}
              onClick={handleSearch}
            />
            <Input
              borderRadius={5}
              type="text"
              onKeyPress={handleKeyPress}
              value={inputSearch}
              onChange={handleSearchChange}
              placeholder="Tìm kiếm theo tên dịch vụ"
              border="1px solid #949494"
            />
          </InputGroup>

          <Button
            leftIcon={<i className="fi fi-ss-filter" />}
            bg="white"
            size="sm"
            border="1px solid #949494"
            onClick={onOpen}
          >
            Lọc đơn
          </Button>
          <FilterOrderModal
            isOpen={isOpen}
            onClose={onClose}
            setAdvanceFilter={setAdvanceFilter}
            setBasicFilter={setBasicFilter}
          />
        </Flex>
      </Flex>

      <Tabs
        position="relative"
        // isFitted
        variant="unstyled"
        className="my-8 overflow-x-hidden"
        overscrollX={"auto"}
        overflowX={"scroll"}
        index={indexStatusSelected}
        onChange={(index) => {
          setIndexStatusSelected(index); 
          setDataPaging(prev => {
            if (prev) {
              return {
                ...prev,
                currentPage: 1
              };
            }
            return prev;
          });
        }}
      >
        <TabList className=" border-0 border-b-[1px] border-b-red-lightShade overflow-x-hidden">
          <Tab
            _selected={{ color: "#4285F4", paddingX: "2" }}
            color="#A2A4AF"
            fontSize="small"
            minW="-webkit-fit-content"
          >
            Tất cả
          </Tab>
          {SERVICES_STATUS.map((service) => (
            <Tab
              _selected={{ color: "#4285F4", paddingX: "2" }}
              color="#A2A4AF"
              fontSize="small"
              minW="-webkit-fit-content"
            >
              {service.Name}
            </Tab>
          ))}
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="#4285F4"
          borderRadius="1px"
        />
        <Flex gap={2} my={5}>
          <Paragraph
            fontWeight="bold"
            color="blueShade-mediumLightShade"
            size="base"
          >
            Tổng đơn:
          </Paragraph>
          <Paragraph fontWeight="bold" size="base">
            {dataPaging?.totalCount} đơn
          </Paragraph>
        </Flex>
        <Box className="overflow-x-hidden">
          <Box w="78vw">
            <OrderTable
              orders={searchedAndFilterededOrders}
              paging={
                <Flex justifyContent="center" mt={4}>
                  <Pagination
                    indexCurrentPage={dataPaging?.currentPage ?? 1}
                    onChangePage={(newPageNum) => {
                      if (dataPaging) {
                        setDataPaging(prev => {
                          if (prev) {
                            return {
                              ...prev,
                              currentPage: newPageNum
                            };
                          }
                          return prev;
                        });
                      }
                    }}
                    numberPage={dataPaging?.totalPages ?? 1}
                  />
                </Flex>
              }
            />
          </Box>

        </Box>
      </Tabs>
    </div>
  );
};

export default ServiceOrder;

export const mockOrderDetails: IAdminOrderDetail[] = [];

import { VStack, Tr, Td, Box, Flex, HStack, Text } from "@chakra-ui/react";
import { SERVICES_STATUS } from "constants/serviceOrder";
import { formatCurrencyVND, onConvertNumberToCurrency } from "helpers/MoneyConverter";
import { Pagination } from "presentational/molecules";
import CustomTable from "presentational/molecules/table/CustomTable";
import React, { useState } from "react";
import { IFreelancerAccountDetail } from "services/entities";


const NUMBER_ITEMS_IN_PAGE = 6;

type freelancerOrderedSectionProps = {
  freelancerDetail: IFreelancerAccountDetail;
};

export const convertIsoToReadable = (isoTimestamp: string): string => {
  const date = new Date(isoTimestamp);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes} - ${day}/${month}/${year}`;
}

export const FreelancerOrderedSection = React.memo(({ freelancerDetail }: freelancerOrderedSectionProps) => {
  const [currentIndexPage, setCurrentIndexPage] = useState<number>(0);
  const numberPages = Math.ceil(freelancerDetail.paymentHistory.length / NUMBER_ITEMS_IN_PAGE);
  return (
    <VStack
      className="bg-silver border border-solid shadow-md"
      borderRadius={5}
      p={3}
      gap={5}
      alignItems={"flex-start"}
    >
      <HStack>
        <i className="fi fi-ss-money-bill-wave" />
        <Text>Lịch sử giao dịch</Text>
      </HStack>
      <CustomTable
        rows={freelancerDetail.paymentHistory.map((payment) => {
          // const currStatus = SERVICES_STATUS.find(
          //   (status) => status.Id === order.serviceStatus
          // );
          return (
            <Tr className="hover:bg-blueShade-lightShade cursor-pointer">
              <Td border="none" className="text-sm" textAlign="center">
                {payment.paymentType === "Add" ? "Nạp tiền" : "Rút tiền"}
              </Td>

              <Td
                border="none"
                isTruncated
                className="text-sm"
                textAlign="center"
              >
                {payment.method}
              </Td>
              <Td
                border="none"
                isTruncated
                className="text-sm"
                textAlign="center"
              >
                {formatCurrencyVND(payment.value)}
              </Td>
              <Td
                border="none"
                isTruncated
                className="text-sm"
                textAlign="center"
              >
                {convertIsoToReadable(payment.timestamp)}
              </Td>
            </Tr>
          );
        })}
        title={[
          "Loại thanh toán",
          "Phương thức thanh toán",
          "Số tiền thanh toán",
          "Thời gian"
        ]}
        paging={
          <Flex justifyContent="center" mt={12}>
            <Pagination
              indexCurrentPage={currentIndexPage}
              onChangePage={setCurrentIndexPage}
              numberPage={numberPages}
            />
          </Flex>
        }
      />
    </VStack>
  );
});

import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { baseCdnImage } from "helpers/baseCdnImage";
import { Avatar, BaseBtn, Paragraph, Tittle } from "presentational/atoms";
import { Menu, RatingStars } from "presentational/molecules";
import React, { useState } from "react";
import { IAccountDetail } from "services/entities";

const ReviewMessage = ({ comment, commentator }: { comment: string, commentator: IAccountDetail }) => {
  const [isOpenOption, setIsOpenOption] = useState<boolean>(false);
  return (
    <Box className="px-6 py-2 border border-blueShade-mediumLightShade shadow-md rounded-xl">
      <Flex justify="space-between" alignItems="center" mb={3}>
        <Text fontWeight="bold" fontSize={"large"}>
          {commentator.fullName}
        </Text>
        <HStack>
          <i className="fi fi-sr-clock-five" />
          <Menu
            onToggle={() => {
              setIsOpenOption((prevState) => !prevState);
            }}
            isOpen={isOpenOption}
            menuButtonContent={
              <i className="fi fi-br-menu-dots-vertical cursor-pointer text-xs" />
            }
            menuBodyContent={
              <div className="flex flex-col justify-center items-center gap-2 rounded-md">
                <div className="w-full flex justify-center hover:bg-gray-extraLight">
                  <BaseBtn
                    isFullWidth
                    padding="8px"
                    title="New action"
                    onPress={() => {}}
                  />
                </div>
              </div>
            }
          />
        </HStack>
      </Flex>
      <Paragraph>{comment}</Paragraph>
    </Box>
  );
};
const ReviewBox = ({ comment, commentator }: { comment: string, commentator: IAccountDetail }) => {
 
  return (
    <Box>
      <Box
        className={`relative mt-6 max-w-[80%]`}
        key={commentator.id}
      >
        <Box
          className="absolute"
          top={0}
          left={-3}
        >
          <Avatar link={baseCdnImage(commentator.avatar)} size="base" />
        </Box>
        <ReviewMessage comment={comment} commentator={commentator} />
      </Box>
    </Box>
  );
};

export const RatingCommentSection = React.memo(
  ({ comment, rating, commentator }: { comment: string | null; rating: number, commentator: IAccountDetail }) => {
    
    return (
      <Box>
        <Flex alignItems="center" gap={2}>
          <Tittle>{rating}</Tittle>
          <RatingStars rating={rating} />
        </Flex>

        {comment ? <ReviewBox comment={comment} commentator={commentator} /> : <></>}
      </Box>
    );
  }
);

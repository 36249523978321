import { countWords } from "helpers/CountWord";
import {
  Paragraph,
  VerticalSpacer,
  TagSelection,
  TagInput,
  Subtitle,
} from "presentational/atoms";
import { useEffect, useState } from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { serviceType } from "services/lib/servicesService/type";

type props = {
  control: Control<any>;
  errors: FieldErrors<serviceType>;
  initKeyword?: string[];
};

export const KeywordSection = ({ control, errors, initKeyword }: props) => {
  const [keywords, setKeywords] = useState<string[]>([]);
  const onAddNewKeyword = (newKeyword: string) => {
    setKeywords((prevState) => [...prevState, newKeyword]);
  };
  const [newKeyword, setNewKeyword] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);

  const onChange = (value: string) => {
    if (isError) {
      setIsError(false);
    }
    setNewKeyword(value);
  };

  useEffect(() => {
    if (initKeyword) {
      setKeywords(initKeyword);
    }
  }, [initKeyword]);
  return (
    <>
      <Subtitle color="black">Từ khóa</Subtitle>
      <VerticalSpacer size="l" />
      <div className="border border-red-lightShade border-solid w-full rounded-md p-3">
        <Paragraph color="blueShade-mediumLightShade" fontWeight="semibold">
          Danh sách từ khóa đã thêm
        </Paragraph>
        <VerticalSpacer size="m" />
        <div className="border border-red-lightShade border-solid w-full rounded-md p-3 pb-10 min-h-32">
          <Controller
            name="keys"
            control={control}
            render={({ field }) => (
              <TagSelection
                tags={keywords}
                onRemoveTag={(indexRemove: number) => {
                  setKeywords((keywordsPrevious) =>
                    keywordsPrevious.filter((_, index) => index !== indexRemove)
                  );
                  field.onChange(
                    keywords.filter((_, index) => index !== indexRemove)
                  );
                }}
              />
            )}
          />
        </div>
        <VerticalSpacer size="m" />
        <Controller
          name="keys"
          control={control}
          render={({ field }) => (
            <TagInput
              textInput={newKeyword}
              onChange={onChange}
              onAdd={() => {
                if (countWords(newKeyword) > 6) {
                  setIsError(true);
                  return;
                }
                field.onChange([...keywords, newKeyword]);
                setNewKeyword("");
                onAddNewKeyword(newKeyword);
              }}
              placeholder="Nhập từ khóa"
            />
          )}
        />

        <VerticalSpacer size="m" />
        <div className={`${isError ? "visible" : "invisible"}`}>
          <Paragraph color="rose" size="xs" fontWeight="semibold">
            Thêm từ khóa cho dịch vụ (mỗi từ khóa tối đa 6 từ)
          </Paragraph>
        </div>
        <div className={`${errors.keys ? "block" : "hide"}`}>
          <Paragraph color="rose" size="xs" fontWeight="semibold">
            {errors.keys?.message}
          </Paragraph>
        </div>
      </div>
    </>
  );
};

import { Paragraph, Subtitle } from "presentational/atoms";
import { ReactNode } from "react";

type insightCardProps = {
  icon: ReactNode;
  backgroundIcon: 'primary' | 'black' | 'rose' | 'green-medium' | 'yellow-dark';
  title: string;
  description: string;
  // percentDiffInsight: string;
  isIncrease: boolean;
  seeMoreComponent: JSX.Element
};

const DiffInsight = ({
  // percentDiffInsight,
  isIncrease,
}: {
  // percentDiffInsight: string;
  isIncrease: boolean;
}) => {
  return (
    <div className="flex gap-1 items-center">
      {/* <i
        className={
          isIncrease
            ? "fi fi-sr-arrow-circle-up text-green-medium"
            : "fi fi-sr-arrow-circle-down text-rose"
        }
      /> */}
      {/* <Paragraph size="sm" color={isIncrease ? "green-medium" : "rose"}>{percentDiffInsight}</Paragraph> */}
    </div>
  );
};

export const InsightCard = ({
  icon,
  backgroundIcon,
  title,
  // percentDiffInsight,
  description,
  isIncrease,
  seeMoreComponent
}: insightCardProps) => {
  const backgroundClass = `bg-${backgroundIcon}`;
  return (
    <div className="flex p-1 gap-3 items-center rounded-md shadow-lg">
      <div className={` text-white border-solid rounded-md w-9 h-9 flex justify-center items-center ${backgroundClass}`}>
        {icon}
      </div>
      <div>
        <div className="flex gap-2 justify-between items-center w-full">
          <Subtitle>{title}</Subtitle>{" "}
          <DiffInsight
            // percentDiffInsight={percentDiffInsight}
            isIncrease={isIncrease}
          />
          {seeMoreComponent}
        </div>
        <div>
          <Paragraph align="left" fontWeight="extralight">
            {description}
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

import { Box, Flex, VStack, HStack, Text } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { ICustomerAccountDetail } from "services/entities";

const InfoItem = React.memo(
  ({ title, content }: { title: string; content: ReactNode }) => {
    return (
      <HStack>
        <Text className="text-blueShade-mediumLightShade" w={150}>
          {title}
        </Text>
        <Text>{content}</Text>
      </HStack>
    );
  }
);

export const CustomerPersionalInfoSection = React.memo(
  ({ customerDetail }: { customerDetail: ICustomerAccountDetail }) => {
    return (
      <Box className="bg-silver border border-solid shadow-md" borderRadius={5}>
        <Flex gap={10} p={5}>
          <VStack className="w-full" alignItems={"flex-start"} gap={3}>
            <HStack>
              <i className="fi fi-sr-user" />
              <Text>Tài khoản</Text>
            </HStack>
            <InfoItem
              title="Họ và tên"
              content={<Text>{customerDetail.account.fullName}</Text>}
            />
            <InfoItem
              title="Ngày sinh"
              content={<Text>{customerDetail.account.dateOfBirth}</Text>}
            />
            <InfoItem
              title="Số điện thoại"
              content={<Text>{customerDetail.account.phone}</Text>}
            />
            {/* <InfoItem
              title="Email"
              content={<Text>nhatduy0409@gmail.com</Text>}
            /> */}
            <InfoItem
              title="Giới tính"
              content={<Text>
                {
                // customerDetail.gender === "Male" ? "Nam" : 
                "Nữ"}</Text>}
            />
          </VStack>
          <VStack className="w-full" gap={3} alignItems={"flex-start"}>
            <HStack>
              <i className="fi fi-sr-ranking-star" />
              <Text>Hệ thống</Text>
            </HStack>
            <InfoItem title="Điểm tích lũy" content={<Text>{customerDetail.memberPoint}</Text>} />
            <InfoItem
              title="Hoạt động"
              content={
                <Text className="text-rose">{customerDetail.account.isActive ? 'Bình thường' : 'Đã bị cấm'}</Text>
              }
            />
          </VStack>
        </Flex>
      </Box>
    );
  }
);

export const timeAgo = (timestamp: string) => {
    const now = new Date();
    const past = new Date(timestamp);
    let diffInSeconds = (now.getTime() - past.getTime()) / 1000;
    const diffInMinutes = diffInSeconds / 60;
    const diffInHours = diffInMinutes / 60;
    const diffInDays = diffInHours / 24;
    if(diffInSeconds<0) diffInSeconds = diffInSeconds*-1

    if (diffInSeconds < 60) {
        return `${Math.floor(diffInSeconds)} giây trước`;
    } else if (diffInMinutes < 60) {
        return `${Math.floor(diffInMinutes)} phút trước`;
    } else if (diffInHours < 24) {
        return `${Math.floor(diffInHours)} giờ trước`;
    } else if (diffInDays < 30) {
        return `${Math.floor(diffInDays)} ngày trước`;
    } else {
        const day = past.getDate();
        const month = past.getMonth() + 1;
        return `${day} thg ${month}`;
    }
}
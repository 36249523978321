type menuBodyProps = {
  isOpen: boolean;
  children: React.ReactNode;
};

export const MenuBody: React.FC<menuBodyProps> = ({ isOpen, children }) => {
  return (
    <div
      className={`${
        isOpen ? "block" : "hidden"
      }  origin-top-right absolute z-50 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
      role="menu"
    >
      <div className="py-1" role="none">
        {children}
      </div>
    </div>
  );
};

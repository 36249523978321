import axios, { AxiosInstance } from "axios";
import * as rax from "retry-axios";
import { getToken } from "../../services/lib/login";

export enum APIPriority {
  NORMAL = 'normal',
  HIGH = 'high',
}
export class AccessDeniedQueryError extends Error {
    constructor(
        public readonly dimensionId: string,
    ) {
        super("Access denied");
    }
}

export class ApiInstance {
  private static instanceMap: Map<APIPriority, AxiosInstance> = new Map();
  private static token: string | null = null;
  private constructor() {
  }

  public static getInstance(priority: APIPriority = APIPriority.NORMAL) {
    if (this.instanceMap.has(priority)) return this.instanceMap.get(priority)!;
    const baseURL = (priority === APIPriority.NORMAL ? process.env.REACT_APP_API_URL : process.env.REACT_APP_HIGH_PRIORITY_API_URL) || "https://example-default.io";
    // Temporary solution
    
    // const token = this.token ?? JSON.parse(localStorage.getItem("userInfo") || "{}").token;
    const token = getToken()

    const headers = {
      "Content-Type": "application/json",
    };
    
    // if (token) {
    //   headers.Authorization = `Bearer ${token}`;
    // }

    const instance = axios.create({
      baseURL,
      headers,
    });
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    instance.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        if (403 === error?.response?.status && error.response?.data?.dimensionId) {
          return Promise.reject(new AccessDeniedQueryError(error.response.data.dimensionId))
        } else {
          return Promise.reject(error)
        }
      }
    )
    // const id = rax.attach(instance);
    // Add POST to the default list
    instance.defaults.raxConfig = {
      retry: 0, // Only retry on those request has raxConfig
      instance, // Retry on this instance only
      httpMethodsToRetry: [
        "GET",
        "HEAD",
        "PUT",
        "OPTIONS",
        "DELETE",
        "POST",
      ],
    }
    this.instanceMap.set(priority, instance);
    return instance;
  }

  // public static setToken(token: string) {
  //   this.token = token;
  //   Array.from(this.instanceMap.values()).forEach(instance => {
  //     instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  //   })
  // }


  public static destroy() {
    this.instanceMap.clear();
    this.token = null;
  }
}

export const GATEWAY_ERROR_RETRY_CONFIG: rax.RetryConfig = {
  retry: 3,
  statusCodesToRetry: [[502, 502]],
  retryDelay: 1500,
  backoffType: "static"
}
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  IConversation,
  IMessage,
  IMessagePreview,
} from "services/entities/chat";
import {
  useGetChatHistoryDetailQuery,
  useGetChatHistoryQuery,
} from "../../services/lib/apis/index";
import { useSession } from "businessLogic/hook";

export type chatManagementType = {
  conversation?: IConversation[];
  messagePreview: IMessagePreview[];
  onChangeConversation: (conversationId: string) => void;
  onAddMessage: (newMessage: string) => void;
  anotherUserId: string;
  currentConversationId: string
};

const ChatManagementContext = createContext({} as chatManagementType);


const deepCompare = (obj1: any, obj2: any) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

const useChatDetails = (conversationId: string | null) => {
  const { data, isFetching, isError, refetch } = useGetChatHistoryDetailQuery({
    receiverId: conversationId,
    page: 1,
    pageSize: 30,
  });
  return {
    data,
    isFetching,
    isError,
    refetch
  };
};

export const ChatManagementProvider = ({ children }: { children: any }) => {
  const {
    data: dataChatHistory,
    isFetching,
    isError,
  } = useGetChatHistoryQuery({});
  const [currentConversationId, setCurrentConversationId] = useState<
    string
  >('');
  const [anotherUserId, setAnotherUserId] = React.useState<string>("");
  const [conversation, setConversation] = useState<IConversation[]>([]);
  const { data: dataDetailChat, isFetching: isFetchingDetailChat, refetch: refetchChatDetails } =
    useChatDetails(currentConversationId);

  const [messagePreview, setMessagePreview] = useState<IMessagePreview[]>([]);

  useEffect(() => {
    if (dataChatHistory) {
      setMessagePreview(dataChatHistory);
    }
  }, [dataChatHistory]);

  useEffect(() => {
    if (dataDetailChat) {
      setConversation(dataDetailChat);
    }
  }, [dataDetailChat]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (currentConversationId) {
        const previousConversation = conversation;
        const newConversation = await refetchChatDetails();
        if (!deepCompare(previousConversation, newConversation.data)) {
          setConversation(newConversation.data);
        }
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [currentConversationId, conversation, refetchChatDetails]);

  const onChangeConversation = useCallback(
    async (conversationId: string) => {
      messagePreview.forEach(async (chat) => {
        if (chat.conversationId === conversationId) {
          setCurrentConversationId(conversationId);
          setAnotherUserId(chat.sender.id);
        }
      });
    },
    [messagePreview]
  );
  const { user } = useSession();
  const onAddMessage = useCallback((newMessage: string) => {
    setConversation((prevMessages) => {
      const newConversation: IConversation = {
        id: (prevMessages.length + 1).toString(), // hard code id
        content: newMessage,
        timeReceived: new Date().toISOString(),
        sender: {
          id: user?.id ?? "",
          fullName: user?.fullName ?? "",
          avatar: user?.avatar ?? "",
          type: 1,
        },
        isSystem: true,
      };
      return [newConversation, ...prevMessages, ];
    });
    setMessagePreview((prevMessageReview) =>
      prevMessageReview.map((preview) =>
        preview.conversationId === currentConversationId
          ? {
              ...preview,
              latestMessage: {
                id: (prevMessageReview.length + 1).toString(), // hard code id
                content: newMessage,
                timeReceived: new Date().toISOString(),
              },
            }
          : preview
      )
    );
  }, [currentConversationId, user, setMessagePreview, setConversation]);
    // sender: {
              //   id: user?.id ?? "",
              //   fullName: user?.fullName ?? "",
              //   avatar: user?.avatar ?? "",
              // },
  const value = useMemo(
    () => ({
      conversation,
      messagePreview,
      onChangeConversation,
      onAddMessage,
      anotherUserId,
      currentConversationId
    }),
    [
      conversation,
      messagePreview,
      onChangeConversation,
      onAddMessage,
      anotherUserId,
      currentConversationId
    ]
  );
  return (
    <ChatManagementContext.Provider value={value}>
      {children}
    </ChatManagementContext.Provider>
  );
};

export const useChatManagement = () => useContext(ChatManagementContext);

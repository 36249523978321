export const getAreaChartProperties = (
	series: number[],
	categories: string[],
	label: string,
	title: string
) => {
	return {
		options: {
			xaxis: {
				categories,
			},
			title: {
				text: title,
				align: "left",
				style: {
					fontFamily: "Calibri",
					fontWeight: "600",
					fontSize: "20px",
				},
			},
		},
		series: [
			{
				name: label,
				data: series,
			},
		],
	};
};

export const getLineChartProperties = (
	series: {
		name: string;
		data: number[];
	}[],
	categories: string[],
	title: string
) => {
	return {
		options: {
			xaxis: {
				categories,
			},
			chart: {
				zoom: {
					enabled: false,
				},
				toolbar: {
					show: false,
				},
			},
			title: {
				text: title,
				style: {
					fontFamily: "Calibri",
					fontWeight: "600",
					fontSize: "20px",
				},
			},
			grid: {
				borderColor: "#e7e7e7",
				row: {
					colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
					opacity: 0.5,
				},
			},
            colors: ['#77B6EA', '#545454'],
			dataLabels: {
				enabled: true,
			},
			stroke: {
				curve: "smooth",
			},
			markers: {
				size: 1,
			},
			legend: {
				position: "top",
				horizontalAlign: "right",
				floating: true,
				offsetY: -30,
				offsetX: -5,
			},
		},
		series,
	};
};

export const getColumnChartProperties = (
	series: {
		name: string;
		data: number[];
	}[],
	categories: string[],
	title: string
) => {
	return {
		options: {
			dataLabels: {
				enabled: false,
			},
			xaxis: { categories },
			title: {
				text: title,
				style: {
					fontFamily: "Calibri",
					fontWeight: "600",
					fontSize: "20px",
				},
			},
		},
		series,
	};
};

export const getPieChartProperties = (
	labels: string[],
	series: number[],
	title: string
) => {
	return {
		series,
		options: {
			chart: {
				type: "pie",
			},
			title: {
				text: title,
				style: {
					fontFamily: "Calibri",
					fontWeight: "600",
					fontSize: "20px",
				},
			},
			labels,
		},
	};
};

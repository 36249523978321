import {
  Box,
  Center,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  convertToDDMMYYYY,
  isLessThan,
  isMoreThan,
} from "helpers/ComapareValue";
import { Tittle } from "presentational/atoms";
import {
  ComplaintTable,
  FilterComplaintModal,
  Pagination,
} from "presentational/molecules";
import { StatusComplainCard } from "presentational/molecules/card/StatusComplainCard";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { complainStatusType, complaintPropsType } from "services/lib/feedbackService/type";
import { useGetComplainsQuery } from "../business-layer/services/lib/apis";
import { LoadingItem, ErrorItem } from "presentational/organisms";
import { pagingType } from "services/lib/general/type";



export const COMPLAINT_THEME: Record<
  complainStatusType,
  {
    color: string;
    background: string;
  }
> = {
  Pending: {
    color: "#48A9F5",
    background: "#EAF5FD",
  },
  Rejected: {
    color: "#F1635C",
    background: "#FDE1E1",
  },
  Resolved: {
    color: "#61BA65",
    background: "#D7F0D8",
  },
};
// export type tabComplainType = {
//   key: complaintType;
//   title: string;
//   description: string;
//   values: complaintPropsType[];
// };

export type basicFilter = {
  customerId: string;
  freelancerId: string;

  statusComplaint: string;
  placeOrder: string;
};
const TAB_LIST = [
  {
    title: "Tất cả",
    description: "Tổng khiếu nại đã nhận",
  },
  {
    key: "Pending",
    title: "Cần giải quyết",
    description: "Số khiệu nại chưa giải quyết",
  },
  {
    key: "Rejected",
    title: "Đã từ chối",
    description: "Số khiệu nại đã từ chối giải quyết",
  },
  {
    key: "Resolved",
    title: "Đã giải quyết",
    description: "Số khiệu nại đã giải quyết",
  }
]

const Complaint = () => {
  const [complainList, setComplainList] = useState<complaintPropsType[]>();
  const [dataPaging, setDataPaging] = useState<pagingType>();
  const [indexStatusSelected, setIndexStatusSelected] = useState<number>(0);
  const [inputSearch, setInputSearch] = useState<string>("");

  const { data, isError, isFetching } = useGetComplainsQuery({
    page: dataPaging?.currentPage ?? 1,
    status: TAB_LIST[indexStatusSelected]?.key ?? undefined,
    name: inputSearch
  });
  useEffect(() => {
    if (data && !isError) {
      const { data: dataComplain, ...paginData } = data;
      setComplainList(dataComplain.map((d: any) => ({ ...d, status: 'need-action' })));
      setDataPaging(paginData);
    }
  }, [data, isFetching]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentIndexPage, setCurrentIndexPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [rangeTimeFilter, setRangeTimeFilter] = useState<{
    from: string;
    to: string;
  }>({
    from: "",
    to: "",
  });

  const [basicFilter, setBasicFilter] = useState<basicFilter>({
    customerId: "",
    freelancerId: "",
    statusComplaint: "",
    placeOrder: "",
  });

  const handleSearch = () => {
    setSearchValue(inputSearch);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputSearch(event.target.value);
  };

  const isPassBasicFilter = useCallback(
    (complaint: complaintPropsType) => {
      return (
        complaint.complainantId.includes(basicFilter.customerId) &&
        complaint.respondentId.includes(basicFilter.freelancerId) &&
        (basicFilter.statusComplaint === "" ||
          complaint.status === basicFilter.statusComplaint)
      );
    },
    [basicFilter]
  );

  const isPassRangeTimeFilter = useCallback(
    (complaint: complaintPropsType) => {
      return true
      // return (
      //   isMoreThan(
      //     complaint.createAt.toString(),
      //     convertToDDMMYYYY(rangeTimeFilter.from)
      //   ) &&
      //   isLessThan(
      //     complaint.createAt.toString(),
      //     convertToDDMMYYYY(rangeTimeFilter.to)
      //   )
      // );
    },
    [rangeTimeFilter]
  );

  const isPassSearchAndFilter = useCallback(
    (complaint: complaintPropsType) => {
      return (
        (searchValue.trim() === "" ||
          complaint.orderId.includes(searchValue.trim().toLowerCase())) &&
        isPassBasicFilter(complaint) &&
        isPassRangeTimeFilter(complaint)
      );
    },
    [isPassBasicFilter, isPassRangeTimeFilter, searchValue]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tabsComplaint: complaintPropsType[] = useMemo(() => {
    if (!complainList) return [];
    return complainList.filter((complaint) =>
      isPassSearchAndFilter(complaint)
    )
    // return [
    //   {
    //     key: "all",
    //     title: "Tất cả",
    //     description: "Tổng khiếu nại đã nhận",
    //     values: complainList.filter((complaint) =>
    //       isPassSearchAndFilter(complaint)
    //     ),
    //   },
    //   {
    //     key: "need-action",
    //     title: "Cần giải quyết",
    //     description: "Số khiệu nại chưa giải quyết",
    //     values: complainList.filter((complaint) =>
    //       complaint.resolvingDescription.trim() === ""
    //     ),
    //   },
    //   {
    //     key: "done",
    //     title: "Đã giải quyết",
    //     description: "Số khiệu nại đã giải quyết",
    //     values: complainList.filter((complaint) =>
    //       complaint.resolvingDescription.trim() !== ""
    //     ),
    //   },
    // ];
  }, [isPassSearchAndFilter, complainList]);

  // const filterComplaintByToolbox = useCallback(
  //   (complaints: tabComplainType): tabComplainType => {
  //     return complaints;
  //   },
  //   // [advanceFilter, basicFilter]
  //   []
  // );

  // const searchedAndFilterededOrders = useMemo(() => {
  //   if (!complainList) return [];
  //   return filterComplaintByToolbox(tabsComplaint)
  //     .values.filter((complaint) => complaint.orderId.includes(searchValue))
  //     .slice(
  //       currentIndexPage * NUMBER_ITEMS_IN_PAGE,
  //       currentIndexPage * NUMBER_ITEMS_IN_PAGE + NUMBER_ITEMS_IN_PAGE
  //     );
  // }, [
  //   complainList,
  //   filterComplaintByToolbox,
  //   tabsComplaint,
  //   indexStatusSelected,
  //   currentIndexPage,
  //   searchValue,
  // ]);


  if (isFetching) {
    return <LoadingItem />;
  }

  if (isError) {
    return <ErrorItem />;
  }
  return (
    <div className="bg-silver p-5 pb-10">
      <Tittle align="left" color="black">
        Quản lý danh sách khiếu nại
      </Tittle>
      <Box my={10} />
      <Flex className="w-full justify-between">
        <InputGroup size="sm" w={300} bg="white">
          <InputLeftElement
            cursor="pointer"
            children={<i className="fi fi-bs-search" />}
            onClick={handleSearch}
          />

          <Input
            borderRadius={5}
            type="text"
            onKeyPress={handleKeyPress}
            value={inputSearch}
            onChange={handleSearchChange}
            placeholder="Nhập tên người (bị) khiếu nại "
            border="1px solid #949494"
          />
        </InputGroup>
        <Flex alignItems={"center"} gap={2}>
          {/* <Input
            placeholder="Select Date and Time"
            size="sm"
            type="date"
            border="1px solid #949494"
            borderRadius={5}
            onChange={(e) =>
              setRangeTimeFilter((prevState) => ({
                ...prevState,
                from: e.target.value,
              }))
            }
          />
          <Text>Đến</Text>
          <Input
            placeholder="Select Date and Time"
            size="sm"
            type="date"
            border="1px solid #949494"
            borderRadius={5}
            onChange={(e) =>
              setRangeTimeFilter((prevState) => ({
                ...prevState,
                to: e.target.value,
              }))
            }
          /> */}
          {/* <Button
            leftIcon={<i className="fi fi-ss-filter" />}
            bg="white"
            size="sm"
            border="1px solid #949494"
            ml={3}
            minW={"fit-content"}
            onClick={onOpen}
          >
            Lọc
          </Button>
          <FilterComplaintModal
            isOpen={isOpen}
            onClose={onClose}
            setBasicFilter={setBasicFilter}
          /> */}
        </Flex>
      </Flex>

      <Tabs
        position="relative"
        // isFitted
        variant="unstyled"
        className="my-8"
        index={indexStatusSelected}
        onChange={(index) => {
          setIndexStatusSelected(index);
          setDataPaging(prev => {
            if (prev) {
              return {
                ...prev,
                currentPage: 1
              };
            }
            return prev;
          });
        }}
      >
        <TabList className=" border-0 border-b-[1px]  border-b-red-lightShade">
          {TAB_LIST.map((tab) => (
            <Tab
              _selected={{ color: "#4285F4", paddingX: "2" }}
              color="#A2A4AF"
              fontSize="small"
              minW="-webkit-fit-content"
            >
              {tab.title}
            </Tab>
          ))}
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="#4285F4"
          borderRadius="1px"
        />
        <Box w="min-content">
          <Box>
            <ComplaintTable
              complaints={complainList ?? []}
              paging={
                <Flex justifyContent="center">
                  <Pagination
                    indexCurrentPage={dataPaging?.currentPage ?? 1}
                    onChangePage={(newPageNum) => {
                      if (dataPaging) {
                        setDataPaging(prev => {
                          if (prev) {
                            return {
                              ...prev,
                              currentPage: newPageNum
                            };
                          }
                          return prev;
                        });
                      }
                    }}
                    numberPage={dataPaging?.totalPages ?? 1}
                  />
                </Flex>
              }
            />
          </Box>
        </Box>
      </Tabs>
    </div>
  );
};

export default Complaint;
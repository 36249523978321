import { Routes, Route, Navigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import { DashboardTemplate } from "presentational/template/DashboardTemplate";
import Overview from "pages/Overview";
import Complaint from "pages/Complaint";
import CategoryManagement from "pages/CategoryManagement";
import ServiceOrder from "pages/ServiceOrder";
import UsersLicenses from "pages/UsersLicenses";
import UsersManagement from "pages/UsersManagement";
import UserChat from "pages/UserChat";
import { Login } from "pages/Login";
import CategoryDetail from "pages/CategoryDetail";
import CategoriesAddition from "pages/CategoriesAddition";
import ServicesAddition from "pages/ServicesAddition";
import { ManageServicesRequirementsProvider } from "businessLogic/context";
import "react-toastify/ReactToastify.css";
import { ChatManagementProvider } from "businessLogic/context/chatManagement";
import CategoriesEditing from "pages/CategoriesEditing";
import ServicesEditing from "pages/ServicesEditing";
import ServiceOrderDetail from "pages/ServiceOrderDetail";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ComplaintDetail from "pages/ComplaintDetail";
import UserDetail from "pages/CustomerDetail";
import FreelancerDetail from "pages/FreelancerDetail";
import { useSession } from "businessLogic/hook";
import { OverManagementProvider } from "businessLogic/context/overviewManagement";
import AdminPermission from "pages/AdminPermission";
import { HistoryPayment } from "pages/HistoryPayment";

function App() {
  const { isAuthenticated, servicesPermission } = useSession();

  return (
    <div className="App">
      {!isAuthenticated ? (
        <Routes>
          <Route
            path="*"
            element={<Navigate to={`${PAGES.LOGIN}`} replace />}
          />
          <Route
            path="/"
            element={<Navigate to={`${PAGES.LOGIN}`} replace />}
          />
          <Route path="/login" element={<Login />} />
        </Routes>
      ) : (
        <OverManagementProvider>
          <DashboardTemplate>
            <Routes>
              <Route path="*" element={<div>Not found</div>} />
              {
                servicesPermission.overview ? <Route
                  path="/"
                  element={<Navigate to={`${PAGES.OVERVIEW}`} replace />}
                />
                  : servicesPermission.complaint ?
                    <Route
                      path="/"
                      element={<Navigate to={`${PAGES.COMPLAINT}`} replace />}
                    />
                    : servicesPermission.adminPermission ? <Route
                      path="/"
                      element={<Navigate to={`${PAGES.ADMIN_PERMISSION}`} replace />}
                    />
                      : servicesPermission.order ? <Route
                        path="/"
                        element={<Navigate to={`${PAGES.SERVICE_ORDER}`} replace />}
                      /> : servicesPermission.service ? <Route
                        path="/"
                        element={<Navigate to={`${PAGES.SERVICE_MANAGEMENT}`} replace />}
                      /> : servicesPermission.userManagement ? <Route
                        path="/"
                        element={<Navigate to={`${PAGES.USER_MANAGEMENT}`} replace />}
                      /> : null
              }
              {
                servicesPermission.overview ?
                  <Route path={`${PAGES.OVERVIEW}`} element={<Overview />} />
                  : null
              }
              {servicesPermission.complaint ?
                <>
                  <Route path={`${PAGES.COMPLAINT}`} element={<Complaint />} />

                  <Route
                    path={`${PAGES.COMPLAINT}/:id`}
                    element={<ComplaintDetail />}
                  /></>
                : null}
              {
                servicesPermission.order ? <>
                  <Route
                    path={`${PAGES.SERVICE_ORDER}`}
                    element={<ServiceOrder />}
                  />
                  <Route
                    path={`${PAGES.SERVICE_ORDER}/:id`}
                    element={<ServiceOrderDetail />}
                  />
                </> : null
              }
              {
                servicesPermission.userManagement
                  ?
                  <>
                    <Route
                      path={`${PAGES.USER_LICENSES}`}
                      element={<UsersLicenses />}
                    />
                    <Route
                      path={`${PAGES.USER_MANAGEMENT}`}
                      element={<UsersManagement />}
                    />
                    <Route
                      path={`${PAGES.USER_MANAGEMENT}/customer/:id`}
                      element={<UserDetail />}
                    />
                    <Route
                      path={`${PAGES.USER_MANAGEMENT}/freelancer/:id`}
                      element={<FreelancerDetail />}
                    />
                    <Route
                      path={`${PAGES.USER_CHAT}`}
                      element={
                        <ChatManagementProvider>
                          <UserChat />
                        </ChatManagementProvider>
                      }
                    />
                  </> : null
              }

              {
                servicesPermission.service ? <>
                  <Route
                    path={`${PAGES.SERVICE_MANAGEMENT}`}
                    element={<CategoryManagement />}
                  />
                  <Route
                    path={`${PAGES.SERVICE_MANAGEMENT}/:id`}
                    element={<CategoryDetail />}
                  />


                  <Route
                    path={`${PAGES.CATEGORIES_ADDITION}`}
                    element={<CategoriesAddition />}
                  />

                  <Route
                    path={`${PAGES.CATEGORIES_EDITING}/:id`}
                    element={<CategoriesEditing />}
                  />

                  <Route
                    path={`${PAGES.SERVICES_ADDITION}/:id`}
                    element={
                      <ManageServicesRequirementsProvider>
                        <ServicesAddition />
                      </ManageServicesRequirementsProvider>
                    }
                  />
                  <Route
                    path={`${PAGES.SERVICES_EDITING}/:id`}
                    element={
                      <ManageServicesRequirementsProvider>
                        <ServicesEditing />
                      </ManageServicesRequirementsProvider>
                    }
                  />
                </> : null
              }

              {
                servicesPermission.adminPermission ?
                  <>
                    <Route
                      path={`${PAGES.ADMIN_PERMISSION}`}
                      element={
                        <AdminPermission />
                      }
                    />
                  </> : null
              }
              {
                servicesPermission.payment ?
                  <>
                    <Route
                      path={`${PAGES.HISTORY_PAYMENT}`}
                      element={
                        <HistoryPayment />
                      }
                    />
                  </> : null
              }

            </Routes>
          </DashboardTemplate>
        </OverManagementProvider>
      )}
    </div>
  );
}

export default App;
import { BaseBtn, Paragraph } from "presentational/atoms";

type paginationType = {
  numberPage: number;
  indexCurrentPage: number;
  onChangePage: (page: number) => void;
};

const LIMIT_DISPLAY_PAGING_ITEM = 7;

const DotPaging = ({
  number,
  isSelected,
}: {
  number: number;
  isSelected: boolean;
}) => (
  <div
    className={`w-6 h-6 flex justify-center items-center ${
      isSelected ? "bg-blue border-solid rounded-full w-6 h-6" : ""
    }`}
  >
    <Paragraph color={isSelected? "white": "black"}>{number}</Paragraph>
  </div>
);

export const Pagination = ({
  numberPage,
  indexCurrentPage,
  onChangePage,
}: paginationType) => {
  return (
    <div className="flex items-center justify-center bg-white px-4 py-3 w-full mt-5">
      <nav className="flex items-center justify-center gap-5">
        <BaseBtn
          onPress={() => {
            if (indexCurrentPage >= 0) {
              onChangePage(indexCurrentPage);
            }
          }}
          title={<i className="fi fi-bs-angle-left" />}
        />
        {numberPage > LIMIT_DISPLAY_PAGING_ITEM ? (
          <>
            {Array.from({ length: 5 }).map((_, index) => (
              <BaseBtn
                key={index+1}
                onPress={() => onChangePage(index+1)}
                title={
                  <DotPaging
                    number={index + 1}
                    isSelected={indexCurrentPage === index +1}
                  />
                }
              />
            ))}
            <BaseBtn onPress={() => {}} title={<Paragraph>...</Paragraph>} />
            <BaseBtn
              onPress={() => onChangePage(numberPage)}
              title={
                <DotPaging
                  number={numberPage}
                  isSelected={indexCurrentPage === numberPage}
                />
              }
            />
          </>
        ) : (
          <>
            {Array.from({ length: numberPage }).map((_, index) => (
              <BaseBtn
                key={index}
                onPress={() => onChangePage(index +1)}
                title={
                  <DotPaging
                    number={index + 1}
                    isSelected={indexCurrentPage === index +1}
                  />
                }
              />
            ))}
          </>
        )}
        <BaseBtn
          onPress={() => {
            if (indexCurrentPage + 1 < numberPage) {
              onChangePage(indexCurrentPage + 1);
            }
          }}
          title={<i className="fi fi-bs-angle-right" />}
        />
      </nav>
    </div>
  );
};

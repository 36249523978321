import React, { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { routeType } from "constants/sideBarRoute";
import { Text } from "@chakra-ui/react";

const SlideBarLinksWrapper = ({
  route,
  children,
}: {
  route: routeType;
  children: ReactNode;
}) => {
  const location = useLocation();
  if (Array.isArray(route.children)) {
    if (location.pathname.includes(route.path)) {
      return <React.Fragment>{children}</React.Fragment>;
    } else {
      return <Link to={route.children[0].path}>{children}</Link>;
    }
  } else {
    return <Link to={route.path}>{children}</Link>;
  }
};

export const SidebarLinks = ({ routes }: { routes: routeType[] }) => {
  const location = useLocation();
  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes: routeType[]) => {
    return routes.map((route, index) => {
      const isChildSelected = route.children ? route.children.some((child) => location.pathname.includes(child.path)) : false;
      return (
        <React.Fragment key={route.name}>
          <SlideBarLinksWrapper route={route}>
            <div className="mb-4 flex hover:cursor-pointer min-w-36">
              <li
                className="flex cursor-pointer items-center "
                key={`${index}-${route.name}-${route.path}`}
              >
                <span
                  className={`${
                    activeRoute(route.path) === true
                      ? "text-white"
                      : "text-gray-medium"
                  } mr-2`}
                >
                  {route.icon}
                </span>
                <Text
                  color={activeRoute(route.path) ? "white" : "gray"}
                >
                  {route.name}
                </Text>
              </li>
            </div>
          </SlideBarLinksWrapper>
          {isChildSelected ?route.children?.map((child) => (
            <Link key={child.name} to={child.path}>
              <div className="mb-3 flex hover:cursor-pointer  pl-5">
                <div className="flex cursor-pointer items-center">
                  <span
                    className={`${
                      activeRoute(child.path) === true
                        ? "text-white"
                        : "text-gray-medium"
                    } mr-2`}
                  >
                    <i className="fi fi-rr-caret-right" />
                  </span>

                  <Text
                    color={activeRoute(child.path) ? "white" : "gray"}
                  >
                    {child.name}
                  </Text>
                </div>
              </div>
            </Link>
          )): null}
        </React.Fragment>
      );
    });
  };

  return <>{createLinks(routes)}</>;
};

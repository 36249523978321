import { useManageServiceRequirements } from "businessLogic/context";
import { BaseBtn, Tittle, VerticalSpacer } from "presentational/atoms";
import {
  ActiveSection,
  PriceSection,
  ImageSection,
  KeywordSection,
  NameAndDescriptionSection,
  RequestServicesSection,
  AddressRequirementSection,
  ErrorItem,
  LoadingItem,
} from "presentational/organisms";
import { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { serviceType } from "services/lib/servicesService/type";
import { serviceSchema, useYupValidationResolver } from "validators/yup";
import VisibilitySensor from "react-visibility-sensor";
import { ValidateServiceRequirements } from "helpers/ValidateServiceRequirements";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetDetailServiceQuery,
  useUpdateServiceMutation,
} from "../business-layer/services/lib/apis";
import { baseCdnImage } from "helpers/baseCdnImage";
import { useUpload } from "../business-layer/business-logic/context/useUpload";
import { generateRandomID } from "helpers/generateRandomID";
import { PAGES } from "constants/pages";

const ServicesEditing = () => {
  const formResolver = useYupValidationResolver(serviceSchema);
  const navigate = useNavigate();
  const { serviceRequirements, onAdd, setServiceRequirement } =
    useManageServiceRequirements();
  const notifyError = (message?: string) =>
    toast.error(
      message ? message : "Lỗi thiếu thông tin hoặc không đúng định dạng"
    );
  const notifySuccess = () => toast.success("Cập nhật dịch vu thành công");
  // const [isLoadedData, setIsLoadedDate] = useState<boolean>(false);
  const { id } = useParams();
  const { onUpload } = useUpload();
  const {
    data: dataService,
    isFetching,
    isError,
  } = useGetDetailServiceQuery(id ?? "id");
  const [updateService] = useUpdateServiceMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    reset,
  } = useForm<serviceType>({
    resolver: formResolver,
  });

  useEffect(() => {
    if (isError || !dataService) return;
    // setIsLoadedDate(true);
    setServiceRequirement(dataService?.requirements ?? []);
    reset({
      ...dataService,
      image: baseCdnImage(dataService.image),
      requirements: undefined,
    });
  }, [isError, dataService]);

  const [serviceRequirementsError, setServiceRequirementsError] = useState<
    Record<string, string[]>
  >({});

  const existRequirementServiceError = useMemo(() => {
    const serviceRequirementsError =
      ValidateServiceRequirements(serviceRequirements);
    return Object.values(serviceRequirementsError).some(
      (contentError) => contentError.length !== 0
    );
  }, [serviceRequirements]);

  const onSubmitSuccess: SubmitHandler<serviceType> = async (data) => {
    setServiceRequirementsError(
      ValidateServiceRequirements(serviceRequirements)
    );
    if (existRequirementServiceError) {
      notifyError();
      return;
    }
    let image = dataService.image;
    try {
      if (baseCdnImage(dataService.image) !== data.image) {
        const { path } = await onUpload({
          uri: data.image,
          name: data.name,
          path: `/category/${generateRandomID()}`,
          type: "JPEG",
        });
        image = path;
      }

      await updateService({
        ...data,
        image,
        requirements: serviceRequirements,
      });
      notifySuccess();
      navigate(PAGES.SERVICE_MANAGEMENT)
    } catch (error) {
      notifyError("Cập nhật danh mục thất bại");
    }
  };
  const onSubmitFail = () => {
    setServiceRequirementsError(
      ValidateServiceRequirements(serviceRequirements)
    );

    if (errors || existRequirementServiceError) {
      notifyError();
    }
  };

  const [isEnableAddServiceRequirement, setIsEnableAddServiceRequirement] =
    useState<boolean>(false);

  if (isFetching) {
    return <LoadingItem />;
  }

  if (isError) {
    return <ErrorItem />;
  }

  return (
    <div className="bg-silver p-5 pb-10">
      <ToastContainer />
      <form
        className="bg-white rounded-lg shadow-md p-4 mb-10 mt-3 relative"
        onSubmit={handleSubmit(onSubmitSuccess, onSubmitFail)}
      >
        <Tittle align="left" color="black">
          Cập nhật dịch vụ
        </Tittle>
        <VerticalSpacer size="xl" />
        <div className="addition-container">
          <div className="column-first-addition w-full">
            <NameAndDescriptionSection
              control={control}
              errors={errors}
              initDescription={getValues().description}
            />
          </div>
          <div className="column-second-addition w-full flex flex-col justify-between h-full">
            <PriceSection control={control} errors={errors} />
            <VerticalSpacer size="m" />
            <ActiveSection control={control} />
          </div>

          <div className="column-first-addition ">
            <KeywordSection
              control={control}
              errors={errors}
              initKeyword={getValues().keys}
            />
          </div>
          <div className="column-second-addition w-full">
            <AddressRequirementSection control={control} />
          </div>
          <div className="column-first-addition ">
            <ImageSection control={control} errors={errors} />
          </div>
        </div>
        <VerticalSpacer size="xl" />

        <div className="addition-container">
          <VisibilitySensor
            partialVisibility
            onChange={(isVisible: boolean) => {
              setIsEnableAddServiceRequirement(isVisible);
            }}
          >
            <RequestServicesSection
              serviceRequirements={serviceRequirements}
              serviceRequirementsError={serviceRequirementsError}
            />
          </VisibilitySensor>
        </div>
        {isEnableAddServiceRequirement ? (
          <div className="text-right fixed bottom-7 right-8">
            <BaseBtn
              title=""
              icon={<i className="fi fi-sr-add text-3xl" />}
              onPress={onAdd}
            />
          </div>
        ) : (
          <></>
        )}
        <VerticalSpacer size="xl" />
        <div className="column-full-addition">
          <BaseBtn
            title="Xác nhận cập nhật dịch vụ"
            onPress={() => {}}
            backgroundColor="blueShade-shade"
            color="white"
            padding="10px 15vw"
            radius="rounded"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default ServicesEditing;
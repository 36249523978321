import { ApiHelper, detoiApi } from "businessLogic/redux/adminApi";

export type conversationDetailPropsType = {
    receiverId: string | null;
    page: number;
    pageSize: number;
};

export type sendMessagePropsType = {
    sendTo: string;
    isSystem: boolean;
    image?: string;
    content: string;
};

const api = detoiApi.injectEndpoints({
    endpoints: (builder) => ({
        getChatHistory: builder.query({
            query: () => ApiHelper.get(`/api/v1/account/chat/history`, {}),
        }),
        getChatHistoryDetail: builder.query({
            query: ({ page, pageSize, receiverId }: conversationDetailPropsType) => {
                return ApiHelper.get(`/api/v1/account/chat/detail`, {
                    params: {
                        receiverId,
                        page,
                        pageSize,
                    },
                });
            },
        }),
        sendMessage: builder.mutation({
            query: (data: sendMessagePropsType) => ApiHelper.post(`/api/v1/account/chat`, {
                data
            }),
        }),
    }),
});

export const {
    useGetChatHistoryQuery,
    useGetChatHistoryDetailQuery,
    useSendMessageMutation,
    endpoints: { getChatHistory, getChatHistoryDetail, sendMessage }
} = api;
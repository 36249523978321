import {
  CategoriesSearch,
  Pagination,
  ServiceCard,
} from "presentational/molecules";

import { BaseBtn } from "presentational/atoms";
import { useEffect, useMemo, useState } from "react";
import { Paragraph } from "presentational/atoms";
import { NUMBER_ITEMS_IN_PAGE } from "constants/serviceManagement";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import { useGetCategoriesQuery } from "../business-layer/services/lib/apis/useCategory";
import { ErrorItem, LoadingItem } from "presentational/organisms";
import { ICategory } from "services/entities";

export const CategoryManagement = () => {
  const navigate = useNavigate();
  const [currentIndexPage, setCurrentIndexPage] = useState<number>(0);
  const [searchContent, setSearchContent] = useState<string>("");
  const { data, isFetching, isError } = useGetCategoriesQuery({});
  const [listCategory, setListCategory] = useState<ICategory[]>();

  useEffect(() => {
    if (!isError) {
      setListCategory(data);
    }
  }, [isError, data]);

  const numberPages = listCategory
    ? Math.ceil(listCategory.length / NUMBER_ITEMS_IN_PAGE)
    : 0;
  const visibleCategories = useMemo(() => {
    if (!listCategory) return [];
    return listCategory
      .filter((category) =>
        category.name
          .toLocaleLowerCase()
          .includes(searchContent.toLocaleLowerCase())
      )
      .slice(
        currentIndexPage * NUMBER_ITEMS_IN_PAGE,
        currentIndexPage * NUMBER_ITEMS_IN_PAGE + NUMBER_ITEMS_IN_PAGE
      );
  }, [currentIndexPage, searchContent, listCategory]);

  if (isFetching) {
    return <LoadingItem />;
  }

  if (isError) {
    return <ErrorItem />;
  }
  
  return (
    <div className="flex justify-center flex-col">
      <div className="bg-white rounded-lg shadow-md flex gap-20 p-4 items-center mb-10 mt-3">
        <CategoriesSearch
          placeholder="Tìm kiếm theo tên danh mục..."
          onSearch={setSearchContent}
        />
        <BaseBtn
          title={
            <div className="min-w-32">
              <Paragraph color="white" align="center">
                Thêm danh mục
              </Paragraph>
            </div>
          }
          onPress={() => {
            navigate(PAGES.CATEGORIES_ADDITION);
          }}
          padding="15px"
          icon={<i className="fi fi-bs-plus" />}
          iconPosition="left"
          backgroundColor="primary"
          color="white"
          radius="full"
        />
      </div>
      <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 mx-4">
        {visibleCategories.map((category) => (
          <ServiceCard
            key={category.id}
            serviceId={category.id}
            serviceBackground={category.image}
            serviceName={category.name}
            serviceTypeCount={category.serviceTypeCount ?? 0}
          />
        ))}
        {visibleCategories.length < 6 &&
          [...Array(6 - visibleCategories.length)].map((_, index) => (
            <div key={`blank-${index}`} className="h-52 w-full"></div>
          ))}
      </div>
      <Pagination
        indexCurrentPage={currentIndexPage}
        onChangePage={setCurrentIndexPage}
        numberPage={numberPages}
      />
    </div>
  );
};

export default CategoryManagement;


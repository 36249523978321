import { PAGES } from "constants/pages";
import { baseCdnImage } from "helpers/baseCdnImage";
import { Paragraph } from "presentational/atoms";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const ServiceCard = ({
	serviceBackground,
	serviceName,
	serviceTypeCount,
	serviceId,
}: {
	serviceBackground: string;
	serviceName: string;
	serviceTypeCount: number;
	serviceId: string;
}) => {
	const serviceImage = useMemo(() =>{
		return baseCdnImage(serviceBackground)
	}, [baseCdnImage, serviceBackground])
	const navigate = useNavigate();
	return (
		<div
			onClick={() => {
				navigate(`${PAGES.SERVICE_MANAGEMENT}/${serviceId}`);
			}}
			className=" w-full h-52 border-solid rounded-md cursor-pointer relative "
		>
			<img
				src={serviceImage}
				alt="thumbnail"
				className="w-full h-full rounded-md object-cover"
			/>
			<div
				className="absolute bottom-0  opacity-85 rounded-md w-full h-40"
				style={{
					backgroundImage:
						"linear-gradient(to bottom, transparent, #000000)",
				}}
			/>

			<div className="absolute left-0 bottom-0 p-3 flex flex-col justify-end gap-1">
				<Paragraph
					fontWeight="bold"
					size="lg"
					align="left"
					color="white"
				>
					{serviceName}
				</Paragraph>
				<Paragraph
					align="left"
					color="white"
					fontWeight="light"
					size="sm"
				>
					{serviceTypeCount} dịch vụ
				</Paragraph>
			</div>
		</div>
	);
};

import { VStack, HStack, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { ICustomerAccountDetail } from "services/entities";

export const CustomerAddressSection = React.memo(
  ({ customerDetail }: { customerDetail: ICustomerAccountDetail }) => {
    return (
      <VStack
        className="bg-silver border border-solid shadow-md"
        borderRadius={5}
        p={5}
        gap={5}
        alignItems={"flex-start"}
      >
        <HStack>
          <i className="fi fi-ss-marker" />
          <Text>Địa chỉ</Text>
        </HStack>
        <Flex flexWrap={"wrap"} gap={5}>
          {customerDetail?.addresses?.map((adress, index) => (
            <VStack
              gap={2}
              alignItems={"flex-start"}
              maxW={250}
              bg="white"
              p={3}
              borderRadius={5}
            >
              <Text className="text-gray-light">Địa chỉ {index + 1}</Text>
              <Text>
                {[
                  adress.addressLine,
                  adress.ward,
                  adress.district,
                  adress.country,
                ].join(", ")}
              </Text>
            </VStack>
          ))}
        </Flex>
      </VStack>
    );
  }
);

import {
    Tabs,
    TabList,
    Tab,
    TabIndicator,
    TabPanels,
    TabPanel,
    Box,
    useDisclosure,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast,
} from "@chakra-ui/react";
import { Tittle } from "presentational/atoms";
import React from "react";
import TableRow from "presentational/atoms/table/TableRow";
import { baseCdnImage } from "helpers/baseCdnImage";
import {
    useGetAdminAllQuery,
    useSetRoleAdminMutation
} from "../business-layer/services/lib/apis";
import { ErrorItem, LoadingItem } from "presentational/organisms";

export enum ADMIN_CATEGORY_KEY {
    ALL = "all",
    ADMIN = "Admin",
    PROFILE_VERIFY_ADMIN = "ProfileVerifyAdmin",
    CUSTOMER_SERVICE_ADMIN = "CustomerServiceAdmin",
    APP_SERVICE_ADMIN = "AppServiceAdmin",
    DEFAULT_CREATE_ADMIN = "DefaultCreatedAdmin",
}

const ADMIN_CATEGORIES = {
    [ADMIN_CATEGORY_KEY.ALL]: "Tất cả",
    [ADMIN_CATEGORY_KEY.ADMIN]: "Admin",
    [ADMIN_CATEGORY_KEY.PROFILE_VERIFY_ADMIN]: "Duyệt hồ sơ",
    [ADMIN_CATEGORY_KEY.CUSTOMER_SERVICE_ADMIN]: "Quản lý đơn dịch vụ, khiếu nại",
    [ADMIN_CATEGORY_KEY.APP_SERVICE_ADMIN]: "Quản lý dịch vụ",
    [ADMIN_CATEGORY_KEY.DEFAULT_CREATE_ADMIN]: "Ban đầu",
};

const AdminTableHeader = () => {
    return (
        <thead className="text-gray font-medium">
            <tr>
                {/* <th scope="col" className="px-2 py-3 w-[15%] text-sm">
                    ID
                </th> */}
                <th scope="col" className="px-2 py-3 w-[10%] text-sm">
                    Ảnh đại diện
                </th>
                <th scope="col" className="px-2 py-3 w-[30%] text-sm">
                    Thông tin chung
                </th>
                <th scope="col" className="px-2 py-3 w-[30%] text-sm ">
                    Số điện thoại
                </th>

                <th scope="col" className="px-2 py-3 w-[10%]"></th>
            </tr>
        </thead>
    );
};

type ModalGrantRoleAdminProps = {
    adminId: string,
    roleSelected: ADMIN_CATEGORY_KEY,
    refetch: () => void
}

const ModalGrantRoleAdmin = ({ adminId, roleSelected, refetch }: ModalGrantRoleAdminProps) => {
    const [setRoleAdmin] = useSetRoleAdminMutation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [newRole, setNewRole] = React.useState<ADMIN_CATEGORY_KEY>()
    const toast = useToast()
    const updateStatusSuccess = () => {
        toast({
            title: "Cấp quyền thành công",
            status: "success",
            isClosable: true,
            position: "top"
        })
    }
    const updateStatusFail = () => {
        toast({
            title: "Cấp quyền thất bại",
            status: "error",
            isClosable: true,
            position: "top"
        })
    }
    return (
        <React.Fragment>
            <span className="relative">
                <button onClick={onOpen}>• • •</button>
            </span>
            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={() => { onClose(); setNewRole(undefined) }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Chọn role muốn thay đổi</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody className="flex flex-wrap gap-4">
                        {
                            Object.entries(ADMIN_CATEGORIES).filter(([key, value]) => ![ADMIN_CATEGORY_KEY.ALL, roleSelected, ADMIN_CATEGORY_KEY.ADMIN, ADMIN_CATEGORY_KEY.PROFILE_VERIFY_ADMIN].includes(key as ADMIN_CATEGORY_KEY)).map(([key, value], index) => {
                                return (
                                    <Box onClick={() => setNewRole(key as ADMIN_CATEGORY_KEY)} className={`border-2  w-fit px-5 py-3 rounded-md cursor-pointer ${key === newRole ? 'border-green-medium' : 'border-gray-extraLight'}`}>
                                        {value}
                                    </Box>
                                )
                            })
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='gray' mr={3} onClick={() => { onClose(); setNewRole(undefined) }}>
                            Đóng
                        </Button>
                        <Button isDisabled={!newRole} colorScheme='blue' onClick={() => {
                            if (!newRole) {
                                onClose()
                                return;
                            }
                            let isFail = false;
                            setRoleAdmin({
                                adminId,
                                roleName: newRole
                            }).then((status) => {
                                if (status.error) {
                                    isFail = true
                                    updateStatusFail()
                                } else {
                                    updateStatusSuccess()
                                }
                            }).catch(() => {
                                updateStatusFail()
                            }).finally(() => {
                                onClose();
                                refetch();
                            })

                        }}>Thay đổi role</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </React.Fragment>
    )
}
const AdminPermission = () => {

    const [categoryAdminSelected, setCategoryAdminSelected] =
        React.useState<ADMIN_CATEGORY_KEY>(ADMIN_CATEGORY_KEY.ALL);

    const { data, isError, isFetching, refetch } =
        useGetAdminAllQuery({});
    return (
        <div className="bg-white p-5 pb-10 max-h-[90vh] overflow-y-scroll overflow-x-hidden">
            <Tittle align="left" color="black">
                Cấp quyền admin
            </Tittle>

            <Tabs position="relative" variant="unstyled" className="my-8">
                <TabList className=" border-0 border-b-[1px]  border-b-red-lightShade">
                    {Object.entries(ADMIN_CATEGORIES).map(([key, adminType], index) => (
                        <Tab
                            key={index}
                            _selected={{ color: "#4285F4", paddingX: "2" }}
                            color="#A2A4AF"
                            fontSize="small"
                            minW="-webkit-fit-content"
                            onClick={() => setCategoryAdminSelected(key as ADMIN_CATEGORY_KEY)}
                        >
                            {adminType}
                        </Tab>
                    ))}
                </TabList>
                <TabIndicator
                    mt="-1.5px"
                    height="2px"
                    bg="#4285F4"
                    borderRadius="1px"
                />
                <TabPanels className="min-h-[60vh]">
                    {Object.entries(ADMIN_CATEGORIES).map(([key], tabIndex) => (
                        <TabPanel key={tabIndex} w="78vw">
                            {isFetching ? <LoadingItem /> : !data?.data || isError ? <ErrorItem /> :
                                <table className="text-xs text-center bg-white rounded-md w-full">
                                    <AdminTableHeader />
                                    <tbody>
                                        {isFetching ? <LoadingItem /> : data.data.filter((acc: any) => key === ADMIN_CATEGORY_KEY.ALL || acc.account.role === key).map((account: any, index: number) => (
                                            <tr key={index}>
                                                <TableRow
                                                    key={account?.id ?? index}
                                                    listData={[
                                                        // <span className="font-semibold">
                                                        //     <span className="line-clamp-1 font-medium text-sm">
                                                        //         {/* <span className="font-normal">{account}</span> */}
                                                        //     </span>
                                                        // </span>,
                                                        <span className="flex items-center justify-center">
                                                            <img
                                                                src={baseCdnImage(account?.account?.avatar)}
                                                                alt="Lỗi ảnh đại diện"
                                                                className="rounded-full h-14 w-14 cursor-pointer object-cover"
                                                            />
                                                        </span>,
                                                        <span className="font-semibold">
                                                            <span className="line-clamp-1 font-medium text-sm">
                                                                <b>Tên:</b>{" "}
                                                                <span className="font-normal">
                                                                    {account?.account?.fullName}
                                                                </span>
                                                            </span>
                                                            <span className="line-clamp-1 font-medium text-sm">
                                                                <b>Ngày sinh:</b>{" "}
                                                                <span className="font-normal">
                                                                    {account?.account?.dateOfBirth || "Chưa cập nhật"}
                                                                </span>
                                                            </span>
                                                            <span className="line-clamp-1 font-medium text-sm">
                                                                <b>Giới tính:</b>{" "}
                                                                <span className="font-normal">
                                                                    {account?.account?.gender === "male"
                                                                        ? "Nam"
                                                                        : "Nữ"}
                                                                </span>
                                                            </span>
                                                        </span>,
                                                        <span className="font-semibold">
                                                            <span className="line-clamp-1 font-medium text-sm ">
                                                                <span className="font-normal">
                                                                    {account?.account?.phone}
                                                                </span>
                                                            </span>
                                                        </span>,
                                                        account?.account?.role !== ADMIN_CATEGORY_KEY.ADMIN ? <ModalGrantRoleAdmin refetch={refetch} adminId={account?.account?.id ?? ""} roleSelected={account?.account?.role} /> : null
                                                    ]}
                                                />
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>}

                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </div>
    );
};


export default AdminPermission;

import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
} from "@chakra-ui/react";
import { SERVICES_STATUS } from "constants/serviceOrder";
import { Paragraph } from "presentational/atoms";
import React, { ReactNode } from "react";
import { IOrderDetail } from "services/entities";

type OrderTableProps = {
  rows: ReactNode[];
  paging: ReactNode;
  title: string[];
};

export default function CustomTable({ title, rows, paging }: OrderTableProps) {
  return (
    <TableContainer className="bg-white w-full rounded-md shadow-md">
      <Table>
        <Thead className="bg-blueShade-lightShade">
          <Tr>
            {title.map((item) => (
              <Th textTransform="none" textAlign={"center"} paddingX="0">
                <Paragraph fontWeight="bold" size="xs" align="center">
                  {item}
                </Paragraph>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rows.map((row) => {
            return <>{row}</>;
          })}
        </Tbody>
        {rows.length < 6 &&
          [...Array(6 - rows.length)].map((_, index) => (
            <div key={`blank-${index}`} className="h-12 w-full"></div>
          ))}
      </Table>
      {paging}
    </TableContainer>
  );
}

import { Text } from "@chakra-ui/react";

export const EmptyInsight = ({ type }: { type?: string }) => {
  return (
    <div
      className={`flex gap-2 p-4 justify-start items-center border-solid rounded-md bg-white max-w-full`}
    >
      <Text className="text-gray-light font-light text-xl">
        Không có bất kì thông tin {type ? type : "dữ liệu"} nào
      </Text>
    </div>
  );
};

import { ApiHelper, detoiApi } from "businessLogic/redux/adminApi";
import { serviceType } from "../servicesService/type";

const api = detoiApi.injectEndpoints({
    endpoints: (builder) => ({
        getDetailService: builder.query({
            query: (id: string) => ApiHelper.get(`/api/v1/service-type/detail?id=${id}`, {}),
        }),
        updateService: builder.mutation({
            query: (data: serviceType) => ApiHelper.put(`/api/v1/service-type`, { data }),
        }),
        createService: builder.mutation({
            query: (data: serviceType) => ApiHelper.post('/api/v1/service-type', { data }),
        }),
    }),
});

export const {
    useGetDetailServiceQuery,
    useCreateServiceMutation,
    useUpdateServiceMutation,
    endpoints: { getDetailService, createService, updateService }
} = api;
type categoriesSearchProps = {
  placeholder: string;
  onSearch: (value: string) => void;
};

export const CategoriesSearch = ({
  placeholder,
  onSearch,
}: categoriesSearchProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onSearch(value);
  };

  return (
    <div className="flex items-center w-full h-12 rounded-3xl bg-gray-extraLight">
      <input
        className="h-full w-full outline-none text-sm text-gray-700 pl-4 rounded-3xl bg-gray-extraLight"
        type="text"
        id="search"
        onChange={handleChange}
        placeholder={placeholder}
      />
      <div className="text-black flex justify-center items-center px-4">
        <i className="fi fi-br-search" />
      </div>
    </div>
  );
};

import { BUNNY_USER } from './apiKeys.constants';

const cdns = {
  sirv: `https://${process.env.EXPO_PUBLIC_SIRV_USER}.sirv.com/`,
  bunny: `https://${BUNNY_USER}.b-cdn.net/`,
};

/**
 * Config using CDN here
 */
const ACTIVE_CDN: keyof typeof cdns = 'bunny';

export const BASE_IMG_CDN = cdns[ACTIVE_CDN];

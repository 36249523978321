import { Flex, HStack, VStack, Text, Image } from "@chakra-ui/react";
import { baseCdnImage } from "helpers/baseCdnImage";
import React from "react";
import { IFreelancerAccountDetail } from "services/entities";

export const FreelancerCardIdSection = React.memo(
  ({ freelancerDetail }: { freelancerDetail: IFreelancerAccountDetail }) => {
    return (
      <VStack
        className="bg-silver border border-solid shadow-md"
        borderRadius={5}
        p={5}
        gap={5}
        alignItems={"flex-start"}
      >
        <HStack>
          <i className="fi fi-bs-picture" />
          <Text>Hình ảnh CMND (mặt trước/ mặt sau)</Text>
        </HStack>
        <Flex className="w-full" justifyContent={"space-between"} gap={3}>
          <Image
            className="w-[48%]"
            alt="Lỗi mặt trước CMND"
            src={baseCdnImage(freelancerDetail.identityCardImage)}
          />
          <Image
            className="w-[48%]"
            alt="Lỗi mặt sau CMND"
            src={baseCdnImage(freelancerDetail.identityCardImageBack)}
          />
        </Flex>
      </VStack>
    );
  }
);

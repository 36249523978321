import {
  initInputServiceRequirement,
  cloneServiceRequirement,
  initValidationMin,
  initValidationMax,
  initSpecialOptionInfoStepper,
  cloneSpecialItemSelectOptionInfo,
  initDropdownServiceRequirement,
  initAddToListServiceRequirement,
  initSpecialSelectServiceRequirement,
  initSpecialOptionInfoButtonGroup,
} from "helpers/index";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { contentAction } from "constants/servicesAddition";
import {
  IOption,
  IUIServiceRequirement,
  inputMethodTypeName,
} from "services/entities/index";

export type manageServiceRequirementsType = {
  serviceRequirements: IUIServiceRequirement[];
  onChangeType: (
    newServiceRequirement: inputMethodTypeName,
    idServiceRequirement: string
  ) => void;
  onDuplicate: (idServiceRequirement: string) => void;
  onRemove: (idServiceRequirement: string) => void;
  onChangeValue: (contentAction: contentAction) => void;
  onAdd: () => void;
  setServiceRequirement: (newSRs: IUIServiceRequirement[]) => void;
};

const ManageServicesRequirementsContext = createContext(
  {} as manageServiceRequirementsType
);

export const ManageServicesRequirementsProvider = ({
  children,
}: {
  children: any;
}) => {
  const [serviceRequirements, setServiceRequirement] = useState<
    IUIServiceRequirement[]
  >([initInputServiceRequirement()]);

  const onAdd = useCallback(() => {
    setServiceRequirement((prevState) => [
      ...prevState,
      initInputServiceRequirement(),
    ]);
  }, [setServiceRequirement]);

  const onChangeType = useCallback(
    (newType: inputMethodTypeName, idServiceRequirement: string) => {
      const newServiceRequirement: Record<
        inputMethodTypeName,
        IUIServiceRequirement
      > = {
        input: initInputServiceRequirement(),
        select: initDropdownServiceRequirement(),
        add_to_list: initAddToListServiceRequirement(),
        special_itemselect: initSpecialSelectServiceRequirement(),
      };
      setServiceRequirement((prevState) =>
        prevState.map((sr) =>
          sr.id === idServiceRequirement ? newServiceRequirement[newType] : sr
        )
      );
    },
    [setServiceRequirement]
  );

  const onRemove = useCallback(
    (idServiceRequirement: string) => {
      setServiceRequirement((prevState) =>
        prevState.filter((sr) => sr.id !== idServiceRequirement)
      );
    },
    [setServiceRequirement]
  );
  const onDuplicate = useCallback(
    (idServiceRequirement: string) => {
      const serviceRequirementSelected = serviceRequirements.find(
        (sr) => sr.id === idServiceRequirement
      );
      if (!serviceRequirementSelected) return;
      const serviceRequirementDuplicated = cloneServiceRequirement(
        serviceRequirementSelected
      );
      if (serviceRequirementDuplicated) {
        setServiceRequirement((prevState) => [
          ...prevState,
          serviceRequirementDuplicated,
        ]);
      }
    },
    [serviceRequirements, setServiceRequirement]
  );

  const onChangeValue = useCallback(
    (contentAction: contentAction) => {
      switch (contentAction.type) {
        case "input-update-label":
        case "addToList-update-label":
        case "dropdown-update-label":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr) => {
              if (contentAction.serviceRequirementId === sr.id) {
                return { ...sr, label: contentAction.newLabel };
              }
              return sr;
            })
          );
          break;
        case "input-update-validate":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr) => {
              if (contentAction.serviceRequirementId === sr.id) {
                return { ...sr, isValidate: contentAction.isValidate };
              }
              return sr;
            })
          );
          break;
        case "input-update-labelIcon":
        case "dropdown-update-labelIcon":
        case "addToList-update-labelIcon":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr) => {
              if (contentAction.serviceRequirementId === sr.id) {
                return { ...sr, labelIcon: contentAction.newLabelIcon };
              }
              return sr;
            })
          );
          break;
        case "input-update-required":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr) => {
              if (contentAction.serviceRequirementId === sr.id) {
                return { ...sr, isRequired: contentAction.isRequired };
              }
              return sr;
            })
          );
          break;
        case "addToList-update-required":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr) => {
              if (contentAction.serviceRequirementId === sr.id) {
                return { ...sr, isRequired: contentAction.isRequired };
              }
              return sr;
            })
          );
          break;
        case "input-update-placeholder":
        case "addToList-update-placeholder":
        case "dropdown-update-placeholder":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr) => {
              if (contentAction.serviceRequirementId === sr.id) {
                return { ...sr, placeholder: contentAction.newPlaceholder };
              }
              return sr;
            })
          );
          break;

        case "input-add-validation":
        case "addToList-add-validation":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr: IUIServiceRequirement) => {
              if (contentAction.serviceRequirementId === sr.id) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    validation: [
                      ...sr.inputMethod.validation,
                      sr.inputMethod.validation.length === 0 ||
                      sr.inputMethod.validation[0].name === "max"
                        ? initValidationMin
                        : initValidationMax,
                    ],
                  },
                };
              }
              return sr;
            })
          );
          break;

        case "input-update-validation-dataType":
        case "addToList-update-validation-dataType":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr: IUIServiceRequirement) => {
              if (contentAction.serviceRequirementId === sr.id) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    dataType: contentAction.newDataType,
                    validation: [initValidationMin],
                  },
                };
              }
              return sr;
            })
          );
          break;
        case "input-update-validation-message":
        case "addToList-update-validation-message":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr: IUIServiceRequirement) => {
              if (contentAction.serviceRequirementId === sr.id) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    validation: sr.inputMethod.validation.map((validation) => {
                      if (validation.name !== "required")
                        return {
                          ...validation,
                          message:
                            validation.id === contentAction.validationId
                              ? contentAction.newMessage
                              : validation.message,
                        };
                      return validation;
                    }),
                  },
                };
              }
              return sr;
            })
          );
          break;
        case "input-update-validation-value":
        case "addToList-update-validation-value":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr) => {
              if (contentAction.serviceRequirementId === sr.id) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    validation: sr.inputMethod.validation.map((validation) => {
                      if (validation.name === "required") return validation;
                      return {
                        ...validation,
                        value:
                          validation.id === contentAction.validationId
                            ? contentAction.newValue
                            : validation?.value,
                      };
                    }),
                  },
                };
              }
              return sr;
            })
          );
          break;
        case "input-remove-validation":
        case "addToList-remove-validation":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr) => {
              if (contentAction.serviceRequirementId === sr.id) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    validation: sr.inputMethod.validation.filter(
                      (validation) =>
                        validation.id !== contentAction.validationId
                    ),
                  },
                };
              }
              return sr;
            })
          );
          break;
        case "input-update-validation-name":
        case "addToList-update-validation-name":
          // setServiceRequirement((prevSRs) =>
          //   prevSRs.map((sr) => {
          //     if (contentAction.serviceRequirementId === sr.id) {
          //       return {
          //         ...sr,
          //         inputMethod: {
          //           ...sr.inputMethod,
          //           validation: sr.inputMethod.validation.map((validation) => {
          //             if (validation.id === contentAction.validationId) {
          //               return contentAction.newName === "max"
          //                 ? initValidationMax
          //                 : initValidationMin;
          //             }
          //             return validation;
          //           }),
          //         },
          //       };
          //     }
          //     return sr;
          //   })
          // );
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr) => {
              if (contentAction.serviceRequirementId === sr.id) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    validation: sr.inputMethod.validation.map((validation) => {
                      if (contentAction.validationId === validation.id) {
                        if (validation.name !== "required") {
                          return {
                            ...validation,
                            value: validation.value ?? 0,
                            name: contentAction.newName,
                          };
                        }
                      }
                      return validation;
                    }),
                  },
                };
              }
              return sr;
            })
          );

          break;
        case "dropdown-add-option":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "select"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: [
                        ...sr.inputMethod.method.options,
                        contentAction.newOption,
                      ],
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;
        case "dropdown-update-option-name":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "select"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: sr.inputMethod.method.options.map(
                        (option, optionIndex) =>
                          optionIndex === contentAction.optionIndex
                            ? {
                                ...option,
                                name: contentAction.newName,
                              }
                            : option
                      ),
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;
        case "dropdown-update-option-description":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "select"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: sr.inputMethod.method.options.map(
                        (option, optionIndex) =>
                          optionIndex === contentAction.optionIndex
                            ? {
                                ...option,
                                description: contentAction.newDescription,
                              }
                            : option
                      ),
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;
        case "dropdown-swap-option":
          const swapOptions = (options: IOption[]): IOption[] => {
            const optionsClone = [...options];
            const temp = optionsClone[contentAction.destinationIndex];
            optionsClone[contentAction.destinationIndex] =
              optionsClone[contentAction.sourceIndex];
            optionsClone[contentAction.sourceIndex] = temp;
            return optionsClone;
          };
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "select"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: swapOptions(sr.inputMethod.method.options),
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;
        case "special-update-option-name":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "special_itemselect"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: sr.inputMethod.method.options.map(
                        (option, optionIndex) => ({
                          ...option,
                          name:
                            optionIndex === contentAction.optionIndex
                              ? contentAction.newName
                              : option.name,
                        })
                      ),
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;
        // case "special-update-option":
        //   setServiceRequirement((prevSRs) =>
        //     prevSRs.map((sr: any) => {
        //       if (contentAction.serviceRequirementId === sr.id) {
        //         return {
        //           ...sr,
        //           inputMethod: [
        //             ...(sr as specialSelectServiceRequirement).inputMethod,
        //             contentAction.newSpecialOption,
        //           ],
        //         };
        //       }
        //       return sr;
        //     })
        //   );
        //   break;
        // case "special-remove-specialOption":
        //   setServiceRequirement((prevSRs) =>
        //     prevSRs.map((sr: any) => {
        //       if (contentAction.serviceRequirementId === sr.id) {
        //         return {
        //           ...sr,
        //           inputMethod: (
        //             sr as specialSelectServiceRequirement
        //           ).inputMethod.filter(
        //             (specialOption) =>
        //               specialOption.id !== contentAction.specialOptionId
        //           ),
        //         };
        //       }
        //       return sr;
        //     })
        //   );
        //   break;
        case "special-add-option":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr: IUIServiceRequirement) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "special_itemselect"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: [
                        ...sr.inputMethod.method.options,
                        contentAction.newOption,
                      ],
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;
        case "special-remove-option":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr: IUIServiceRequirement) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "special_itemselect"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: sr.inputMethod.method.options.filter(
                        (_, optionIndex) =>
                          optionIndex !== contentAction.optionIndex
                      ),
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;

        case "special-update-option-info-type":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr: IUIServiceRequirement) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "special_itemselect"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: sr.inputMethod.method.options.map(
                        (option, optionIndex) => {
                          if (optionIndex !== contentAction.optionIndex)
                            return option;

                          return {
                            ...option,
                            info: option.info.map((info, infoIndex) =>
                              infoIndex === contentAction.infoIndex
                                ? contentAction.newType === "stepper"
                                  ? initSpecialOptionInfoStepper
                                  : initSpecialOptionInfoButtonGroup
                                : info
                            ),
                          };
                        }
                      ),
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;
        case "special-update-option-info-remove":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr: IUIServiceRequirement) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "special_itemselect"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: sr.inputMethod.method.options.map(
                        (option, optionIndex) => {
                          if (optionIndex !== contentAction.optionIndex)
                            return option;

                          return {
                            ...option,
                            info: option.info.filter(
                              (_, infoIndex) =>
                                infoIndex !== contentAction.infoIndex
                            ),
                          };
                        }
                      ),
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;
        case "special-update-option-info-duplicate":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr: IUIServiceRequirement) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "special_itemselect"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: sr.inputMethod.method.options.map(
                        (option, optionIndex) => {
                          if (optionIndex !== contentAction.optionIndex)
                            return option;

                          return {
                            ...option,
                            info: [
                              ...option.info,
                              cloneSpecialItemSelectOptionInfo(
                                option.info[contentAction.infoIndex]
                              ),
                            ],
                          };
                        }
                      ),
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;

        case "special-add-option-info":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr: IUIServiceRequirement) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "special_itemselect"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: sr.inputMethod.method.options.map(
                        (option, optionIndex) => {
                          if (optionIndex !== contentAction.optionIndex)
                            return option;

                          return {
                            ...option,
                            info: [
                              ...option.info,
                              initSpecialOptionInfoStepper
                            ],
                          };
                        }
                      ),
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;
        case "special-update-stepper-label":
        case "special-update-buttonGroup-label":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr: IUIServiceRequirement) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "special_itemselect"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: sr.inputMethod.method.options.map(
                        (option, optionIndex) => {
                          if (optionIndex !== contentAction.optionIndex)
                            return option;
                          return {
                            ...option,
                            info: option.info.map((info, infoIndex) =>
                              infoIndex === contentAction.infoIndex
                                ? { ...info, label: contentAction.newLabel }
                                : info
                            ),
                          };
                        }
                      ),
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;

        case "special-update-stepper-mask":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr: IUIServiceRequirement) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "special_itemselect"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: sr.inputMethod.method.options.map(
                        (option, optionIndex) => {
                          if (optionIndex !== contentAction.optionIndex)
                            return option;
                          return {
                            ...option,
                            info: option.info.map((info, infoIndex) =>
                              infoIndex === contentAction.infoIndex
                                ? { ...info, mask: contentAction.newMask }
                                : info
                            ),
                          };
                        }
                      ),
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;
        case "special-update-stepper-maxValue":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr: IUIServiceRequirement) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "special_itemselect"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: sr.inputMethod.method.options.map(
                        (option, optionIndex) => {
                          if (optionIndex !== contentAction.optionIndex)
                            return option;
                          return {
                            ...option,
                            info: option.info.map((info, infoIndex) =>
                              infoIndex === contentAction.infoIndex
                                ? {
                                    ...info,
                                    validation: info.validation.map(
                                      (validate) =>
                                        validate.name === "max"
                                          ? {
                                              ...validate,
                                              value: contentAction.newMaxValue,
                                            }
                                          : validate
                                    ),
                                  }
                                : info
                            ),
                          };
                        }
                      ),
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;
        case "special-update-stepper-minValue":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr: IUIServiceRequirement) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "special_itemselect"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: sr.inputMethod.method.options.map(
                        (option, optionIndex) => {
                          if (optionIndex !== contentAction.optionIndex)
                            return option;
                          return {
                            ...option,
                            info: option.info.map((info, infoIndex) =>
                              infoIndex === contentAction.infoIndex
                                ? {
                                    ...info,
                                    validation: info.validation.map(
                                      (validate) =>
                                        validate.name === "min"
                                          ? {
                                              ...validate,
                                              value: contentAction.newMinValue,
                                            }
                                          : validate
                                    ),
                                  }
                                : info
                            ),
                          };
                        }
                      ),
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;
        case "special-update-stepper-defaultValue":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr: IUIServiceRequirement) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "special_itemselect"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: sr.inputMethod.method.options.map(
                        (option, optionIndex) => {
                          if (optionIndex !== contentAction.optionIndex)
                            return option;
                          return {
                            ...option,
                            info: option.info.map((info, infoIndex) =>
                              infoIndex === contentAction.infoIndex
                                ? {
                                    ...info,
                                    defaultValue: contentAction.newDefaultValue,
                                  }
                                : info
                            ),
                          };
                        }
                      ),
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;

        case "special-update-buttonGroup-button":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr: IUIServiceRequirement) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "special_itemselect"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: sr.inputMethod.method.options.map(
                        (option, optionIndex) => {
                          if (optionIndex !== contentAction.optionIndex)
                            return option;
                          return {
                            ...option,
                            info: option.info.map((info, infoIndex) => {
                              if (
                                info.type === "button_group" &&
                                infoIndex === contentAction.infoIndex
                              )
                                return {
                                  ...info,
                                  buttons: info.buttons.map(
                                    (button, buttonIndex) =>
                                      buttonIndex === contentAction.buttonIndex
                                        ? contentAction.newButtonContent
                                        : button
                                  ),
                                };
                              return info;
                            }),
                          };
                        }
                      ),
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;
        case "special-add-buttonGroup-button":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr: IUIServiceRequirement) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "special_itemselect"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: sr.inputMethod.method.options.map(
                        (option, optionIndex) => {
                          if (optionIndex !== contentAction.optionIndex)
                            return option;
                          return {
                            ...option,
                            info: option.info.map((info, infoIndex) => {
                              if (
                                info.type === "button_group" &&
                                infoIndex === contentAction.infoIndex
                              )
                                return {
                                  ...info,
                                  buttons: [...info.buttons, ""],
                                };
                              return info;
                            }),
                          };
                        }
                      ),
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;
        case "special-swap-buttonGroup-button":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr: IUIServiceRequirement) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "special_itemselect"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: sr.inputMethod.method.options.map(
                        (option, optionIndex) => {
                          if (optionIndex !== contentAction.optionIndex)
                            return option;
                          return {
                            ...option,
                            info: option.info.map((info, infoIndex) => {
                              if (
                                info.type === "button_group" &&
                                infoIndex === contentAction.infoIndex
                              ) {
                                // Perform the button swap
                                const clonedButtons = [...info.buttons];
                                const temp =
                                  clonedButtons[contentAction.sourceIndex];
                                clonedButtons[contentAction.sourceIndex] =
                                  clonedButtons[contentAction.destinationIndex];
                                clonedButtons[contentAction.destinationIndex] =
                                  temp;
                                return {
                                  ...info,
                                  buttons: clonedButtons,
                                };
                              }
                              return info;
                            }),
                          };
                        }
                      ),
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;

        case "special-remove-buttonGroup-button":
          setServiceRequirement((prevSRs) =>
            prevSRs.map((sr: IUIServiceRequirement) => {
              if (
                contentAction.serviceRequirementId === sr.id &&
                sr.inputMethod.method.name === "special_itemselect"
              ) {
                return {
                  ...sr,
                  inputMethod: {
                    ...sr.inputMethod,
                    method: {
                      ...sr.inputMethod.method,
                      options: sr.inputMethod.method.options.map(
                        (option, optionIndex) => {
                          if (optionIndex !== contentAction.optionIndex)
                            return option;
                          return {
                            ...option,
                            info: option.info.map((info, infoIndex) => {
                              if (
                                info.type === "button_group" &&
                                infoIndex === contentAction.infoIndex
                              )
                                return {
                                  ...info,
                                  buttons: info.buttons.filter(
                                    (_, buttonIndex) =>
                                      buttonIndex !== contentAction.buttonIndex
                                  ),
                                };
                              return info;
                            }),
                          };
                        }
                      ),
                    },
                  },
                };
              }
              return sr;
            })
          );
          break;
      }
    },
    [serviceRequirements, setServiceRequirement]
  );

  const value = useMemo(
    () => ({
      serviceRequirements,
      onAdd,
      onChangeType,
      onRemove,
      onDuplicate,
      onChangeValue,
      setServiceRequirement,
    }),
    [
      serviceRequirements,
      onAdd,
      onChangeType,
      onRemove,
      onDuplicate,
      onChangeValue,
      setServiceRequirement,
    ]
  );
  return (
    <ManageServicesRequirementsContext.Provider value={value}>
      {children}
    </ManageServicesRequirementsContext.Provider>
  );
};

export const useManageServiceRequirements = () =>
  useContext(ManageServicesRequirementsContext);

type props = {
	size?: "xs" | "sm" | "base" | "lg" | "xl";
	placeholder?: string;
	value?: string;
	autoFocus?: boolean;
	onChange?: (value: string) => void;
};
export const BorderBottomInput = ({
	size = "lg",
	placeholder = "",
	value,
	autoFocus = false,
	onChange,
}: props) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (onChange) {
			onChange(event.target.value);
		}
	};

	return (
		<input
			value={value}
			placeholder={placeholder}
			autoFocus={autoFocus}
			onChange={handleChange}
			className={`text-${size} peer h-full w-full border-b border-black bg-transparent pt-4 font-normal outline outline-0 transition-all`}
		/>
	);
};

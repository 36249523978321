import { PAGES } from "constants/pages";
import { Paragraph, Tittle } from "presentational/atoms";
import { useNavigate } from "react-router-dom";
import { FieldErrors, SubmitHandler, useForm } from "react-hook-form";
import { loginSchema, useYupValidationResolver } from "validators/yup";
import { loginType } from "services/lib/login/type";
import { toast, ToastContainer } from "react-toastify";
import { useSession } from "businessLogic/hook";
import { useToast } from "@chakra-ui/react";
import { useState } from "react";

export const Login = () => {
  const navigate = useNavigate();
  const notifyError = (errors: FieldErrors<loginType>) => {
    const errorMessage = Object.values(errors)
      .map((error) => error.message)
      .join(", ");
    toast.error(
      errorMessage.length !== 0 ? errorMessage : "Email và password là bắt buộc"
    );
  };

  const formResolver = useYupValidationResolver(loginSchema);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<loginType>({
    resolver: formResolver,
  });
  const { signIn } = useSession();
  const toastCharka = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const onSubmitSuccess: SubmitHandler<loginType> = async (data) => {
    setIsLoading(true)
    const signInStatus = await signIn(
      {
        email: data.email,
        password: data.password,
      },
      () => navigate(`/`, { replace: true })
    );
    setIsLoading(false)
    if (signInStatus?.success) {
      toastCharka({
        title: "Đăng nhập thành công",
        status: "success",
        isClosable: true,
        position: "top"
      })
    } else {
      toastCharka({
        title: "Đăng nhập thất bại",
        status: "error",
        isClosable: true,
        position: "top"
      })
    }
  };

  const onSubmitFail = () => {
    if (errors) {
      notifyError(errors);
    }
  };

  return (
    <form
      className="container overflow-hidden relative h-screen"
      onSubmit={handleSubmit(onSubmitSuccess, onSubmitFail)}
    >
      <ToastContainer />
      <div className="overflow-hidden absolute left-[-18%] top-[-15%]">
        <img src="/assets/login.jpg" alt="login" className="rounded-full " />
      </div>
      <div className="h-screen flex flex-col justify-center absolute left-[50%] items-center w-[60%]">
        <Tittle color="black">ĐĂNG NHẬP</Tittle>
        <div className="relative w-[50%] mt-10">
          <input
            {...register("email")}
            type="text"
            placeholder="Email"
            className="border-2 border-red-lightShade focus:outline-none focus:border-blueShade-cyan  bg-blueShade-lightShade rounded-md w-full p-2 pl-3 "
          />
          <div className="absolute right-3 top-[50%] translate-y-[-50%]">
            <i className="fi fi-sr-user text-lg"></i>
          </div>
        </div>
        <div className="relative w-[50%] mt-5">
          <input
            {...register("password")}
            type="password"
            placeholder="Mật khẩu"
            className="border-2 border-red-lightShade focus:outline-none focus:border-blueShade-cyan  bg-blueShade-lightShade rounded-md w-full p-2 pl-3"
          />
          <div className="absolute right-2 top-[50%] translate-y-[-50%] bg-white  pl-3 pr-1">
            <i className="fi fi-sr-eye text-lg"></i>
          </div>
        </div>
        <div className="w-[50%] mt-2">
          <p className="text-blueShade-mediumLightShade text-right text-sm font-normal leading-4">
            Quên mật khẩu?
          </p>
        </div>
        <button
          className="mt-4 p-3 rounded-md w-[50%] bg-blueShade-shade"
          type="submit"
          disabled={isLoading}
        >
          <Paragraph color="white" align="center" fontWeight="bold">
            {!isLoading ? "Đăng nhập" : "Đang kiểm tra thông tin..."}
          </Paragraph>
        </button>
      </div>
    </form>
  );
};

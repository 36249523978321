import { ChangeEvent, useMemo, useState, useRef, useEffect } from "react";
import TableRow from "presentational/atoms/table/TableRow";
import { LicenseModal, Pagination } from "presentational/molecules";
import { Box, Center, useDisclosure, Text, Flex, Tooltip } from "@chakra-ui/react";
import { IRequestCompany, IRequestFreelancer } from "services/entities";
import { useGetListRequestFreelancerQuery, useGetListRequestCompanyQuery } from "../business-layer/services/lib/apis";
import { ErrorItem, LoadingItem } from "presentational/organisms";
import { ConfirmVerifyModal } from "presentational/molecules/modal/ConfirmVerifyModal";
import { verifyFreelancerType } from "services/lib/verify/type";
import { baseCdnImage } from "helpers/baseCdnImage";
import DropDown from "presentational/atoms/dropdown/DropDown";
import { pagingType } from "services/lib/general/type";

const MAX_ELEMENT_PER_PAGE = 4;
type typeUserRequest = 'freelancer' | 'company'
export const UsersLicenses = () => {
  const [currentIndexPage, setCurrentIndexPage] = useState<number>(0);
  const [dataPaging, setDataPaging] = useState<Record<typeUserRequest | 'main', pagingType>>();
  const [searchValue, setSearchValue] = useState("");
  const [freelancerList, setFreelancerList] = useState<
    IRequestFreelancer[]
  >([]);
  const [typeUser, setTypeUser] = useState<typeUserRequest>('freelancer')

  const [companyList, setCompanyList] = useState<
    IRequestCompany[]
  >([]);
  const {
    data: dataListFreelancer,
    isFetching: isFetchingFreelancer,
    isError: isErrorFreelancer,
    refetch
  } = useGetListRequestFreelancerQuery({});

  const {
    data: dataListCompany,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    refetch: refetchCompany
  } = useGetListRequestCompanyQuery({})
  useEffect(() => {
    if (!isErrorFreelancer && dataListFreelancer) {
      setFreelancerList(dataListFreelancer.data);
    }
  }, [isErrorFreelancer, dataListFreelancer]);


  useEffect(() => {
    if (!isErrorCompany && dataListCompany) {
      setCompanyList(dataListCompany.data)
    }
  }, [isErrorCompany, dataListCompany]);


  //CHECK BOX
  const [openOptionsIndex, setOpenOptionsIndex] = useState<number | null>(null);

  const [checkedState, setCheckedState] = useState(
    new Array(freelancerList.length).fill(false)
  );

  const checkedCount = checkedState.filter((value) => value).length;

  const visibleLicenseList = useMemo(() => {
    const visibleList = typeUser === 'freelancer' ? freelancerList : companyList
    return visibleList
    // .filter((freelancer) =>
    //   freelancer.identityNumber
    //     .toLocaleLowerCase()
    //     .includes(searchValue.toLocaleLowerCase())
    // )
  }, [freelancerList, companyList, searchValue, typeUser]);

  //Handle Options
  const handleOptionClick = (index: number) => {
    setOpenOptionsIndex((prevState) => (prevState === index ? null : index));
  };



  //Handle Select All
  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedCheckedState = new Array(freelancerList.length).fill(
      event.target.checked
    );
    setCheckedState(updatedCheckedState);
  };

  const [selectedAccountViewDetail, setSelectedAccountViewDetail] =
    useState<IRequestFreelancer>();
  const {
    isOpen: isOpenViewDetail,
    onOpen: onOpenViewDetail,
    onClose: onCloseViewDetail,
  } = useDisclosure();

  const {
    isOpen: isOpenConfirmVerify,
    onOpen: onOpenConfirmVerify,
    onClose: onCloseConfirmVerify,
  } = useDisclosure();

  const [verifyParam, setVerifyParam] = useState<verifyFreelancerType>({
    freelancerId: "",
    verified: true,
  });

  const removeFreelancerToList = () => {
    refetch()
  }

  if (isFetchingFreelancer || isFetchingCompany) {
    return <LoadingItem />;
  }

  if (isErrorFreelancer || isErrorCompany) {
    return <ErrorItem />;
  }

  return (
    <main className="w-full p-10">
      <ConfirmVerifyModal
        isOpen={isOpenConfirmVerify}
        onClose={onCloseConfirmVerify}
        verifyParam={verifyParam}
        callback={removeFreelancerToList}
      />
      <section className="py-3 px-4 shadow-md border-silver border-2   flex justify-between items-center bg-white rounded-md ">
        <Flex className="flex-1 justify-between">
          <div className="w-3/5 relative">
            <input
              name="searchBox"
              type="text"
              className="pl-6 pr-12 py-2 w-full text-s bg-silver rounded-full placeholder-gray outline-none"
              placeholder="Tìm kiếm theo CMND / CCCD..."
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <i className="fi fi-br-search absolute top-1/2 transform -translate-y-1/2 right-4 text-gray-medium" />
          </div>
          <div className="flex">
            <DropDown
              listItem={['Nhân viên', 'Doanh nghiệp']}
              itemSelect={typeUser === 'freelancer' ? 'Nhân viên' : 'Doanh nghiệp'}
              onChange={(typeSelected) => {
                const typeSelectedSerialized = typeSelected === 'Nhân viên' ? 'freelancer' : 'company' as typeUserRequest
                setTypeUser(typeSelectedSerialized as typeUserRequest);
                setDataPaging(prev => {
                  if (prev) {
                    return {
                      ...prev,
                      main: typeSelectedSerialized === 'freelancer' ? prev.freelancer : prev.company
                    };
                  }
                  return prev;
                });
              }}
            />
          </div>
        </Flex>
        <div className="flex">
          {checkedCount > 1 ? <button onClick={() => { }}>●●●</button> : null}
        </div>
      </section>
      {selectedAccountViewDetail ? (
        <LicenseModal
          isOpenViewDetail={isOpenViewDetail}
          onCloseViewDetail={onCloseViewDetail}
          account={selectedAccountViewDetail}
        />
      ) : (
        <></>
      )}
      <section className="shadow-sm  overflow-x-auto overflow-y-hidden border border-silver mt-8 rounded-md">
        <table className="w-full text-xs text-center bg-white rounded-md">
          <thead className="text-gray font-medium">
            <tr>
              <th scope="col" className="px-2 py-3 w-[10%] text-sm">
                Ảnh đại diện
              </th>
              <th scope="col" className="px-2 py-3 w-[20%] text-sm">
                Thông tin chung
              </th>
              <th scope="col" className="px-2 py-3 w-[25%] text-sm">
                Thông tin liên hệ
              </th>
              <th scope="col" className="px-2 py-3 w-[15%] text-sm">
                CMND/CCCD
              </th>
              <th scope="col" className="px-2 py-3 w-[20%] text-sm">
                Hình ảnh CMND/CCCD
              </th>
              <th scope="col" className="px-2 py-3 w-[5%]"></th>
            </tr>
          </thead>

          <tbody>
            <FreelancerLicenseTable
              handleOptionClick={handleOptionClick}
              onOpenConfirmVerify={onOpenConfirmVerify}
              onOpenViewDetail={onOpenViewDetail}
              openOptionsIndex={openOptionsIndex}
              setSelectedAccountViewDetail={setSelectedAccountViewDetail}
              setVerifyParam={setVerifyParam}
              visibleFreelancerList={visibleLicenseList}
            />
          </tbody>
        </table>
        <Pagination
          indexCurrentPage={dataPaging?.main.currentPage ?? 1}
          onChangePage={(newPageNum) => {
            if (dataPaging) {
              setDataPaging(prev => {
                if (prev) {
                  return {
                    ...prev,
                    main: {
                      ...prev.main,
                      currentPage: newPageNum
                    }
                  };
                }
                return prev;
              });
            }
          }}
          numberPage={dataPaging?.main.totalPages ?? 1}
        />
      </section>
    </main>
  );
};

const FreelancerLicenseTable = ({
  visibleFreelancerList,
  handleOptionClick,
  setVerifyParam,
  openOptionsIndex,
  setSelectedAccountViewDetail,
  onOpenViewDetail,
  onOpenConfirmVerify
}: {
  visibleFreelancerList: IRequestFreelancer[] | IRequestCompany[],
  handleOptionClick: (index: number) => void,
  setVerifyParam: (v: verifyFreelancerType) => void,
  openOptionsIndex: number | null,
  setSelectedAccountViewDetail: (v: IRequestFreelancer) => void
  onOpenViewDetail: () => void
  onOpenConfirmVerify: () => void
}) => {
  const optionsRef = useRef<{ [key: number]: HTMLSpanElement | null }>({});
  return (
    <>
      {Array.isArray(visibleFreelancerList)
        ? visibleFreelancerList.map((account, index) => (
          <tr
            className="py-4 odd:border-y odd:border-gray-light"
            key={`account@${account.id}`}
          >
            <TableRow
              listData={[

                <>
                  <span className="flex items-center justify-center">
                    <img
                      src={baseCdnImage(account.account.avatar)}
                      alt="Lỗi ảnh đại diện"
                      className=" rounded-full h-16 w-16 cursor-pointer object-cover"
                    />
                  </span>
                </>,
                <>
                  <span className="font-semibold">
                    <Tooltip label={account?.companyName ? account.companyName : account.account.fullName} bg='red.600'>
                      <span className="line-clamp-1 font-medium text-sm ">
                        <b>{account?.companyName ? "Tên doanh nghiệp" : "Tên:"}</b>{" "}
                        <span className="font-normal">
                          {account?.companyName ? account.companyName : account.account.fullName}
                        </span>

                      </span>
                    </Tooltip>

                    <span className="line-clamp-1 font-medium text-sm ">
                      <b>Ngày sinh:</b>{" "}
                      <span className="font-normal">
                        {account.account.dateOfBirth
                          ? account.account.dateOfBirth
                          : "Chưa cập nhật"}
                      </span>
                    </span>
                    <span className="line-clamp-1 font-medium text-sm ">
                      <b>Giới tính:</b>{" "}
                      <span className="font-normal">
                        {
                          // account.account.gender === "Male"
                          //   ? "Nam":
                          "Nữ"
                        }
                      </span>
                    </span>
                  </span>
                </>,
                <>
                  <span className="font-semibold">
                    <span className="line-clamp-1 font-medium text-sm ">
                      <b>Số điện thoại:</b>{" "}
                      <span className="font-normal">
                        {account.account.phone}
                      </span>
                    </span>
                    <span className="line-clamp-2 ml-1 font-medium text-sm ">
                      <b>Địa chỉ:</b> {account.address.addressLine},{" "}
                      {account.address.district}, {account.address.ward}
                      , {account.address.province}
                    </span>
                  </span>
                </>,
                <>
                  <span className="font-semibold text-base">
                    {account.identityNumber}
                  </span>
                </>,
                <>
                  <span className="flex">
                    <img
                      src={baseCdnImage(account.identityCardImage)}
                      alt="Lỗi CMND"
                      className=" w-1/2 h-15 cursor-pointer object-cover"
                    />
                    <img
                      src={baseCdnImage(account.identityCardImageBack)}
                      alt="Lỗi CMND"
                      className="w-1/2 h-15 ml-1 cursor-pointer object-cover"
                    />
                  </span>
                </>,
                <>
                  <span
                    className="relative"
                    ref={(el) => (optionsRef.current[index] = el)}
                  >
                    <button onClick={() => handleOptionClick(index)}>
                      • • •
                    </button>

                    {/* HANDLE OPTIONS BOX */}
                    {openOptionsIndex === index && (
                      <span className="absolute top-0 right-0 w-48 mt-4 bg-white z-10 border border-silver rounded-2xl rounded-tr-none shadow-lg py-3 px-4">
                        <span
                          className="font-bold mb-3 cursor-pointer hover:underline  line-clamp-1 flex items-center"
                          onClick={() =>
                          //TODO: HANDLE WATCH DETAIL
                          { }
                          }
                        >
                          <span className="ml-2 text-base">
                            <Box
                              onClick={() => {
                                setSelectedAccountViewDetail(account);
                                onOpenViewDetail();
                              }}
                            >
                              <i className="fi fi-sr-eye w-4 h-4" />
                              Xem chi tiết
                            </Box>
                          </span>
                        </span>
                        <span
                          className="font-bold mb-3 text-green cursor-pointer hover:underline  line-clamp-1 flex items-center  text-green-medium"
                          onClick={() => {
                            setVerifyParam({
                              freelancerId: account.id,
                              verified: true,
                            });
                            onOpenConfirmVerify();
                          }}
                        >
                          <span className="ml-2 text-base">
                            <i className="fi fi-sr-check-circle w-4 h-4 " />{" "}
                            Cấp phép
                          </span>
                        </span>
                        <span
                          className="font-bold text-rose cursor-pointer hover:underline  line-clamp-1 flex items-center"
                          onClick={() => {
                            setVerifyParam({
                              freelancerId: account.id,
                              verified: false,
                            });
                            onOpenConfirmVerify();
                          }}
                        >
                          <span className="ml-2 text-base">
                            <i className="fi fi-sr-cross-circle w-4 h-4" />{" "}
                            Từ chối
                          </span>
                        </span>
                      </span>
                    )}
                  </span>
                </>,
              ]}
            />
          </tr>
        ))
        : null}
    </>
  )
}

export default UsersLicenses;

import { Box, Flex, Text } from "@chakra-ui/react";
import { Subtitle, Tittle } from "presentational/atoms";
import { useEffect, useState } from "react";
import { complaintPropsType } from "services/lib/feedbackService/type";
import { useNavigate, useParams } from "react-router-dom";
import { mockOrderDetails } from "./ServiceOrder";
import { ComplaintDetailTable } from "presentational/molecules/table/ComplaintDetailTable";
import Masonry from "react-responsive-masonry";
import { PAGES } from "constants/pages";
import { useGetComplainsQuery } from "../business-layer/services/lib/apis";
import { LoadingItem, ErrorItem } from "presentational/organisms";
import { UpdateStatusComplaintModal } from "presentational/molecules/modal/UpdateStatusComplaiintModal";
import { baseCdnImage } from "helpers/baseCdnImage";
import { formatCurrencyVND } from "helpers/MoneyConverter";
import React from "react";

const ComplaintDetail = () => {
  const { id } = useParams();
  const [complainDetail, setComplainDetail] = useState<complaintPropsType>();
  const { data, isError, isFetching, refetch } = useGetComplainsQuery({});
  const navigate = useNavigate();

  useEffect(() => {
    if (!data || isError) return;
    setComplainDetail(
      data.data.map((d: any) => ({ ...d, status: 'need-action' })).find((complain: complaintPropsType) => complain.id === id)
    );
    const orderDetail = mockOrderDetails.find((order) => order.id === id);
  }, [id, data, isFetching]);

  const goToOrderDetail = (orderId: string) => {
    navigate(`${PAGES.SERVICE_ORDER}/${orderId}`);
  };


  // const currOrderStatus = SERVICES_STATUS.find(
  //   (status) => status.Id === orderDetail.serviceStatus
  // );

  if (isFetching || !complainDetail) {
    return <LoadingItem />;
  }

  if (isError) {
    return <ErrorItem />;
  }
  // Tan -hack
  const serializeNamePattern = (v: string) => {
    return v.replaceAll("Customer", "Khách hàng").replaceAll("Freelancer", !complainDetail.complainant?.isCompany ? "Nhân viên" : "Doanh nghiệp")
  }

  return (
    <div className="bg-silver p-5 pb-10">
      <Tittle align="left" color="rose">
        {complainDetail.order.serviceTypes[0].name + " - " + complainDetail.respondent.fullName}
      </Tittle>
      <Box my={10} />
      <Box className="px-10 bg-white pt-4 pb-12 rounded-2xl border border-red-lightShade">
        <Flex justifyContent={"space-between"}>
          <Subtitle>Đối tượng khiếu nại</Subtitle>

          <UpdateStatusComplaintModal
            complainId={complainDetail.id}
            currentComplaintStatus={complainDetail?.complainStatus}
            refetch={refetch}
            freelancerId={complainDetail.respondentId}
          />
        </Flex>
        <Box className="my-6" />
        <ComplaintDetailTable
          title={[
            "Người khiếu nại",
            "Vai trò người khiếu nại",
            "Người bị khiếu nại",
            "Vai trò người bị khiếu nại",
            "Thời gian khiếu nại",
          ]}
          content={[
            complainDetail.complainant.fullName,
            serializeNamePattern(complainDetail.complainant.role),
            complainDetail.respondent.fullName,
            serializeNamePattern(complainDetail.respondent.role),
            new Date(complainDetail.createdAt).toLocaleDateString() + " - " + new Date(complainDetail.createdAt).toLocaleTimeString(),
          ]}
        />
      </Box>
      <Box my={10} />
      <Box className="px-10 bg-white pt-4 pb-12 rounded-2xl border border-red-lightShade">
        <Flex justifyContent={"space-between"}>
          <Subtitle>Chi tiết đơn dịch vụ</Subtitle>
          <Flex gap={3} alignItems={"center"}>
            {/* <Box
              bg={currOrderStatus?.backgroundColor}
              color={currOrderStatus?.textColor}
              fontWeight="medium"
              borderRadius="full"
              textAlign="center"
              py={1}
              px={3}
              className="text-xs"
            >
              {currOrderStatus?.Name ?? ""}
            </Box>
            <Box
              cursor={"pointer"}
              onClick={() => goToOrderDetail(orderDetail.id)}
            >
              <i className="fi fi-ss-info" />
            </Box> */}
          </Flex>
        </Flex>
        <Box className="my-6" />
        <div onClick={() => navigate(`${PAGES.SERVICE_ORDER}/${complainDetail.order.id}`)}>
          <ComplaintDetailTable
            title={[
              "Thời gian đặt đơn",
              "Dịch vụ",
              "Giá",
              "Thời gian bắt đầu",
            ]}
            content={[
              new Date(complainDetail.order.createdAt).toLocaleDateString() + " - " + new Date(complainDetail.order.createdAt).toLocaleTimeString(),
              complainDetail.order.serviceTypes[0].name,
              formatCurrencyVND(complainDetail.order.estimatedPrice),
              complainDetail.order.startDate + " - " + complainDetail.order.startTime,
            ]}
          />
        </div>
      </Box>
      <Box my={10} />
      <Box className="px-10 bg-white pt-4 pb-12 rounded-2xl border border-red-lightShade">
        <Subtitle>Chi tiết khiếu nại</Subtitle>
        <Box className="my-6" />
        <Text>{complainDetail.complainContent}</Text>
      </Box>
      <Box my={10} />
      <Box className="px-10 bg-white pt-4 pb-12 rounded-2xl border border-red-lightShade">
        <Subtitle>Hình ảnh minh chứng</Subtitle>
        <Box className="my-6" />
        <Masonry columnsCount={3} gutter="10px">
          {complainDetail.images.map((image) => (
            <img src={baseCdnImage(image)} alt="" />
          ))}
        </Masonry>
      </Box>
    </div>
  );
};

export default ComplaintDetail;

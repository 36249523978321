import React, { ReactNode, useEffect } from "react";
import Select from "react-select";

type Props = {
  options: {
    name: string;
    Component: ReactNode;
  }[];
  value?: string;
  onChange: (name: string) => void;
};

export const IconSelect: React.FC<Props> = ({ options, value, onChange }) => {
  const handleSelectChange = (itemSelected: any) => {
    const selectedOption = options.find(
      (option) => option.name === itemSelected.value
    );

    if (selectedOption) {
      onChange(selectedOption.name);
    }
  };
  useEffect(() => {
    if (value === undefined) onChange(options[0].name);
  }, []);

  const formattedOptions = options.map((option) => ({
    value: option.name,
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        {option.Component}
      </div>
    ),
  }));

  const selectedValue = formattedOptions.find((opt) => opt.value === value);
  const customStyles = {
    control: (base: any) => ({
      ...base,
      width: '100px',
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none',
    }),
  };
  return (
    <div className="w-full">
      <Select
        options={formattedOptions}
        value={selectedValue}
        onChange={handleSelectChange}
        styles={customStyles}
      />
    </div>
  );
};

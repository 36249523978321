import { baseCdnImage } from "helpers/baseCdnImage";
import { Avatar } from "presentational/atoms";
import { userChat } from "services/entities/chat";

type chartHeaderProps = {
  user?: Omit<userChat, 'type'>;
};
const ChatHeader = ({ user }: chartHeaderProps) => {
  if(!user) return null;
  return (
    <div className="bg-white w-full flex border-b border-b-red-lightShade py-2 px-4 justify-between items-center mt-1 shadow-md">
      <div className="flex gap-3 items-center">
        <Avatar link={baseCdnImage(user.avatar)} size="base"/>

        <p className="text-base">{user.fullName}</p>
      </div>
    </div>
  );
};

export default ChatHeader;

import { ApiHelper, detoiApi } from "businessLogic/redux/adminApi";

type resolveComplaintProps = {
    complainId: string
    resolvingDescription: string,
    resolvingAction: string
}

const api = detoiApi.injectEndpoints({
    endpoints: (builder) => ({
        getComplains: builder.query({
            query: ({ status, page, pageSize, name}: { status?: string, page?: number, pageSize?: number, name?:string }) => ApiHelper.get(`/api/v1/feedback/complains`, {
                params: {
                    status,
                    page,
                    pageSize,
                    name
                }
            }),
        }),
        resolveComplaint: builder.mutation({
            query: (data: resolveComplaintProps) => ApiHelper.post('/api/v1/feedback/complain/resolve', { data }),
        }),
    }),
});

export const {
    useGetComplainsQuery,
    useResolveComplaintMutation,
    endpoints: { getComplains, resolveComplaint }
} = api;
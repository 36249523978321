import { ReactNode } from "react";

type tittleProps = {
	children: ReactNode;
	color?: "primary" | "black" | "rose";
	style?: string;
	align?: "center" | "left" | "right" | "justify";
};

export const Tittle = ({
	children,
	style = "",
	color = "primary",
	align = "left",
}: tittleProps) => {
	return (
		<p
			className={`text-${color} text-3xl font-bold text-${align} ${style} `}
		>
			{children}
		</p>
	);
};

/* eslint-disable jsx-a11y/img-redundant-alt */
import { Subtitle, Paragraph, BaseBtn } from "presentational/atoms";
import React, { useState } from "react";
import { ICategoryDetail } from "services/entities/index";
import { Menu } from "../menu";
import { baseCdnImage } from "helpers/baseCdnImage";

export const CategoryDetailCard = ({
  categoryDetail,
  onEditCategory,
}: {
  categoryDetail: ICategoryDetail;
  onEditCategory: () => void;
}) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  return (
    <React.Fragment>
      <div className="w-1/2 ">
        <img
          src={baseCdnImage(categoryDetail.image)}
          alt="image-detail-service"
          className="w-full rounded-md h-56 object-cover"
        />
      </div>
      <div className="w-1/2 p-2 flex flex-col gap-2">
        <Subtitle align="left" color="primary">
          {categoryDetail?.name}
        </Subtitle>
        {/* <Paragraph fontWeight="normal" align="left">
          <b>Đang có: </b> 25 dịch vụ
        </Paragraph> */}
        {/* <Paragraph fontWeight="normal" align="left">
          <b>Được tạo vào: </b> 19:00, 26 Thg 3, 2002
        </Paragraph> */}
        <Paragraph fontWeight="normal" align="left">
          <b>Mô tả: </b> {categoryDetail.description}
        </Paragraph>
      </div>
      <Menu
        onToggle={() => {
          setIsOpenMenu((prevState) => !prevState);
        }}
        isOpen={isOpenMenu}
        menuButtonContent={
          <i className="fi fi-br-menu-dots-vertical cursor-pointer" />
        }
        menuBodyContent={
          <div className="flex flex-col justify-center items-center gap-2 rounded-md">
            <div className="w-full flex justify-center hover:bg-gray-extraLight">
              <BaseBtn
                isFullWidth
                padding="8px"
                title="Chỉnh sửa"
                onPress={() => {
                  onEditCategory();
                  setIsOpenMenu(false);
                }}
              />
            </div>
            <div className="w-full flex justify-center hover:bg-gray-extraLight">
              <BaseBtn
                title="Ngưng hoạt động"
                icon={<i className="fi fi-sr-pause-circle" />}
                iconPosition="left"
                isFullWidth
                color="rose"
                padding="8px"
                onPress={() => {
                  setIsOpenMenu(false);
                }}
              />
            </div>
          </div>
        }
      />
    </React.Fragment>
  );
};

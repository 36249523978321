import {
  IUIServiceRequirement,
} from "services/entities/serviceRequirement";

const isEmptyString = (item: string | undefined | null) =>
  !item || item.trim() === "";

const isNumber = (item: string | undefined | null) =>
  item && /^\d+$/.test(item);

export const ValidateServiceRequirements = (
  serviceRequirements: IUIServiceRequirement[]
) => {
  const serviceRequirementError: Record<string, string[]> = {};
  for (const requirement of serviceRequirements) {
    const { id, inputMethod, label, labelIcon, placeholder, isValidate } =
      requirement;
    const errorList = [];

    let isAddLackInfo = false;
    if (
      inputMethod.method.name === "input" ||
      inputMethod.method.name === "add_to_list" || 
      inputMethod.method.name === "select"
    ) {
      if (
        isEmptyString(label) ||
        isEmptyString(labelIcon) ||
        isEmptyString(placeholder)
      ) {
        errorList.push("nhập thiếu thông tin");
        isAddLackInfo = true;
      }
    }
    switch (inputMethod.method.name) {
      case "input":
      case "add_to_list": {
        if (
          isValidate &&
          inputMethod.validation &&
          inputMethod.validation.some((validation) => {
            if (validation.name === "required") return false;
            return isEmptyString(validation.message);
          })
        ) {
          if (!isAddLackInfo) errorList.push("nhập thiếu thông tin");
        }
        if (
          isValidate &&
          inputMethod.validation &&
          inputMethod.validation.some((validation) => {
            if (validation.name === "required") return false;

            return !isNumber(validation.value.toString());
          })
        ) {
          errorList.push("value phải là một số");
        }
        if (
          inputMethod.validation.length === 2 &&
          inputMethod.validation[0].name === inputMethod.validation[1].name
        ) {
          errorList.push("hai validate không thể cùng min hoặc max");
        }
        if (
          inputMethod.validation.length === 2 &&
          inputMethod.validation[0].name !== inputMethod.validation[1].name
        ) {
          if (
            inputMethod.validation[0].name === "max" &&
            inputMethod.validation[1].name === "min"
          ) {
            if (
              inputMethod.validation[0].value <= inputMethod.validation[1].value
            ) {
              errorList.push("max phải lớn hơn min");
            }
          } else if (
            inputMethod.validation[0].name === "min" &&
            inputMethod.validation[1].name === "max"
          ) {
            if (
              inputMethod.validation[0].value >= inputMethod.validation[1].value
            ) {
              errorList.push("max phải lớn hơn min");
            }
          }
        }
        break;
      }
      case "select": {
        if (
          inputMethod.method.options.some((option) => {
            return (
              isEmptyString(option.name) || isEmptyString(option.description)
            );
          })
        ) {
          errorList.push("nhập thiếu thông tin");
        }
        break;
      }
      case "special_itemselect": {
        for (const option of inputMethod.method.options) {
          if (isEmptyString(option.name) && !isAddLackInfo) {
            errorList.push("nhập thiếu thông tin");
            break;
          }
          let isStop = false;
          for (const info of option.info) {
            if (isStop) break;
            switch (info.type) {
              case "stepper":
                if (
                  (isEmptyString(info.label) || isEmptyString(info.mask)) &&
                  !isAddLackInfo
                ) {
                  errorList.push("nhập thiếu thông tin");
                  isStop = true;
                }
                if (!isNumber(info.defaultValue.toString())) {
                  errorList.push("default phải là số");
                  isStop = true;
                }
                if (
                  info.validation.length === 2 &&
                  info.validation[0].name === "min" &&
                  info.validation[1].name === "max" &&
                  info.validation[0].value > info.validation[1].value
                ) {
                  errorList.push("min phải nhỏ hơn max");
                  isStop = true;
                }
                break;
              case "button_group":
                if (isEmptyString(info.label) && !isAddLackInfo) {
                  errorList.push("nhập thiếu thông tin");
                  isStop = true;
                  break;
                }
                for (const button of info.buttons) {
                  if (isEmptyString(button) && !isAddLackInfo) {
                    errorList.push("nhập thiếu thông tin");
                    isStop = true;
                    break;
                  }
                }
                break;
            }
          }
        }

        break;
      }
      default:
        break;
    }
    serviceRequirementError[id] = errorList;
  }
  return serviceRequirementError;
};

import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Checkbox,
  Box,
  Center,
} from "@chakra-ui/react";
import { SERVICES_STATUS } from "constants/serviceOrder";
import { Paragraph } from "presentational/atoms";
import { IOrderDetail } from "services/entities";
import { ReactNode, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";

const HEADERS_TABLE = [
  "Thời gian đặt đơn",
  "Dịch vụ",
  "Giá",
  "Khách hàng",
  "Freelancer",
  "Thời gian hoàn thành"
];

type orderDetailTableProps = {
  orderDetail: IOrderDetail;
};

export const OrderDetailTable = ({ orderDetail }: orderDetailTableProps) => {
  const navigate = useNavigate();
  const goToOrderDetail = (orderId: string) => {
    navigate(`${PAGES.SERVICE_ORDER}/${orderId}`);
  }
 
  return (
    <TableContainer className="bg-white w-full rounded-md shadow-md">
      <Table >
        <Thead className="bg-blueShade-lightShade">
          <Tr >
            {HEADERS_TABLE.map((item) => (
              <Th textTransform="none" textAlign={"center"} paddingX="0" 

              >
                <Paragraph fontWeight="bold" size="xs" align="center">
                  {item}
                </Paragraph>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody> 
              <Tr className="hover:bg-blueShade-lightShade cursor-pointer">
                <Td border="none" className="text-sm" textAlign="center">{new Date(orderDetail.createdAt).toLocaleDateString() + " - " + new Date(orderDetail.createdAt).toLocaleTimeString()}</Td>

                <Td border="none" isTruncated className="text-sm" textAlign="center">
                {orderDetail.serviceTypes[0].name}

                </Td>
                <Td border="none" isTruncated className="text-sm" textAlign="center">
                {orderDetail.estimatedPrice === 0 ? "Chưa có giá" : orderDetail.estimatedPrice}
                </Td>
                <Td border="none" className="text-sm" textAlign="center"> {orderDetail.customer.fullName}</Td>
                <Td border="none" className="text-sm" textAlign="center">{orderDetail.freelancer ? orderDetail.freelancer.fullName : "Chưa bắt đầu"}</Td>
                <Td border="none" className="text-sm" textAlign="center">{orderDetail.finishDate === "0001-01-01" ? "-----" : orderDetail.finishDate}</Td>
              </Tr>
        </Tbody> 
      </Table>
    </TableContainer>
  );
};
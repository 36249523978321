import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import {
  INSIGHT_OVERVIEW,
  INSIGHT_FEEDBACK,
  INSIGHT_NEED_RESOLVE,
} from "constants/overview";
import {
  getAreaChartProperties,
  getColumnChartProperties,
  getLineChartProperties,
  getPieChartProperties,
} from "helpers/ChartProperties";
import { formatToMonthDay } from "helpers/DateTime";
import { BaseBtn, Paragraph, Subtitle } from "presentational/atoms";
import {
  EmptyInsight,
  InsightBadge,
  InsightCard,
} from "presentational/molecules";
import { formatCurrencyVND, onConvertNumberToCurrency } from "helpers/MoneyConverter";
import { currentMonth, currentYear, useOverviewManagement } from "businessLogic/context/overviewManagement";
import { DATE_OVERVIEW } from "constants/dateOverview";
import { profitOverviewType } from "services/lib/overviewService/type";

const Overview = () => {
  const { data: dashboardData } = useOverviewManagement()
  const [chartProfitProperties, setChartProfitProperties] = useState<any>();
  const [chartPromotionProperties, setChartPromotionProperties] =
    useState<any>();
  const [chartNewUserProperties, setChartNewUserProperties] = useState<any>();
  const [chartPopularService, setChartPopularService] = useState<any>();
  const {
    isOpen: isOpenDetailProfit,
    onOpen: onOpenDetailProfit,
    onClose: onCloseDetailProfiit } = useDisclosure();
  const onChartProfit = (profitOverview: any[]) => {
    const yProfitData: number[] = [];
    const xProfitData: string[] = [];
    profitOverview.forEach((profit) => {
      xProfitData.push(formatToMonthDay(profit.date));
      yProfitData.push(profit.profit);
    });
    setChartProfitProperties(
      getAreaChartProperties(
        yProfitData,
        xProfitData,
        "Profit",
        "Tổng quan doanh thu"
      )
    );
  };

  const onChartPromote = (promotions: any[]) => {
    const xPromoteData = promotions.map((promote) => ({
      name: promote.name,
      data:
        promote?.statistics.map(
          (statistic: { usageCount: any }) => statistic.usageCount
        ) ?? [],
    }));
    let indexMaxLengthPromotion = 0;
    promotions.forEach((promotion, index) => {
      if (
        promotion.statistics.length >
        promotions[indexMaxLengthPromotion].statistics.length
      )
        indexMaxLengthPromotion = index;
    });
    const yPromoteData = promotions[indexMaxLengthPromotion].statistics.map(
      (statistic: { month: any }) => statistic.month
    );
    setChartPromotionProperties(
      getLineChartProperties(
        xPromoteData,
        yPromoteData,
        "Khuyến mãi đã sử dụng"
      )
    );
  };

  const onChartNewUsers = (newUsers: any[]) => {
    const xNewUserData = newUsers.map((newUser) => ({
      name: newUser.name,
      data:
        newUser?.statistics.map(
          (statistic: { usageCount: any }) => statistic.usageCount
        ) ?? [],
    }));

    let indexMaxLengthPromotion = 0;
    newUsers.forEach((newUser, index) => {
      if (
        newUser.statistics.length >
        newUsers[indexMaxLengthPromotion].statistics.length
      )
        indexMaxLengthPromotion = index;
    });
    const yNewUserData = newUsers[indexMaxLengthPromotion].statistics.map(
      (statistic: { month: any, monthNum: any }) => statistic.month ?? statistic.monthNum ?? "month"
    );
    setChartNewUserProperties(
      getColumnChartProperties(xNewUserData, yNewUserData, "Người dùng mới")
    );
  };

  const onChartPopularService = (popularServices: any[]) => {
    const labels: string[] = [];
    const series: number[] = [];

    // eslint-disable-next-line array-callback-return
    popularServices.map((service) => {
      labels.push(service.serviceName);
      series.push(service.percentage);
    });
    setChartPopularService(
      getPieChartProperties(labels, series, "Dịch vụ phổ biến")
    );
  };


  useEffect(() => {
    if (!dashboardData) return;
    if (dashboardData.promotions) {
      onChartPromote(dashboardData.promotions);
    }
    onChartProfit(dashboardData.profitOverview);
    onChartNewUsers(dashboardData.newUsers);
    onChartPopularService(dashboardData.popularServices.statistics);
  }, [dashboardData]);

  return (
    <div className="overview-container bg-gray-extraLight p-2">
      <div className="column-first-overview flex flex-col gap-[0.4rem] ">
        <div className="w-full h-[12.8rem] shadow-lg rounded-md bg-white ">
          {chartProfitProperties ? (
            <ReactApexChart
              options={chartProfitProperties.options}
              series={chartProfitProperties.series}
              type="area"
              width="100%"
              height="100%"
            />
          ) : null}
        </div>
        {/* Detail Profit */}
        <ModalDetailProfit
          isOpenDetailProfit={isOpenDetailProfit}
          onCloseDetailProfiit={onCloseDetailProfiit}
          data={dashboardData?.profitOverview ?? []}
        />
        {/*  */}

        <div className="overflow-auto">
          <div className="w-full min-w-96 h-[12.8rem] shadow-lg rounded-md bg-white flex-col overflow-auto">

            {chartPopularService ? (
              <Chart
                options={chartPopularService.options}
                series={chartPopularService.series}
                type="pie"
                width="100%"
                height="100%"
              />
            ) : <EmptyInsight type="dịch vụ phổ biến" />}
          </div>
        </div>
        <div className="w-full h-[12rem] shadow-lg rounded-md bg-white">
          {chartPromotionProperties ? (
            <ReactApexChart
              options={chartPromotionProperties.options}
              series={chartPromotionProperties.series}
              type="line"
              width="100%"
              height="100%"
            />
          ) : (
            <EmptyInsight type="Khuyến mãi" />
          )}
        </div>
      </div>
      <div className="column-second-overview flex flex-col gap-[0.4rem]">
        <div className="w-full h-[21rem] shadow-lg rounded-md bg-white  p-4">
          <Paragraph align="left" color="primary">
            Tổng quan
          </Paragraph>
          <div className="flex justify-between items-center">
            <Subtitle align="left" color="primary">
              Tháng {currentMonth}, {currentYear}
            </Subtitle>

            <div className="flex gap-2">
              <BaseBtn
                title=""
                backgroundColor="platinum"
                radius="rounded"
                icon={
                  <i className="fi fi-rr-angle-small-left text-white text-2xl" />
                }
                onPress={() => { }}
              />
              <BaseBtn
                title=""
                backgroundColor="platinum"
                radius="rounded"
                fontSize="medium"
                icon={
                  <i className="fi fi-rr-angle-small-right text-white text-2xl" />
                }
                onPress={() => { }}
              />
            </div>
          </div>

          <div className="flex flex-col gap-3 mt-4 max-h-[20rem] overflow-y-auto">
            {dashboardData
              ? Object.entries(dashboardData.overview).map(([key, value]) => {
                if (key in INSIGHT_OVERVIEW) {
                  const insightItem = INSIGHT_OVERVIEW[key];
                  return (
                    <InsightCard
                      key={`${key}-${value}`}
                      icon={insightItem.icon}
                      backgroundIcon={insightItem.backgroundIcon}
                      title={`${insightItem.type === "currency"
                        ? formatCurrencyVND(value)
                        : value
                        }`}
                      description={insightItem.description}
                      // percentDiffInsight="10%"
                      isIncrease={true}
                      seeMoreComponent={key === "totalProfit" ?
                        <>
                          <i onClick={onOpenDetailProfit} className="fi fi-br-menu-dots-vertical cursor-pointer text-xs" />
                        </> : <></>}
                    />
                  );
                } else {
                  return null;
                }
              })
              : <EmptyInsight type="tổng quan" />}
          </div>
        </div>
        <div className="w-full h-[12.8rem] shadow-lg rounded-md bg-white ">

          {chartNewUserProperties ? (
            <Chart
              options={chartNewUserProperties.options}
              series={chartNewUserProperties.series}
              type="bar"
              width="100%"
              height="100%"
            />
          ) : (
            <EmptyInsight type="người dùng" />
          )}

        </div>
      </div>
      <div className="column-third-overview ">
        <Subtitle color="primary" align="left">
          Phản hồi
        </Subtitle>
        {dashboardData?.feedback ? (
          Object.entries(dashboardData.feedback).map(([key, value]) => {
            if (key in INSIGHT_FEEDBACK) {
              const insightItem = INSIGHT_FEEDBACK[key];
              return (
                <InsightBadge
                  value={value}
                  description={insightItem}
                  color={"black"}
                />
              );
            } else {
              return null;
            }
          })
        ) : (
          <EmptyInsight type="phản hồi" />
        )}
        <div className="my-5" />
        <Subtitle color="primary" align="left">
          Cần giải quyết
        </Subtitle>
        {dashboardData
          ? Object.entries(dashboardData.needResolve).map(([key, value]) => {
            if (key in INSIGHT_NEED_RESOLVE) {
              const insightItem = INSIGHT_NEED_RESOLVE[key];
              return (
                <InsightBadge
                  value={value}
                  description={insightItem}
                  color={key === "freelancerRequestCount" ? "rose" : "black"}
                />
              );
            } else {
              return null;
            }
          })
          : null}
      </div>
    </div>
  );
};

export default Overview;

type modalDetailProfiProps = {
  isOpenDetailProfit: boolean
  onCloseDetailProfiit: () => void
  data: profitOverviewType[]

}
const ModalDetailProfit = React.memo(({ isOpenDetailProfit, onCloseDetailProfiit, data }: modalDetailProfiProps) => {
  return (
    <Modal isOpen={isOpenDetailProfit} onClose={onCloseDetailProfiit}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Thống kê lợi nhuận</ModalHeader>
        <ModalCloseButton />
        <ModalBody maxH={"60vh"} overflowY={"auto"}>
          <TableContainer>
            <Table variant='striped'>
              <Thead>
                <Tr bg="GrayText" >
                  <Th color="white">STT</Th>
                  <Th color="white">Ngày</Th>
                  <Th color="white">Lợi nhuận</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  data.map((d, dIndex) => (
                    <Tr>
                      <Td>{dIndex + 1}</Td>

                      <Td>{d.date}</Td>
                      <Td> {formatCurrencyVND(d.netProfit)}</Td>
                    </Tr>
                  ))
                }
              </Tbody>

            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='gray' mr={3} onClick={onCloseDetailProfiit}>
            Đóng
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
})
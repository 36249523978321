import React from "react";
import ReactDOM from "react-dom/client";
import 'presentational/styles/global.css';
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { ChakraProvider } from "@chakra-ui/react";
import { AuthProvider } from "businessLogic/provider/AuthProvider";
import store from "businessLogic/redux/store";
import { Provider } from "react-redux";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <RecoilRoot>
    <BrowserRouter>
      <ChakraProvider>
        <AuthProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </AuthProvider>
      </ChakraProvider>
    </BrowserRouter>
  </RecoilRoot>
  // </React.StrictMode>
);

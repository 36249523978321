import {
  BaseBtn,
  BellButton,
  Paragraph,
  Subtitle,
} from "presentational/atoms";
import React, { useState } from "react";
import { Menu } from "../../molecules/menu";
import { UserCard } from "../../molecules/card";
import { useLocation, useNavigate } from "react-router-dom";
import { sideBarRoute } from "constants/sideBarRoute";
import { AccountDrawer } from "presentational/molecules";
import { findRouteChild } from "constants/header";
import { useSession } from "businessLogic/hook";

export const Header = () => {
  const [currentLocation, setCurrentLocation] = React.useState<string>("");
  let location = useLocation();
  const navigate = useNavigate();
  React.useEffect(() => {
    sideBarRoute.forEach((route) => {
      if (location.pathname.includes(route.path)) {
        if (route.children) {
          route.children.forEach((child) => {
            if (location.pathname.includes(child.path)) {
              setCurrentLocation(child.description ?? "");
            }
          });
        } else {
          setCurrentLocation(route.description ?? "");
        }
      }
    });
  }, [location]);
  const { user } = useSession()

  const [isViewedNotification, setIsViewedNotification] =
    useState<boolean>(false);

  const [isOpenNotificationMenu, setIsOpenNotificationMenu] =
    useState<boolean>(false);
  const onToggleNotification = () => {
    setIsViewedNotification(true);
    setIsOpenNotificationMenu(!isOpenNotificationMenu);
  };

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const onCloseDrawer = () => {
    setIsOpenDrawer(false);
  };
  const onToggle = () => {
    setIsOpenDrawer((prevState) => !prevState);
  };
  return (
    <nav className=" flex flex-row flex-wrap items-center justify-between shadow-container">
      <div className="flex justify-between items-center  py-1 px-3 w-full">
        {window.history.length > 1 && findRouteChild(location.pathname) != null ? (
          <div className="flex gap-1 items-center justify-start cursor-pointer">
            <BaseBtn
              title={
                <div className="flex gap-1">
                  <i className="fi fi-rr-arrow-left" />
                  <Paragraph fontWeight="normal">
                    {findRouteChild(location.pathname)?.parent}
                  </Paragraph>
                </div>
              }
              onPress={() => navigate(-1)}
            />
            {" > "}
            <Paragraph fontWeight="bold">
              {findRouteChild(location.pathname)?.child}
            </Paragraph>
          </div>
        ) : (
          <Subtitle color="black">{currentLocation}</Subtitle>
        )}
        <div className="flex items-center gap-12">
          <Menu
            isOpen={isOpenNotificationMenu}
            onToggle={onToggleNotification}
            menuBodyContent={<div>Notification Menu</div>}
            menuButtonContent={<BellButton isViewed={isViewedNotification} />}
          />
          <UserCard avatarUrl={user?.avatar} userName={user?.fullName ?? "Loading..."} />
          <BaseBtn
            onPress={() => {
              onToggle();
            }}
            title={<i className="fi fi-br-angle-down" />}
          />
        </div>
        <AccountDrawer isOpen={isOpenDrawer} onClose={onCloseDrawer} />
      </div>
    </nav>
  );
};

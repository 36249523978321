/* eslint-disable jsx-a11y/img-redundant-alt */
import { Paragraph, BaseBtn } from "presentational/atoms";
import { IService } from "services/entities/index";
import { Menu } from "../menu";
import { useState } from "react";
import { Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import { baseCdnImage } from "helpers/baseCdnImage";

export const ServiceSuggestionCard = ({
  serviceType,
}: {
  serviceType: IService;
}) => {
  const navigate = useNavigate()
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const onEditService = () => {
    navigate(`${PAGES.SERVICES_EDITING}\\${serviceType.id}`);
  };

  return (
    <div
      className="h-20 bg-white rounded-md shadow-md w-full p-2 pb-1 flex gap-2 relative"
      key={serviceType.id}
    >
      <img
        // src={serviceType.image}
        src={baseCdnImage(serviceType.image)}
        alt="image-detail-service"
        className=" rounded-full h-10 w-10 object-cover"
      />
      <div className="flex flex-col gap-1">
        <Paragraph align="left" fontWeight="bold">
          {serviceType.name}
        </Paragraph>

        <Box h={'fit-content'} overflow={"hidden"} className="max-w-full">
          <Paragraph align="left">{serviceType.description}</Paragraph>
        </Box>
      </div>
      <div className="absolute right-2 top-2">
        <Menu
          onToggle={() => {
            setIsOpenMenu((prevState) => !prevState);
          }}
          isOpen={isOpenMenu}
          menuButtonContent={
            <i className="fi fi-br-menu-dots-vertical cursor-pointer" />
          }
          menuBodyContent={
            <div className="flex flex-col justify-center items-center gap-2 rounded-md">
              <div className="w-full flex justify-center hover:bg-gray-extraLight">
                <BaseBtn
                  isFullWidth
                  padding="8px"
                  title="Chỉnh sửa"
                  onPress={() => {
                    onEditService();
                    setIsOpenMenu(false);
                  }}
                />
              </div>
              <div className="w-full flex justify-center hover:bg-gray-extraLight">
                <BaseBtn
                  isFullWidth
                  padding="8px"
                  title="Ngưng hoạt động"
                  icon={<i className="fi fi-sr-pause-circle" />}
                  iconPosition="left"
                  color="rose"
                  onPress={() => {
                    setIsOpenMenu(false);
                  }}
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

import { ApiHelper, detoiApi } from "businessLogic/redux/adminApi";

const api = detoiApi.injectEndpoints({
    endpoints: (builder) => ({
        getListFreelancer: builder.query({
            query: ({ status, page, pageSize = 5 }: { status?: string, page?: number, pageSize?: number }) => ApiHelper.get(`/api/v1/admin/all-freelancer`, {
                params: {
                    status,
                    page,
                    pageSize
                }
            }),
        }),
        getListRequestFreelancer: builder.query({
            query: () => ApiHelper.get(`/api/v1/admin/freelancer-request`, {}),
        }),
        getListRequestCompany: builder.query({
            query: () => ApiHelper.get(`api/v1/admin/company-request
`, {}),
        }),
        getDetailFreelancer: builder.query({
            query: (id: string) => ApiHelper.get(`/api/v1/admin/detail-freelancer?id=${id}`, {}),
        }),
    }),
});

export const {
    useGetListFreelancerQuery,
    useGetListRequestFreelancerQuery,
    useGetDetailFreelancerQuery,
    useGetListRequestCompanyQuery,
    endpoints: { getListFreelancer, getListRequestFreelancer, getDetailFreelancer, getListRequestCompany }
} = api;
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Checkbox,
  Box,
  Text,
} from "@chakra-ui/react";
import { SERVICES_STATUS } from "constants/serviceOrder";
import { Paragraph } from "presentational/atoms";
import { ReactNode, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import { paymentHistoryType } from "services/lib/paymentHistory/type";
import { formatCurrencyVND, onConvertNumberToCurrency } from "helpers/MoneyConverter";

const HEADERS_TABLE = [
  "Thời điểm giao dịch",
  "Phương thức",
  "Loại thanh toán",
  "Số tiền",
  "Loại ví",
  "Người thực hiện"
];

type OrderTableProps = {
  paymentHistory: paymentHistoryType[];
  paging: ReactNode
};

export const PaymentHistoryTable = ({ paymentHistory, paging }: OrderTableProps) => {

  return (
    <TableContainer className="bg-white w-full rounded-md shadow-md">
      <Table className="max-w-full">
        <Thead className="bg-blueShade-lightShade max-w-full ">
          <Tr >
            {HEADERS_TABLE.map((item) => (
              <Th textTransform="none" textAlign={"center"} paddingX="0"

              >
                <Paragraph fontWeight="bold" size="xs" align="center">
                  {item}
                </Paragraph>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {paymentHistory.map((p) => {

            return (
              <Tr className="hover:bg-blueShade-lightShade cursor-pointer">
                <Td border="none" className="text-sm" textAlign="center">{new Date(p.timestamp).toLocaleDateString() + " - " + new Date(p.timestamp).toLocaleTimeString()}</Td>
                <Td border="none" className="text-sm" textAlign="center"> {p.method}</Td>
                <Td border="none" className="text-sm" textAlign="center"> {p.paymentType === "Add" ? "Nạp tiền" : "Rút tiền"}</Td>
                <Td border="none" className="text-sm" textAlign="center">{formatCurrencyVND(p.value)}</Td>
                <Td border="none" className="text-sm" textAlign="center">{p.wallet === "System" ? "Hệ thống" : "Cá nhân"}</Td>
                <Td border="none" className="text-sm" textAlign="center">{p.customerAccount?.fullName ?? p.freelanceAccount.fullName }</Td>
                {/* <Td border="none" className="text-sm" textAlign="center">
                  <span className="font-semibold" >
                    <span className="line-clamp-1 font-medium text-sm ">
                      <b>Tên:</b>{" "}
                      <span className="font-normal">{p.freelanceAccount.fullName}</span>
                    </span>
                    <span className="line-clamp-1 font-medium text-sm">
                      <b>Ngày sinh:</b>{" "}
                      <span className="font-normal">
                        {p.freelanceAccount.id}
                      </span>
                    </span>
                  </span>
                </Td> */}
              </Tr>
            );
          })}
        </Tbody>
        {paymentHistory.length < 6 &&
          [...Array(6 - paymentHistory.length)].map((_, index) => (
            <div
              key={`blank-${index}`}
              className="h-12 w-full"
            ></div>
          ))}
      </Table>
      {paging}
    </TableContainer>
  );
};
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Flex,
    Center,
    Input,
    Box,
    Select,
    InputGroup,
    Text,
    InputRightElement,
    Divider,
    Switch,
  } from "@chakra-ui/react";
import { advanceFilter, basicFilter } from "pages/ServiceOrder";
import { Paragraph } from "presentational/atoms";
import React, { useState } from "react";
import regionData from "helpers/VietnamRegions/regionsData.json";
import { SERVICES_STATUS } from "constants/serviceOrder";
  
  const CATEGORY_OPTIONS = [
    { value: "sua_chua_1", label: "Sửa chữa 1" },
    { value: "sua_chua_2", label: "Sửa chữa 2" },
    { value: "sua_chua_3", label: "Sửa chữa 3" },
  ];
  
  const SERVICE_OPTIONS = [
    { value: "sua_chua_ong_nuoc_1", label: "Sửa chữa ổng nước 2" },
    { value: "sua_chua_ong_nuoc_2", label: "Sửa chữa ổng nước 2" },
    { value: "sua_chua_ong_nuoc_3", label: "Sửa chữa ổng nước 3" },
  ];
  
  type FilterOrderModalProps = {
    isOpen: boolean;
    onClose: () => void;
    setBasicFilter: (value: basicFilter) => void;
    setAdvanceFilter: (value: advanceFilter) => void;
  };
  
  type regionType = {
    Id: string;
    Name: string;
    Districts: {
      Id: string;
      Name: string;
      Wards: optionsWardType[];
    }[];
  };

  type optionsWardType = {
    Id: string;
    Name: string;
    Level: string;
  };

  export const FilterOrderModal = ({
    isOpen,
    onClose,
    setBasicFilter,
    setAdvanceFilter,
  }: FilterOrderModalProps) => {
    const [isUseAdvanceFilter, setIsUseAdvanceFilter] = useState<boolean>(false);
    const handleSwitchStatusFilterAdvance = () => {
      setIsUseAdvanceFilter((prevState) => !prevState);
    };
    const [optionsWard, setOptionsWard] = React.useState<optionsWardType[]>([]);

  
    const [basicFilterData, setBasicFilterData] = useState<basicFilter>({
      fromDateOrder: "",
      toDateOrder: "",
      category: "",
      service: "",
      minPrice: undefined,
      maxPrice: undefined,
      statusOrderId: "",
      placeOrder: "",
    });
  
    const [advanceFilterData, setAdvanceFilterData] = useState<advanceFilter>({
      district: "",
      ward: "",
      idCustomer: "",
      idFreelancer: "",
    });
  
    const handleBasicInputChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
      const { name, value } = e.target;
      setBasicFilterData({
        ...basicFilterData,
        [name]: value,
      });
    };
  
    const handleAdvanceInputChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
      const { name, value } = e.target;
      setAdvanceFilterData({
        ...advanceFilterData,
        [name]: value,
      });
    };
  
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minW={800} maxH="85vh" overflowY="scroll">
          <ModalHeader>
            <Flex justifyContent="space-between">
              <Text fontWeight="bold">Bộ lọc cơ bản</Text>
              <Button
                variant="unstyled"
                color="#4285F4"
                rightIcon={<i className="fi fi-br-refresh text-blueShade-cyan" />}
                onClick={() =>
                  setBasicFilterData({
                    fromDateOrder: "",
                    toDateOrder: "",
                    category: "",
                    service: "",
                    minPrice: undefined,
                    maxPrice: undefined,
                    statusOrderId: "",
                    placeOrder: "",
                  })
                }
              >
                Reset bộ lọc
              </Button>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Paragraph color="blueShade-mediumLightShade" fontWeight="medium">
              Khoảng thời gian đặt đơn
            </Paragraph>
            <Flex alignItems="center" mt={5}>
              <Input
                name="fromDateOrder"
                placeholder=""
                size="md"
                type="date"
                value={basicFilterData.fromDateOrder}
                onChange={handleBasicInputChange}
              />
              <Center w={64}>
                <i className="fi fi-rs-arrow-right" />
              </Center>
              <Input
                name="toDateOrder"
                placeholder=""
                size="md"
                type="date"
                value={basicFilterData.toDateOrder}
                onChange={handleBasicInputChange}
              />
            </Flex>
            {/* category and service */}
            {/* TODO */}
            <Flex alignItems="center" mt={5} w="full">
              <Box w="full">
                <Paragraph color="blueShade-mediumLightShade" fontWeight="medium">
                  Danh mục
                </Paragraph>
                <Select
                  name="category"
                  placeholder="Chọn danh mục"
                  mt={5}
                  value={basicFilterData.category}
                  onChange={handleBasicInputChange}
                >
                  {CATEGORY_OPTIONS.map((c) => (
                    <option value={c.value}>{c.label}</option>
                  ))}
                </Select>
              </Box>
              <Center w={64} />
              {/* TODO */}
              <Box w="full">
                <Paragraph color="blueShade-mediumLightShade" fontWeight="medium">
                  Dịch vụ
                </Paragraph>
                <Select
                  name="service"
                  placeholder="Chọn dịch vụ"
                  mt={5}
                  value={basicFilterData.service}
                  onChange={handleBasicInputChange}
                >
                  {SERVICE_OPTIONS.map((c) => (
                    <option value={c.value}>{c.label}</option>
                  ))}
                </Select>
              </Box>
            </Flex>
            {/* price */}
            <Flex alignItems="center" mt={5}>
              <Box w="full">
                <Paragraph color="blueShade-mediumLightShade" fontWeight="medium">
                  Giá đơn hàng tối thiểu
                </Paragraph>
                <InputGroup mt={5}>
                  <Input
                    name="minPrice"
                    placeholder=""
                    size="md"
                    type="number"
                    value={basicFilterData.minPrice}
                    onChange={handleBasicInputChange}
                  />
                  <InputRightElement pointerEvents="none" fontSize="1.2em">
                    <i className="fi fi-br-dollar" />
                  </InputRightElement>
                </InputGroup>
              </Box>
              <Center w={64} mt={8}>
                <i className="fi fi-rs-arrow-right" />
              </Center>
              <Box w="full">
                <Paragraph color="blueShade-mediumLightShade" fontWeight="medium">
                  Giá đơn hàng tối đa
                </Paragraph>
                <InputGroup mt={5}>
                  <Input
                    name="maxPrice"
                    placeholder=""
                    size="md"
                    type="number"
                    value={basicFilterData.maxPrice}
                    onChange={handleBasicInputChange}
                  />
                  <InputRightElement pointerEvents="none" fontSize="1.2em">
                    <i className="fi fi-br-dollar" />
                  </InputRightElement>
                </InputGroup>
              </Box>
            </Flex>
  
            {/* Status and place */}
            <Flex alignItems="center" mt={5} w="full">
              <Box w="full">
                <Paragraph color="blueShade-mediumLightShade" fontWeight="medium">
                  Trạng thái đơn
                </Paragraph>
                <Select
                  placeholder="Chọn trạng thái"
                  name="statusOrderId"
                  mt={5}
                  value={basicFilterData.statusOrderId}
                  onChange={handleBasicInputChange}
                >
                  {SERVICES_STATUS.map((c) => (
                    <option value={c.Name}>{c.Name}</option>
                  ))}
                </Select>
              </Box>
              <Center w={64} />
              <Box w="full">
                <Paragraph color="blueShade-mediumLightShade" fontWeight="medium">
                  Địa điểm đặt đơn
                </Paragraph>
                <Select
                  placeholder="Chọn địa điểm"
                  name="placeOrder"
                  mt={5}
                  value={basicFilterData.placeOrder}
                  onChange={handleBasicInputChange}
                >
                  {(regionData as regionType[]).map((c) => (
                  <option value={c.Name}>{c.Name}</option>
                ))}
                </Select>
              </Box>
            </Flex>
  
            <Divider my={6} />
  
            <Flex justifyContent="space-between">
              <Flex alignItems="center" gap={6}>
                <Text fontWeight="bold">Bộ lọc nâng cao</Text>{" "}
                <Box onClick={handleSwitchStatusFilterAdvance} cursor='pointer'>
                  <Switch
                    size="md"
                    colorScheme="green"
                    isChecked={isUseAdvanceFilter}
                  />
                </Box>
              </Flex>
  
              <Button
                variant="unstyled"
                color="#4285F4"
                rightIcon={<i className="fi fi-br-refresh text-blueShade-cyan" />}
                onClick={() =>
                  setAdvanceFilterData({
                    district: "",
                    ward: "",
                    idCustomer: "",
                    idFreelancer: "",
                  })
                }
              >
                Reset bộ lọc
              </Button>
            </Flex>
            {isUseAdvanceFilter ? (
              <React.Fragment>
                <Flex alignItems="center" mt={5} w="full">
                  <Box w="full">
                    <Paragraph
                      color="blueShade-mediumLightShade"
                      fontWeight="medium"
                    >
                      Quận/ Huyện
                    </Paragraph>
                    <Select
                      placeholder="Chọn Quận/ Huyện"
                      mt={5}
                      name="district"
                      value={advanceFilterData.district}
                      onChange={(e) => {
                        handleAdvanceInputChange(e);
                        const districtSelected = (regionData as regionType[])[0].Districts.find(district => district.Name === e.target.value);
                        setOptionsWard(districtSelected?.Wards ?? [])
                      }}
                    >
                      {(regionData as regionType[]).find(item => item.Name === basicFilterData.placeOrder)?.Districts.map((c) => (
                        <option value={c.Name}>{c.Name}</option>
                      ))}
                    </Select>
                  </Box>
                  <Center w={64} />
                  <Box w="full">
                    <Paragraph
                      color="blueShade-mediumLightShade"
                      fontWeight="medium"
                    >
                      Phường/ Xã
                    </Paragraph>
                    <Select
                      placeholder="Chọn Phường/ Xã"
                      mt={5}
                      name="ward"
                      value={advanceFilterData.ward}
                      onChange={handleAdvanceInputChange}
                    >
                      {optionsWard.map((c) => (
                        <option value={c.Name}>{c.Name}</option>
                      ))}
                    </Select>
                  </Box>
                </Flex>
  
                <Box mt={5}>
                  <Paragraph
                    color="blueShade-mediumLightShade"
                    fontWeight="medium"
                  >
                    ID khách hàng
                  </Paragraph>
                  <Input
                    placeholder="Nhập ID khách hàng"
                    mt={5}
                    name="idCustomer"
                    value={advanceFilterData.idCustomer}
                    onChange={handleAdvanceInputChange}
                  />
                </Box>
  
                <Box mt={5}>
                  <Paragraph
                    color="blueShade-mediumLightShade"
                    fontWeight="medium"
                  >
                    ID freelancer
                  </Paragraph>
                  <Input
                    placeholder="Nhập ID freelancer"
                    mt={5}
                    name="idFreelancer"
                    value={advanceFilterData.idFreelancer}
                    onChange={handleAdvanceInputChange}
                  />
                </Box>
              </React.Fragment>
            ) : (
              <></>
            )}
          </ModalBody>
          <Divider my={6} />
  
          <ModalFooter>
            <Center w="full" gap={5}>
              <Button
                bg="#624DE3"
                w={120}
                color="white"
                onClick={() => {
                  setBasicFilter(basicFilterData);
                  if (isUseAdvanceFilter) {
                    setAdvanceFilter(advanceFilterData);
                  }
                  onClose();
                }}
              >
                Áp dụng
              </Button>
              <Button
                bg="#E11D48"
                w={120}
                color="white"
                onClick={() => onClose()}
              >
                Hủy
              </Button>
            </Center>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };
  
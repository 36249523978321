import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { dashboardType } from "services/lib/overviewService/type";
import { useGetOverviewMutation } from "../../services/lib/apis";
import { DATE_OVERVIEW } from "constants/dateOverview";

export type overviewManagementType = {
  data?: dashboardType;
};

const OverviewManagementContext = createContext({} as overviewManagementType);
const currentDate = new Date();

export const currentYear = currentDate.getFullYear();
export const currentMonth = currentDate.getMonth() + 1;
export const OverManagementProvider = ({ children }: { children: any }) => {
  const [dashboardData, setDashboardData] = useState<dashboardType>();
  const [getOverview] = useGetOverviewMutation({});

  useEffect(() => {
    getOverview({
      discount: {
        month: currentMonth,
        year: currentYear,
      },
      income: {
        month: currentMonth,
        year: currentYear,
      },
      newUsers: {
        month: currentMonth,
        year: currentYear,
      },
      overview: {
        month: currentMonth,
        year: currentYear,
      },
    }).then((dashboardData) => {
      if (dashboardData.data) {
        setDashboardData(dashboardData.data as dashboardType);
      }
    });
  }, []);

  const value = useMemo(() => ({ data: dashboardData }), [dashboardData]);
  return (
    <OverviewManagementContext.Provider value={value}>
      {children}
    </OverviewManagementContext.Provider>
  );
};

export const useOverviewManagement = () =>
  useContext(OverviewManagementContext);

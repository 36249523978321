import { ReactNode } from "react";

type paragraphProps = {
  children: ReactNode;
  size?: "xs" | "sm" | "base" | "lg" | "xl";
  color?:
    | "primary"
    | "black"
    | "rose"
    | "white"
    | "gray-medium"
    | "green-medium"
    | "blueShade-mediumLightShade";
  fontWeight?:
    | "extralight"
    | "light"
    | "normal"
    | "medium"
    | "semibold"
    | "bold"
    | "extrabold"
    | "black";
  style?: string;
  align?: "center" | "left" | "right" | "justify";
  truncate?: boolean;
  isNoWrap?: boolean;
};

export const Paragraph = ({
  children,
  style = "",
  color = "black",
  size = "base",
  fontWeight = "normal",
  align = "left",
  truncate = false,
  isNoWrap = false,
}: paragraphProps) => {
  return (
    <p
      className={`${
        truncate ? "truncate" : ""
      } text-${color} text-${size} font-${fontWeight} text-${align} ${style} leading-4 ${
        isNoWrap ? "whitespace-nowrap overflow-hidden text-ellipsis" : ""
      }`}
    >
      {children}
    </p>
  );
};

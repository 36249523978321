import { y_dateOfBirth, y_fullName, y_phone } from './default';
import * as y from 'yup';

export const registerFreelancerSchema = y.object({
  phone: y_phone,
  identification: y
    .string()
    .typeError('Định dạng không hợp lệ')
    .required('Số căn cước công dân hoặc chứng minh nhân dân là bắt buộc.')
    .matches(
      /^[0-9]{9}$|^[0-9]{12}$/,
      'Số căn cước công dân hoặc chứng minh nhân dân không hợp lệ'
    ),
  fullName: y_fullName,
  sex: y
    .string()
    .typeError('Định dạng không hợp lệ')
    .required('Không được để trống giới tính'),
  dateOfBirth: y_dateOfBirth,
  avatar: y
    .string()
    .typeError('Định dạng không hợp lệ')
    .required('Bạn chưa thêm ảnh đại diện'),
});

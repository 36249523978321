import { IOption, infoType, inputMethodTypeName } from "services/entities/index";
import { validationType } from "validators/yup";

export const LIMIT_SIZE_IMAGE = 1;

export type selectionType = {
	value: any;
	label: string;
};

export type serviceRequirementOptions = {
	value: inputMethodTypeName;
	label: string;
  };

export const TYPE_SERVICES_ADDITION: selectionType[] = [
	{
		value: 'input',
		label: "Input",
	},
	{
		value: 'select',
		label: "Dropdown",
	},
	{
		value: 'add_to_list',
		label: "Add to list",
	},
	{
		value: 'special_itemselect',
		label: "Special select",
	},
];

export const TYPE_SERVICES_ADDITION_ICON: Record<
	inputMethodTypeName,
	JSX.Element
> = {
	input: <i className="fi fi-br-align-left" />,
	select: <i className="fi fi-sr-angle-circle-down" />,
	add_to_list: <i className="fi fi-br-align-left" />,
	special_itemselect: <i className="fi fi-sr-angle-circle-down" />,
};

export const TYPE_VALIDATION = [
	{ value: "text", label: "Text" },
	{ value: "number", label: "Number" },
];

export const TYPE_VALIDATION_TEXT = [
	{ value: "max", label: "Maximum character" },
	{ value: "min", label: "Minimum character" },
];

export const TYPE_VALIDATION_NUMBER = [
	{ value: "max", label: "Maximum number" },
	{ value: "min", label: "Minimum number" },
];

export const TYPE_SPECIAL_ITEMS = [
	{
		value: "stepper",
		label: "Stepper",
	},
	{
		value: "button_group",
		label: "Button Group",
	},
];

export const TYPE_SPECIAL_ITEMS_ICON = {
	[TYPE_SPECIAL_ITEMS[0].value]: <i className="fi fi-sr-layers" />,
	[TYPE_SPECIAL_ITEMS[1].value]: <i className="fi fi-br-align-left" />,
};

export const ADDRESS_REQUIREMENT_OPTIONS = [
	{
		value: "none",
		label: "none",
	},
	{
		value: "destination",
		label: "destination",
	},
	{
		value: "shipping",
		label: "shipping",
	},
];

export const ACTIVE_CATEGORY_OPTIONS = [
	{ value: "0", label: "Không kích hoạt" },
	{ value: "1", label: "Kích hoạt" },
];

export type contentAction =
  |  { type: "input-update-label", serviceRequirementId: string, newLabel: string}
  |  { type: "input-update-labelIcon", serviceRequirementId: string, newLabelIcon: string}
  |  { type: "input-update-validate", serviceRequirementId: string, isValidate: boolean}
  |  { type: "input-update-required", serviceRequirementId: string, isRequired: boolean}
  |  { type: "input-update-placeholder", serviceRequirementId: string, newPlaceholder: string}
  |  { type: "input-add-validation", serviceRequirementId: string, newInputMethod: { id: string } & validationType}
  |  { type: "input-update-validation-dataType", serviceRequirementId: string, validationId: string, newDataType: 'text' | 'number';}
  |  { type: "input-update-validation-message", serviceRequirementId: string, validationId: string, newMessage: string}
  |  { type: "input-update-validation-value", serviceRequirementId: string, validationId: string, newValue: number}
  |  { type: "input-remove-validation", serviceRequirementId: string, validationId: string}
  |  { type: "input-update-validation-name", serviceRequirementId: string, validationId: string, newName: 'max' | 'min' }

  |  { type: "dropdown-update-label", serviceRequirementId: string, newLabel: string}
  |  { type: "dropdown-update-labelIcon", serviceRequirementId: string, newLabelIcon: string}
  |  { type: "dropdown-update-placeholder", serviceRequirementId: string, newPlaceholder: string}

  |  { type: "dropdown-add-option", serviceRequirementId: string, newOption: IOption}
  |  { type: "dropdown-update-option-description", serviceRequirementId: string, optionIndex: number, newDescription: string}
  |  { type: "dropdown-update-option-name", serviceRequirementId: string, optionIndex: number, newName: string}
  |  { type: "dropdown-swap-option", serviceRequirementId: string, destinationIndex: number, sourceIndex: number}
  |  { type: "dropdown-remove-option", serviceRequirementId: string, optionIndex: number}

  |  { type: "addToList-update-label", serviceRequirementId: string, newLabel: string}
  |  { type: "addToList-update-labelIcon", serviceRequirementId: string, newLabelIcon: string}
  |  { type: "addToList-update-placeholder", serviceRequirementId: string, newPlaceholder: string}
  |  { type: "addToList-add-validation", serviceRequirementId: string, newInputMethod: { id: string } & validationType}
  |  { type: "addToList-update-validation-dataType", serviceRequirementId: string, validationId: string, newDataType: 'text' | 'number'}
  |  { type: "addToList-update-validation-value", serviceRequirementId: string, validationId: string, newValue: number}
  |  { type: "addToList-update-validation-name", serviceRequirementId: string, validationId: string, newName: 'max' | 'min'}
  |  { type: "addToList-update-required", serviceRequirementId: string, isRequired: boolean}

  |  { type: "addToList-remove-validation", serviceRequirementId: string, validationId: string}
//   |  { type: "addToList-update-inputMethod-value", serviceRequirementId: string, addToListMethodId: string, newValue: string}
  |  { type: "addToList-update-validation-message", serviceRequirementId: string, validationId: string, newMessage: string}
  |  { type: "addToList-update-validation-name", serviceRequirementId: string, validationId: string, newName: 'max' | 'min'}

  |  { type: "special-update-option-name", serviceRequirementId: string, optionIndex: number, newName: string}
//   |  { type: "special-update-option", serviceRequirementId: string, newOption: specialOption}
  |  { type: "special-add-option", serviceRequirementId: string, newOption: { name: string; info: infoType[] }}
  |  { type: "special-remove-option", serviceRequirementId: string, optionIndex: number}

  |  { type: "special-add-option-info", serviceRequirementId: string, optionIndex: number, newInfo: infoType}

//   |  { type: "special-remove-option", serviceRequirementId: string, specialOptionId: string}
  |  { type: "special-update-option-info-type", serviceRequirementId: string, optionIndex: number, infoIndex: number, newType: 'stepper' | 'buttonGroup'}

  |  { type: "special-update-option-info-remove", serviceRequirementId: string, optionIndex: number, infoIndex: number}
  |  { type: "special-update-option-info-duplicate", serviceRequirementId: string,  optionIndex: number, infoIndex: number}

  |  { type: "special-update-stepper-label", serviceRequirementId: string, optionIndex: number, infoIndex: number, newLabel: string}
  |  { type: "special-update-stepper-mask", serviceRequirementId: string, optionIndex: number, infoIndex: number, newMask: string}
  |  { type: "special-update-stepper-maxValue", serviceRequirementId: string, optionIndex: number, infoIndex: number, newMaxValue: number}
  |  { type: "special-update-stepper-minValue", serviceRequirementId: string, optionIndex: number, infoIndex: number, newMinValue: number}
  |  { type: "special-update-stepper-defaultValue", serviceRequirementId: string, optionIndex: number, infoIndex: number, newDefaultValue: number}

  |  { type: "special-update-buttonGroup-label", serviceRequirementId: string, optionIndex: number, infoIndex: number, newLabel: string }
  |  { type: "special-update-buttonGroup-button", serviceRequirementId: string, optionIndex: number, infoIndex: number, buttonIndex: number, newButtonContent: string}
  |  { type: "special-add-buttonGroup-button", serviceRequirementId: string, optionIndex: number, infoIndex: number}
  |  { type: "special-remove-buttonGroup-button", serviceRequirementId: string, optionIndex: number, infoIndex: number, buttonIndex: number,}
  |  { type: "special-swap-buttonGroup-button", serviceRequirementId: string, optionIndex: number, infoIndex: number, destinationIndex: number, sourceIndex: number}
import { countWords } from "helpers/CountWord";
import * as y from "yup";

export const categorySchema = y.object({
  name: y.string().required("Tên là bắt buộc"),
  image: y.string().required("Hình ảnh là bắt buộc"),
  description: y
  .string()
  .trim()
  .test({
    name: 'word-count',
    message: 'Description phải từ 30 đến 60 từ',
    test: (value: string | undefined) => {
      if (!value) return false;
      const wordCount = countWords(value);
      return wordCount >= 10 && wordCount <= 60;
    },
  })
  .required('Mô tả là bắt buộc'),
  keys :y.array().min(1, "Bạn phải thêm tối thiểu 1 keyword").required("Bạn phải thêm tối thiểu 1 keyword"),
  isActivated: y.boolean().required("Activation status is required"),
});

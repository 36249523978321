import { LIMIT_SIZE_IMAGE } from "constants/servicesAddition";
import React, { useState, ChangeEvent, useRef, useEffect } from "react";

type imageInputProps = {
  onChange: (value: string) => void;
  setIsExceedSizeLimit: (value: boolean) => void;
  source?: string;
};

export const ImageInput = ({
  onChange,
  setIsExceedSizeLimit,
  source,
}: imageInputProps) => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  useEffect(() => {
    if (source) {
      setImagePreview(source);
    }
  }, [source]);
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const maxAllowedSize = LIMIT_SIZE_IMAGE * 1024 * 1024;

    if (file) {
      if (file.size > maxAllowedSize) {
        setIsExceedSizeLimit(true);
        return;
      }
      setIsExceedSizeLimit(false);
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === "string") {
          onChange(reader.result);
          setImagePreview(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === "Enter" && e.target instanceof HTMLInputElement) {
        e.preventDefault(); // Prevent default form submission
      }
    };
    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  return (
    <div className="flex items-center justify-center w-full cursor-pointer">
      <button
        type="button"
        onClick={handleClick}
        className="flex flex-col items-center justify-center w-full h-52 border-2 border-blueShade-shade border-dashed rounded-lg cursor-pointer bg-gray-50"
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          {imagePreview ? (
            <img
              src={imagePreview}
              alt="Preview"
              className="h-40 w-40 object-cover rounded-lg"
            />
          ) : (
            <i className="fi fi-br-picture text-5xl text-blueShade-mediumLightShade" />
          )}
        </div>
      </button>
      <input
        id="imageUpload"
        type="file"
        className="hidden"
        accept="image/*"
        ref={hiddenFileInput}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default ImageInput;

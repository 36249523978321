
export const BellButton = ({ isViewed = true }: { isViewed?: boolean }) => {
  return (
    <button className="inline-block relative">

      <i
        className="fi fi-bs-bell"
        style={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.4)" }}
      />
      <span
        className={`absolute top-0 right-0 ${
          isViewed ? "hidden" : "block"
        } h-2 w-2 rounded-full ring-green-medium-400 bg-rose`}
      ></span>
    </button>
  );
};

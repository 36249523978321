import { Box, Flex } from "@chakra-ui/react";
import { Tittle } from "presentational/atoms";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IFreelancerAccountDetail } from "services/entities";
import { freelancerList } from "./mock";
import {
  ErrorItem,
  FreelancerAddressSection,
  FreelancerCardIdSection,
  FreelancerOrderedSection,
  FreelancerPersionalInfoSection,
  FreelancerStatisticOrderSection,
  LoadingItem,
} from "presentational/organisms";
import { useGetDetailFreelancerQuery } from "../business-layer/services/lib/apis";

export default function FreelancerDetail() {
  const { id } = useParams<{ id: string }>();
  const {
    data: dataListCustomer,
    isFetching: isFetchingCustomer,
    isError: isErrorCustoner,
  } = useGetDetailFreelancerQuery(id ?? "id");

  useEffect(() => {
    if (!isErrorCustoner) {
      setfreelancerDetail(dataListCustomer);
    }
  }, [isErrorCustoner, dataListCustomer]);

  const [freelancerDetail, setfreelancerDetail] =
    useState<IFreelancerAccountDetail>();
  useEffect(() => {
    setfreelancerDetail(freelancerList.find((customer) => customer.id === id));
  }, [id]);

  if (isFetchingCustomer) {
    return <LoadingItem />;
  }

  if (isErrorCustoner || !freelancerDetail) {
    return <ErrorItem />;
  }

  return (
    <div className=" p-5 pb-10">
      <Box className="border-solid border-b-2 pb-5 mb-10">
        <Tittle align="left" color="black">
          Hồ sơ freelancer
        </Tittle>
      </Box>

      <FreelancerPersionalInfoSection freelancerDetail={freelancerDetail} />
      <Box my={10} />
      <Flex gap={5} justifyContent={"space-between"}>
        <FreelancerAddressSection address={freelancerDetail.address} />
        <FreelancerStatisticOrderSection statictisOrder={freelancerDetail.successRate}/>
      </Flex>
      <Box my={10} />
      <FreelancerCardIdSection freelancerDetail={freelancerDetail} />
      {/* <Box my={10} />
      <FreelancerStatisticSection freelancerDetail={freelancerDetail}/>
      <Box my={10} />
      <FreelancerPriceSection freelancerDetail={freelancerDetail}/> */}
      <Box my={10} />
      <FreelancerOrderedSection freelancerDetail={freelancerDetail} />
    </div>
  );
}

import { Center, Spinner, VStack, Text } from "@chakra-ui/react";

export function LoadingItem() {
  return (
    <Center minH={200}>
      <VStack gap={4}>
        <Text>Detoi loading...</Text>
        <Spinner size="xl" />
      </VStack>
    </Center>
  );
}

import { configureStore, createSlice } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {  CDNService, uploadParamsType } from '../../services/lib/cdnService';

// Initialize CDN service
const service = new CDNService();

// Define Redux slice for CDN operations
const initialState = {
  uploadToSirvResult: null,
  uploadToBunnyResult: null,
  deleteFromBunnyResult: null,
  error: null,
};

const cdnSlice = createSlice({
  name: 'cdn',
  initialState,
  reducers: {
    uploadToSirvSuccess(state, action) {
      state.uploadToSirvResult = action.payload;
      state.error = null;
    },
    uploadToSirvFailure(state, action) {
      state.uploadToSirvResult = null;
      state.error = action.payload;
    },
    uploadToBunnySuccess(state, action) {
      state.uploadToBunnyResult = action.payload;
      state.error = null;
    },
    uploadToBunnyFailure(state, action) {
      state.uploadToBunnyResult = null;
      state.error = action.payload;
    },
    deleteFromBunnySuccess(state, action) {
      state.deleteFromBunnyResult = action.payload;
      state.error = null;
    },
    deleteFromBunnyFailure(state, action) {
      state.deleteFromBunnyResult = null;
      state.error = action.payload;
    },
  },
});

export const {
  uploadToSirvSuccess,
  uploadToSirvFailure,
  uploadToBunnySuccess,
  uploadToBunnyFailure,
  deleteFromBunnySuccess,
  deleteFromBunnyFailure,
} = cdnSlice.actions;

export const selectCDNState = (state: any) => state.cdn;

export const cdnReducer = cdnSlice.reducer;

export const store = configureStore({
  reducer: {
    cdn: cdnReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useUploadToSirvMutation = () => {
  const dispatch = useAppDispatch();

  const uploadToSirv = useCallback(async (params: uploadParamsType) => {
    try {
      const result = await service.uploadFileToSirv(params);
      dispatch(uploadToSirvSuccess(result));
      return result;
    } catch (error: any) {
      dispatch(uploadToSirvFailure(error.toString()));
      throw error;
    }
  }, [dispatch]);

  return uploadToSirv;
};

export const useUploadToBunnyMutation = () => {
  const dispatch = useAppDispatch();

  const uploadToBunny = useCallback(async (params: uploadParamsType) => {
    try {
      const result = await service.uploadFileToBunny(params);
      dispatch(uploadToBunnySuccess(result));
      return result;
    } catch (error: any) {
      dispatch(uploadToBunnyFailure(error.toString()));
      throw error;
    }
  }, [dispatch]);

  return uploadToBunny;
};

export const useDeleteFromBunnyMutation = () => {
  const dispatch = useAppDispatch();

  const deleteFromBunny = useCallback(async (fileId: string) => {
    try {
      const result = await service.deleteFileFromBunny(fileId);
      dispatch(deleteFromBunnySuccess(result));
      return result;
    } catch (error: any) {
      dispatch(deleteFromBunnyFailure(error.toString()));
      throw error;
    }
  }, [dispatch]);

  return deleteFromBunny;
};

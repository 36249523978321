import { BaseBtn, BorderBottomInput, Paragraph } from "presentational/atoms";
import React, { useMemo } from "react";
import { useRef, useState } from "react";

type draggableItemProps = {
  button?: string;
  isDeletable?: boolean;
  index: number;
  isAutoFocus?: boolean;
  typeDisplay?: "input" | "text";
  onRemove?: () => void;
  onChange?: (newContent: string) => void;
};

const DraggableItem = ({
  isDeletable = false,
  isAutoFocus = false,
  index,
  button,
  typeDisplay = "input",
  onRemove,
  onChange,
}: draggableItemProps) => {
  return (
    <div className="flex w-full items-end gap-10">
      <div className="flex items-end gap-3 w-full">
        <Paragraph size="base">{index + 1}.</Paragraph>
        {typeDisplay === "input" ? (
          <BorderBottomInput
            autoFocus={isAutoFocus}
            placeholder="Thêm button"
            size="sm"
            value={button}
            onChange={onChange}
          />
        ) : (
          <Paragraph size="sm">{button ?? ""}</Paragraph>
        )}
      </div>

      <div className={`${isDeletable ? "" : "invisible"}`}>
        <BaseBtn
          title=""
          icon={<i className="fi fi-br-cross" />}
          onPress={() => {
            if (onRemove) {
              onRemove();
            }
          }}
        />
      </div>
    </div>
  );
};

type props = {
  swapButtons: (destinationIndex: number, sourceIndex: number) => void;
  removeButton: (indexButton: number) => void;
  updateButton: (indexButton: number, newContent: string) => void;
  addButton: () => void;
  buttons: string[];
};

export const DraggableInputQuestionGroup = ({
  swapButtons,
  removeButton,
  updateButton,
  buttons,
  addButton,
}: props) => {
  const [indexOptionSelected, setIndexOptionSelected] = useState<number>(-1);
  const [dragging, setDragging] = useState<number>();

  const dragOption = useRef<number>(-1);
  const draggedOverOption = useRef<number>(0);

  function handleSort() {
    swapButtons(dragOption.current, draggedOverOption.current);
    setDragging(undefined);
  }
  const isEnableAddButtons = useMemo(() => {
    return buttons.every((item) => item.trim().length);
  }, [buttons]);
  return (
    <div className="flex flex-col items-center gap-2">
      {buttons?.map((button, index) => {
        return (
          <div
            onClick={() => setIndexOptionSelected(index)}
            className="relative w-full"
            draggable
            onDragStart={() => {
              setDragging(index);
              dragOption.current = index;
            }}
            onDragEnter={() => (draggedOverOption.current = index)}
            onDragEnd={handleSort}
            onDragOver={(e) => e.preventDefault()}
          >
            <div
              className={`absolute -left-5 bottom-0 ${
                dragging === index ? "" : "invisible"
              }`}
            >
              <i className="fi fi-br-arrows" />
            </div>
            <DraggableItem
              key={index}
              index={index}
              isAutoFocus={true}
              button={button}
              isDeletable={true}
              onRemove={() => removeButton(index)}
              typeDisplay={indexOptionSelected === index ? "input" : "text"}
              onChange={(value) => updateButton(index, value)}
            />
          </div>
        );
      })}
      <div
        className="w-full relative "
        onClick={() => {
          if (!isEnableAddButtons) return;
          addButton();
        }}
      >
        <DraggableItem index={buttons.length} />
        <div className="absolute w-full h-full top-0 bg-transparent" />
      </div>
    </div>
  );
};

import { userType } from "pages/UsersManagement";

type TableHeadProps = {
  userSelected: userType;
  checkedState: boolean[];
  handleSelectAll: (value: boolean) => void;
};

export const TableUserHead = ({
  userSelected,
  checkedState,
  handleSelectAll,
}: TableHeadProps) => {
  return (
    <thead className="text-gray font-medium">
      <tr>
        {/* <th scope="col" className="px-2 w-[5%]">
          <span className="hover:bg-softBlueberry w-9 h-9 rounded-full flex items-center justify-center">
            <input
              type="checkbox"
              className="w-4 ha-3.5 cursor-pointer"
              checked={
                checkedState.length > 0 && checkedState.every((value) => value)
              }
              onChange={(e) => handleSelectAll(e.target.checked)}
            />
          </span>
        </th> */}
        <th scope="col" className="px-2 py-3 w-[10%]">
          Ảnh đại diện
        </th>
        <th scope="col" className="px-2 py-3 w-[20%]">
          Thông tin chung
        </th>
        <th scope="col" className="px-2 py-3 w-[10%]">
          Số điện thoại
        </th>
        {userSelected === userType.freelancer ? (
          <>
            <th scope="col" className="px-2 py-3 w-[20%]">
              Địa chỉ
            </th>
            {/* <th scope="col" className="px-2 py-3 w-[10%]">
              Dịch vụ
            </th> */}
          </>
        ) : (
          <>
            <th scope="col" className="px-2 py-3 w-[10%]">
              Trạng thái
            </th>
            <th scope="col" className="px-2 py-3 w-[10%]">
              Điểm
            </th>
          </>
        )}

        {userSelected === userType.freelancer && (
          <th scope="col" className="px-2 py-3 w-[15%]">
            Được đánh giá
          </th>
        )}
        <th scope="col" className="px-2 py-3 w-[10%]"></th>
      </tr>
    </thead>
  );
};

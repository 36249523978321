import { ApiHelper, detoiApi } from "businessLogic/redux/adminApi";

type updateOrderStatusProps = {
    orderId: string,
    statusId: string
}

const api = detoiApi.injectEndpoints({
    endpoints: (builder) => ({
        getListOrder: builder.query({
            query: ({ status, page, pageSize }: { status?: string, page?: number, pageSize?: number }) => ApiHelper.get(`/api/v1/admin/order`, {
                params: {
                    status,
                    page,
                    pageSize
                }
            }),
        }),
        getDetailOrder: builder.query({
            query: (id: string) => ApiHelper.get(`/api/v1/admin/order/detail?id=${id}`, {}),
        }),
        updateOrderStatus: builder.mutation({
            query: (data: updateOrderStatusProps) => ApiHelper.delete(`api/v1/order?orderId=${data.orderId}`, { data })
        })
    }),
});

export const {
    useGetListOrderQuery,
    useGetDetailOrderQuery,
    useUpdateOrderStatusMutation,
    endpoints: { getListOrder, getDetailOrder, updateOrderStatus }
} = api;
import { VStack, HStack, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { IAddress } from "services/entities";

export const FreelancerAddressSection = React.memo(
  ({ address }: { address: IAddress }) => {
    return (
      <VStack
        className="bg-silver border border-solid shadow-md w-full"
        borderRadius={5}
        p={5}
        gap={5}
        alignItems={"flex-start"}
      >
        <HStack>
          <i className="fi fi-ss-marker" />
          <Text>Địa chỉ</Text>
        </HStack>
        <Flex flexWrap={"wrap"} gap={5}>
          <VStack
            gap={2}
            alignItems={"flex-start"}
            maxW={250}
            bg="white"
            p={3}
            borderRadius={5}
          >
            <Text className="text-gray-light">Địa chỉ</Text>
            <Text>
              {" "}
              {address?.district ?? ""},{" "}
              {address?.ward ?? ""},{" "}
              {address?.province ?? ""}
            </Text>
          </VStack>
        </Flex>
      </VStack>
    );
  }
);

import { Box, Flex, VStack, HStack, Text } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { IFreelancerAccountDetail } from "services/entities";

export const InfoItem = React.memo(
  ({ title, content }: { title: string; content: ReactNode }) => {
    return (
      <HStack>
        <Text className="text-blueShade-mediumLightShade" w={150}>
          {title}
        </Text>
        <Text>{content}</Text>
      </HStack>
    );
  }
);

export const FreelancerPersionalInfoSection = React.memo(
  ({ freelancerDetail }: { freelancerDetail: IFreelancerAccountDetail }) => {
    return (
      <Box className="bg-silver border border-solid shadow-md" borderRadius={5}>
        <Flex gap={10} p={5}>
          <VStack className="w-full" alignItems={"flex-start"} gap={3}>
            <HStack>
              <i className="fi fi-sr-user" />
              <Text>Tài khoản</Text>
            </HStack>
            <InfoItem
              title="Họ và tên"
              content={<Text>{freelancerDetail.account.fullName}</Text>}
            />
            <InfoItem
              title="Ngày sinh"
              content={<Text>{freelancerDetail.account.dateOfBirth}</Text>}
            />
            <InfoItem
              title="Số điện thoại"
              content={<Text>{freelancerDetail.account.phone}</Text>}
            />
            {/* <InfoItem
              title="Email"
              content={<Text>nhatduy0409@gmail.com</Text>}
            /> */}
            <InfoItem
              title="Giới tính"
              content={
                <Text>
                  {freelancerDetail.account.gender === "Male" ? "Nam" : "Nữ"}
                </Text>
              }
            />
            <InfoItem
              title="CMND"
              content={<Text>{freelancerDetail.identityNumber}</Text>}
            />
            <InfoItem
              title="Team"
              content={
                <Text
                  className={`${
                    freelancerDetail.isTeam === true
                  } ? 'text-green-medium ': 'text-rose'`}
                >
                  {freelancerDetail.isTeam ? "Yes" : "No"}
                </Text>
              }
            />
            <InfoItem
              title="Số thành viên"
              content={<Text>{freelancerDetail.teamMemberCount}</Text>}
            />
          </VStack>
          <VStack className="w-full" gap={3} alignItems={"flex-start"}>
            <HStack>
              <i className="fi fi-sr-ranking-star" />
              <Text>Hệ thống</Text>
            </HStack>
            <InfoItem
              title="Đánh giá"
              content={<Text>{freelancerDetail.rating}</Text>}
            />
            <InfoItem
              title="Tổng review"
              content={<Text>{freelancerDetail.totalReviewCount}</Text>}
            />
            <InfoItem
              title="Review tích cực"
              content={<Text>{freelancerDetail.positiveReviewCount}</Text>}
            />
            <InfoItem
              title="Tổng đơn"
              content={<Text>{freelancerDetail.orderCount}</Text>}
            />
            <InfoItem
              title="Tổng đơn yêu thích"
              content={<Text>{freelancerDetail.loveCount}</Text>}
            />
            <InfoItem
              title="Tổng đơn yêu thích"
              content={
                <Text className="text-rose">
                  {freelancerDetail.account.isActive
                    ? "Bình thường"
                    : "Đã bị cấm"}
                </Text>
              }
            />
          </VStack>
        </Flex>

        <VStack className="w-full" alignItems={"flex-start"} p={5}>
          <HStack>
            <i className="fi fi-sr-user" />
            <Text>Mô tả</Text>
          </HStack>
          <Box pl={5}>
            <Text fontWeight={"400"}>{freelancerDetail.description}</Text>
          </Box>
        </VStack>
      </Box>
    );
  }
);

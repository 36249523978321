/* eslint-disable jsx-a11y/img-redundant-alt */
import { getPieChartProperties } from "helpers/ChartProperties";
import Chart from "react-apexcharts";
import {
  CategoriesSearch,
  CategoryDetailCard,
  ServiceSuggestionCard,
} from "presentational/molecules";
import { useEffect, useState } from "react";
import { ICategoryDetail } from "services/entities/index";
import { BaseBtn, Paragraph } from "presentational/atoms";
import { useNavigate, useParams } from "react-router-dom";
import { PAGES } from "constants/pages";
import { useGetDetailCategoryQuery } from "../business-layer/services/lib/apis";
import { LoadingItem, ErrorItem } from "presentational/organisms";
import { useOverviewManagement } from "businessLogic/context/overviewManagement";

export const CategoryDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [categoryDetail, setCategoryDetail] = useState<ICategoryDetail>();
  const { data: dashboardData } = useOverviewManagement();

  const [chartPopularService, setChartPopularService] = useState<any>();
  const [searchContent, setSearchContent] = useState<string>("");
  const { data, isFetching, isError } = useGetDetailCategoryQuery(id ?? "id");

  const onChartPopularService = (popularServices: any[]) => {
    const labels: string[] = [];
    const series: number[] = [];

    // eslint-disable-next-line array-callback-return
    popularServices.map((service) => {
      labels.push(service.serviceName);
      series.push(service.percentage);
    });
    setChartPopularService(
      getPieChartProperties(labels, series, "Dịch vụ phổ biến")
    );
  };

  useEffect(() => {
    if (dashboardData) {
      onChartPopularService(dashboardData.popularServices.statistics);
    }
  }, [dashboardData]);
  useEffect(() => {
    setCategoryDetail(data);
  }, [data, isError]);

  if (!categoryDetail) return null;

  const onEditCategory = () => {
    navigate(`${PAGES.CATEGORIES_EDITING}\\${categoryDetail.id}`);
  };

  if (isFetching) {
    return <LoadingItem />;
  }

  if (isError || !categoryDetail) {
    return <ErrorItem />;
  }

  return (
    <div className="bg-silver h-screen p-2">
      <div className="bg-white rounded-lg shadow-md flex gap-20 p-4 items-center mb-10 mt-3">
        <CategoriesSearch
          placeholder="Tìm kiếm theo tên danh mục..."
          onSearch={setSearchContent}
        />
        <BaseBtn
          title={
            <div className="min-w-32">
              <Paragraph color="white" align="center">
                Thêm dịch vụ
              </Paragraph>
            </div>
          }
          onPress={() => {
            navigate(`${PAGES.SERVICES_ADDITION}\\${categoryDetail.id}`);
          }}
          padding="15px"
          icon={<i className="fi fi-bs-plus" />}
          iconPosition="left"
          backgroundColor="primary"
          color="white"
          radius="full"
        />
      </div>
      <div className="grid grid-cols-3 gap-2 ">
        <div className="col-span-2 bg-white h-60 rounded-md shadow-md w-full p-2 flex gap-2 relative">
          <CategoryDetailCard
            categoryDetail={categoryDetail}
            onEditCategory={onEditCategory}
          />
        </div>
        <div className="bg-white h-60 rounded-md shadow-md w-full p-2">
          {chartPopularService ? (
            // <Chart
            //   options={chartPopularService.options}
            //   series={chartPopularService.series}
            //   type="pie"
            //   width="100%"
            //   height="100%"
            // />
            <></>
          ) : null}
        </div>
        {categoryDetail.serviceTypes
          .filter((category) =>
            category.name
              .toLocaleLowerCase()
              .includes(searchContent.toLocaleLowerCase())
          )
          .map((serviceType) => {
            return <ServiceSuggestionCard serviceType={serviceType} />;
          })}
      </div>
    </div>
  );
};

export default CategoryDetail;
import { Box, Image, Flex, Text, Card, Center, VStack } from "@chakra-ui/react";
import { BaseBtn, Subtitle, Tittle } from "presentational/atoms";
import {
  Menu,
  OrderDetailTable,
  TableRequestOrder,
  UpdateStatusOrderModal,
} from "presentational/molecules";
import {
  ErrorItem,
  LoadingItem,
  OrderImageSection,
  Pagination,
  RatingCommentSection,
  TimelineOrderSection,
} from "presentational/organisms";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { IAdminOrderDetail, IReviewBase } from "services/entities";
import { PROVEN_STATUS, SERVICES_STATUS } from "constants/serviceOrder";
import { useGetDetailOrderQuery } from "../business-layer/services/lib/apis";
import React from "react";
import { ResponsiveContainer, StackedCarousel } from "react-stacked-center-carousel";
import { baseCdnImage } from "helpers/baseCdnImage";

const TITLES_CONTENT = ["Câu hỏi", "Câu trả lời "];
const IMAGE_PICKER = "image_picker"
const ServiceOrderDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isError, isFetching, refetch } = useGetDetailOrderQuery(id ?? "id")

  const [orderDetail, setOrderDetail] = useState<IAdminOrderDetail>();
  const bodyContent = React.useMemo(() => {
    if (!orderDetail) return [];
    return Object.values(orderDetail.services.requirement).map((value) => ({
      label: value.label,
      type: value.value[0],
      value: value.value.slice(1)
    }));
  }, [orderDetail]);

  useEffect(() => {
    if (!data || isError) return;
    // const orderDetail = mockOrderDetails.find((order) => order.id === id);
    setOrderDetail(data);
  }, [id, data]);

  const sourceAddress = useMemo(() => {
    if (!orderDetail || !orderDetail.address[0]) return "";
    return `
    ${orderDetail.address[0].addressLine}, 
    ${orderDetail.address[0].ward}, 
    ${orderDetail.address[0].district}, 
    ${orderDetail.address[0].country}
  `;
  }, [orderDetail]);

  const destAddress = useMemo(() => {
    if (!orderDetail || !orderDetail.address[1]) return "";
    return `
    ${orderDetail.address[1].addressLine}, 
    ${orderDetail.address[1].ward}, 
    ${orderDetail.address[1].district}, 
    ${orderDetail.address[1].country}
  `;
  }, [orderDetail]);

  if (!orderDetail) {
    return <Box>Loading</Box>;
  }

  const currStatus = SERVICES_STATUS.find(
    (status) => status.Id === orderDetail.serviceStatus
  );


  if (isFetching) {
    return <LoadingItem />;
  }

  if (isError || !orderDetail) {
    return <ErrorItem />;
  }

  return (
    <div className="bg-silver p-5 pb-10">
      <Flex justify="space-between">
        <Tittle align="left" color="black">
          {/* ID đơn: {id} */}
          {orderDetail.startDate.replaceAll("-", "/")} - {orderDetail.serviceTypes[0].name} - {orderDetail.customer.fullName}
        </Tittle>{" "}
        {/* <Center
          bg={currStatus?.backgroundColor}
          color={currStatus?.textColor}
          fontWeight="medium"
          borderRadius="full"
          px={3}
          className="text-xs"
        >
          {currStatus?.Name ?? ""}
        </Center> */}
      </Flex>
      <Box className="my-10" />

      <Box className=" rounded-2xl border px-10 pt-4 pb-12 bg-white">
        <Flex justify="space-between">
          <Subtitle>Chi tiết đơn dịch vụ</Subtitle>
          {/* <Center
            bg={currStatus?.backgroundColor}
            color={currStatus?.textColor}
            fontWeight="medium"
            borderRadius={5}
            px={3}
            className="text-xs"
          >
            {currStatus?.Name ?? ""}
          </Center> */}
          {currStatus
            ? <UpdateStatusOrderModal orderId={orderDetail.id} currentOrderStatus={currStatus} refetch={refetch} /> : null}

        </Flex>
        <Box className="my-6" />
        <OrderDetailTable orderDetail={orderDetail} />
      </Box>
      <Box className="my-10" />

      {
        orderDetail.address.length > 0 &&
        <Box className=" rounded-2xl border px-10 pt-4 pb-12 bg-white">
          <Subtitle>Địa chỉ</Subtitle>
          <Box className="my-6" />
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Flex gap={2} alignItems={"center"}>
              <i className="fi fi-ss-marker text-blueShade-cyan"></i>
              {orderDetail.address?.[0] ? <Text>{sourceAddress}</Text> : <></>}
            </Flex>
            {orderDetail.address?.[1] ? <> <i className="fi fi-rr-arrow-right"></i>
              <Flex gap={2} alignItems={"center"} ml={4}>
                <i className="fi fi-ss-marker text-rose"></i>
                {orderDetail.address?.[1] ? <Text>{destAddress}</Text> : <></>}
              </Flex> </> : null}
          </Flex>
        </Box>
      }
      <Box className="my-10" />

      {/* <Box className=" px-10 bg-white pt-4 pb-12 rounded-2xl border border-red-lightShade">
        <TimelineOrderSection />
      </Box> */}
      <Box className="my-10" />
      <Box className=" px-10 bg-white pt-4 pb-12 rounded-2xl border border-red-lightShade">
        <Subtitle>Yêu cầu đơn dịch vụ</Subtitle>
        <Box className="my-6" />
        <Box className="px-28">
          {/* Tan hack */}
          <TableRequestOrder
            bodyContent={bodyContent.filter(b => b.type !== IMAGE_PICKER).map(b => ({
              ...b,
              value: Array.isArray(b.value) ? b.value.toString() : b.value
            }))}
            titleContent={TITLES_CONTENT}
          />
        </Box>
        {/* Tan hack */}
        <br />
        {
          bodyContent.filter(b => b.type === IMAGE_PICKER).map((imagePickerItem) => {
            return (
              <Box w='100%'>
                <Text >{imagePickerItem.label}</Text>
                <br />
                <Flex overflowX={"auto"} gap={10}>
                  {
                    imagePickerItem.value.map(imagePickerValue => {

                      if (!imagePickerValue) return null
                      return (
                        <Image
                          src={baseCdnImage(imagePickerValue)}
                          className="w-40 h-4w-40"
                        />)
                    })
                  }
                </Flex>

                {/* <ResponsiveContainer
                  carouselRef={ref}
                  render={(parentWidth, carouselRef) => {
                    let currentVisibleSlide = 5;
                    if (parentWidth <= 720) currentVisibleSlide = 1;
                    const maxVisibleSlide = Math.min(5, imagePickerItem.value.length || 1);
                    if (currentVisibleSlide > maxVisibleSlide) {
                      currentVisibleSlide = maxVisibleSlide;
                    }

                    return (
                      <StackedCarousel
                        data={[]}
                        ref={carouselRef}
                        carouselWidth={parentWidth}
                        slideWidth={500}
                        slideComponent={Card}
                        maxVisibleSlide={maxVisibleSlide}  // Use the adjusted number of visible slides
                        currentVisibleSlide={currentVisibleSlide}  // Use the adjusted currentVisibleSlide
                        onActiveSlideChange={onCenterSlideDataIndexChange}
                      />
                    );
                  }}
                /> */}
                {/* <Pagination centerSlideDataIndex={centerSlideDataIndex} images={imagePickerItem.value ?? []} /> */}
              </Box>

            )
          }
          )
        }
      </Box>
      <Box className="my-10" />
      <Box className="px-10 bg-white pt-4 pb-12 rounded-2xl border border-red-lightShade">
        <Subtitle>Hình ảnh minh chứng</Subtitle>
        <Box className="my-6" />

        {/* Before */}
        <div className="flex flex-row justify-between">
          <Text color="#A2A4AF">
            Hình ảnh trước khi thực hiện dịch vụ
          </Text>
          {orderDetail.beforeProven == null ?
            <Text color="#E11D48">Chưa có hình ảnh</Text>
            :
            (orderDetail.beforeProven.data.length === 0 &&
              <Text color="#E11D48">
                {PROVEN_STATUS[orderDetail.beforeProven.status]}
              </Text>
            )
          }
        </div>
        <Box className="my-6" />
        {orderDetail.beforeProven && orderDetail.beforeProven.data.length > 0 && <OrderImageSection proven={orderDetail.beforeProven} key={"1221@"} />}

        {/* After */}
        <div className="flex flex-row justify-between">
          <Text color="#A2A4AF">
            Hình ảnh sau khi thực hiện dịch vụ
          </Text>
          {orderDetail.afterProven == null ?
            <Text color="#E11D48">Chưa có hình ảnh</Text>
            :
            (orderDetail.afterProven.data.length === 0 &&
              <Text color="#E11D48">
                {PROVEN_STATUS[orderDetail.afterProven.status]}
              </Text>
            )
          }
        </div>
        <Box className="my-6" />
        {orderDetail.afterProven && orderDetail.afterProven.data.length > 0 && <OrderImageSection proven={orderDetail.afterProven} key={"asdsadas"} />}

      </Box>
      <Box className="my-10" />
      {/* <Box className="px-10 bg-white pt-4 pb-12 rounded-2xl border border-red-lightShade">
        <Subtitle>Hình ảnh minh chứng</Subtitle>
        <Box className="my-6" />
        <Text color="#A2A4AF">
          Hình ảnh sau khi thực hiện dịch vụ
        </Text>
        <Box className="my-6" />
        <OrderImageSection images={orderDetail.afterProven?.data ?? []} />
      </Box> */}
      <Box className="my-10" />
      <Box className=" px-10 bg-white pt-4 pb-12 rounded-2xl border border-red-lightShade">
        <Subtitle>Đánh giá và bình luận</Subtitle>
        <Box className="my-6" />
        <RatingCommentSection comment={orderDetail.comment} rating={orderDetail.rating} commentator={orderDetail.customer} />
      </Box>
    </div>
  );
};

export default ServiceOrderDetail;

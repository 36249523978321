import { ReactNode } from 'react';
import { Paragraph } from '../text';

type baseBtnProps = {
  title: ReactNode;
  fontSize?: 'small' | 'medium' | 'large'; // Adjust as needed
  icon?: ReactNode;
  iconPosition?: 'left' | 'right'; // Adjust as needed
  gap?: number;
  color?: string;
  backgroundColor?: string;
  alignItems?: string;
  border?: 'none' | 'solid'; // Adjust as needed
  borderColor?: string;
  radius?: 'square' | 'rounded' | 'full'; // Adjust as needed
  disabled?: boolean;
  isFitContent?: boolean;
  isLoading?: boolean;
  isFullWidth?: boolean;
  onPress: () => void;
  padding?: string
  type?: 'button' | 'submit'
}

export const BaseBtn: React.FC<baseBtnProps> = ({
  title,
  fontSize = 'bold',
  icon,
  iconPosition = 'left',
  gap = 5,
  color,
  backgroundColor = 'secondary',
  alignItems = 'center',
  border = 'none',
  borderColor,
  radius = 'square',
  disabled,
  isFitContent,
  isFullWidth,
  isLoading,
  onPress,
  padding,
  type = 'button'
}) => {
  const titleJsx = title ? (
    <Paragraph style={`text-${fontSize} text-${color}`} fontWeight='bold'>{title}</Paragraph>
  ) : (
    null
  );
  const iconJsx = icon ? (
    <i className={`text-${color}`}>
      {icon}
    </i>
  ) : (
    null
  );

  return (
    <button
      disabled={disabled || isLoading}
      className={`btn bg-${backgroundColor} ${
        isLoading ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      style={{
        justifyContent: alignItems,
        borderWidth: border === 'solid' ? '1px' : '0px',
        borderColor: borderColor ? borderColor : 'transparent',
        borderRadius: radius === 'rounded' ? '0.375rem' : 'full' ? '99px' : '0px', 
        width: isFullWidth ? '100%': 'fit-content'
      }}
      onClick={() => onPress()}
      type={type}
    >
      {isLoading ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-8 w-8"></div>
        </div>
      ) : null}
      <div
        className="flex flex-row items-center justify-center "
        style={{ gap, padding }}
      >
        {iconPosition === 'left' ? (
          <>
            {iconJsx}
            {titleJsx}
          </>
        ) : (
          <>
            {titleJsx}
            {iconJsx}
          </>
        )}
      </div>
    </button>
  );
};

import { ADDRESS_REQUIREMENT_OPTIONS } from "constants/servicesAddition";
import {
	Subtitle,
	VerticalSpacer,
	Paragraph,
	NormalSelect,
} from "presentational/atoms";
import { Control, Controller } from "react-hook-form";

type props = {
	control: Control<any>;
};

export const AddressRequirementSection = ({ control }: props) => {
	return (
		<>
			<Subtitle color="black">Yêu cầu địa chỉ</Subtitle>
			<VerticalSpacer size="l" />
			<div className="border border-red-lightShade border-solid w-full rounded-md p-3">
				<Paragraph
					color="blueShade-mediumLightShade"
					fontWeight="semibold"
				>
					Loại địa chỉ cho dịch vụ
				</Paragraph>
				<VerticalSpacer size="s" />
				{/* <NormalSelect
          options={ADDRESS_REQUIREMENT_OPTIONS}
          onChange={() => {}}
        /> */}
				<Controller
					name="addressRequireOption"
					control={control}
					render={({ field }) => (
						<NormalSelect
							options={ADDRESS_REQUIREMENT_OPTIONS}
							value={field.value}
							onChange={(option: {
								label: string;
								value: string;
							}) => {
								field.onChange(option.value);
							}}
						/>
					)}
				/>
			</div>
		</>
	);
};

import { useChatManagement } from "businessLogic/context/chatManagement";
import { BaseBtn, MessageInput } from "presentational/atoms";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useSendMessageMutation } from "../../../business-layer/services/lib/apis";

type messageFormProps = {
  sendTo: string;
};
export const MessageForm = ({  sendTo }: messageFormProps) => {
  const { onAddMessage } = useChatManagement();
  const [sendMessage] = useSendMessageMutation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      message: "",
    },
  });

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    setValue("message", "", { shouldValidate: true });
    try {
      await sendMessage({
        sendTo,
        content: data.message ?? "",
        // image: data.content ?? "asdasdsa",
        isSystem: false,
      });
      onAddMessage(data.message)
    } catch (error) {
      console.log({ error });
    }
    // onAddMessage(data.message ?? "");
  };

  return (
    <div className="p-4 bg-white flex items-center gap-2 w-full">
      {/* <CldUploadButton
        options={{ maxFiles: 1 }}
        onUpload={handleUpload}
        uploadPreset='cyzjjon9'
      >
        <HiPhoto size={30} className='text-sky-500' />
      </CldUploadButton> */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex items-center gap-2 lg:gap-4 w-full"
      >
        <MessageInput
          id="message"
          register={register}
          errors={errors}
          required
          placeholder="Write a message"
        />
        <BaseBtn
          title=""
          icon={
            <i className="fi fi-bs-paper-plane text-blueShade-cyan text-lg" />
          }
          onPress={handleSubmit(onSubmit)}
        />
      </form>
    </div>
  );
};

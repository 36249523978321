import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Checkbox,
  Box,
  Center,
} from "@chakra-ui/react";
import { SERVICES_STATUS } from "constants/serviceOrder";
import { Paragraph } from "presentational/atoms";
import { IOrderDetail } from "services/entities";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import { complaintPropsType } from "services/lib/feedbackService/type";
import { COMPLAINT_THEME } from "pages/Complaint";

const HEADERS_TABLE = [
  "Người khiếu nại",
  "Trạng thái",
  "Người bị khiếu nại",
  "Dịch vụ",
  "Ngày tạo khiếu nại",
];

type complaintTableProps = {
  complaints: complaintPropsType[];
  paging: ReactNode;
};

export const ComplaintTable = ({ complaints, paging }: complaintTableProps) => {
  const navigate = useNavigate();
  const goToOrderDetail = (orderId: string) => {
    navigate(`${PAGES.COMPLAINT}/${orderId}`);
  };
  return (
    <TableContainer className="bg-white w-full rounded-md shadow-md min-w-[80vw]">
      <Table>
        <Thead className="bg-blueShade-lightShade">
          <Tr>
            {HEADERS_TABLE.map((item) => (
              <Th textTransform="none" textAlign={"center"} paddingX="0">
                <Paragraph fontWeight="bold" size="xs" align="center">
                  {item}
                </Paragraph>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {complaints.map((complaint) => {
            return (
              <Tr
                className="hover:bg-blueShade-lightShade cursor-pointer"
                onClick={() => goToOrderDetail(complaint.id)}
              >
                <Td
                  border="none"
                  isTruncated
                  className="text-sm"
                  textAlign="center"
                >
                  {complaint.complainant.fullName}
                </Td>

                <Td border="none" isTruncated textAlign="center">
                  <Center>
                    {/* hard code kinh khủng @@ */}
                    <Box
                      bg={COMPLAINT_THEME[complaint.complainStatus].background}
                      color={COMPLAINT_THEME[complaint.complainStatus].color}
                      fontWeight="medium"
                      borderRadius="full"
                      textAlign="center"
                      py={1}
                      px={3}
                      maxW={"fit-content"}
                      className="text-xs"
                    >
                      {
                        complaint.complainStatus === 'Pending'
                          ? "Cần giải quyết" :
                          complaint.complainStatus === 'Rejected' ? 'Đã từ chối'
                            : "Đã giải quyết"
                      }
                    </Box>
                  </Center>
                </Td>
                <Td
                  border="none"
                  isTruncated
                  className="text-sm"
                  textAlign="center"
                >
                  {complaint.respondent.fullName}
                </Td>
                <Td border="none" className="text-sm" textAlign="center">
                  {complaint.order.serviceTypes[0]?.name ?? ""}
                </Td>
                <Td border="none" className="text-sm" textAlign="center">
                  {/* {" "} */}
                  {new Date(complaint.createdAt).toLocaleDateString() + " - " + new Date(complaint.createdAt).toLocaleTimeString()}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        {/* {complaints.values.length < 6 &&
          [...Array(6 - complaints.values.length)].map((_, index) => (
            <div key={`blank-${index}`} className="h-12 w-full"></div>
          ))} */}
      </Table>
      {paging}
    </TableContainer>
  );
};

export const IPv4_ADDRESS = process.env.EXPO_PUBLIC_IPv4_ADDRESS ?? '';
export const APP_ROLE = process.env.EXPO_PUBLIC_APP_ROLE ?? '';
export const BUNNY_USER = process.env.REACT_APP_EXPO_PUBLIC_BUNNY_USER ?? '';
export const BUNNY_STORAGE_ZONE_NAME =
  process.env.REACT_APP_EXPO_PUBLIC_BUNNY_STORAGE_ZONE_NAME ?? '';
export const BUNNY_API_KEY = process.env.REACT_APP_EXPO_PUBLIC_BUNNY_API_KEY ?? '';
export const GOOGLE_DIRECTION_API_KEY =
  process.env.EXPO_PUBLIC_DIRECTION_API_KEY ?? '';
export const GOOGLE_CAPTCHA_API_KEY =
  process.env.EXPO_PUBLIC_CAPTCHA_API_KEY ?? '';
export const SERVER_BASE_URL = process.env.EXPO_PUBLIC_SERVER_BASE_URL ?? '';

import { countWords } from "helpers/CountWord";
import * as y from "yup";

export const serviceSchema = y.object({
	name: y.string().required("Tên là bắt buộc"),
	image: y.string().required("Hình ảnh là bắt buộc"),
	basePrice: y
		.number()
		.typeError("Giá phải là một số")
		.min(1000, "Giá thấp nhất là 1.000 VND")
		.required("Giá là bắt buộc"),
	addressRequireOption: y.string(),
	description: y
		.string()
		.trim()
		.test({
			name: "word-count",
			message: "Description phải từ 10 đến 60 từ",
			test: (value: string | undefined) => {
				if (!value) return false;
				const wordCount = countWords(value);
				return wordCount >= 10 && wordCount <= 60;
			},
		})
		.required("Description is required"),
	keys: y
		.array()
		.min(1, "Bạn phải thêm tối thiểu 1 keyword")
		.required("Bạn phải thêm tối thiểu 1 keyword"),
	isActivated: y.boolean().required("Activation status is required"),
});

"use client";
import { useEffect, useRef, useState } from "react";
import { IConversation, IMessage } from "services/entities/chat";
import { MessageBody } from "./MessageBody";
import { Paragraph } from "presentational/atoms";

type BodyProps = {
  conversation: IConversation[];
};

const ChatBody: React.FC<BodyProps> = ({ conversation = [] }) => {
  const bottomRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversation]);

  useEffect(() => {
    const updateMessageHandler = (newMessage: IMessage) => {
      // TODO
    };
  }, []);

  const timeDiff = (sourceTime: string, desTime: string) => {
    const sourceDate = new Date(sourceTime);
    const desDate = new Date(desTime);
    const now = new Date();
    // Check if the dates are valid
    if (isNaN(sourceDate.getTime()) || isNaN(desDate.getTime())) {
      throw new Error("Invalid date format");
    }

    // Calculate the difference in milliseconds
    const diffMs = Math.abs(desDate.getTime() - sourceDate.getTime());

    const diffHours = diffMs / (1000 * 60 * 60);

    if (diffHours > 1) {
      return `${now.getHours()}:${now.getMinutes()} - Hôm nay`;
    }

    return null;
  };


  return (
    <div className="flex-1 overflow-y-auto">
      {conversation.slice().reverse().map((message, i) => (
        <div className="w-full">
          {i > 0 &&
            (() => {
              const diff = timeDiff(
                conversation[i - 1].timeReceived,
                conversation[i].timeReceived
              );
              return diff ? (
                <Paragraph
                  size="xs"
                  align="center"
                  color="blueShade-mediumLightShade"
                >
                  {diff}
                </Paragraph>
              ) : null;
            })()}
          <MessageBody
            key={message.id}
            data={message}
            isLastMessage={conversation.length === i + 1}
          />
        </div>
      ))}
      <div ref={bottomRef} />
    </div>
  );
};

export default ChatBody;

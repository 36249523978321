import { ReactNode, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import paths from "constants/pathServices";
import { User, SignInCredentials, AuthContext, servicesPermissionType } from "businessLogic/context";
import {
  createSessionCookies,
  getToken,
  removeSessionCookies,
  setAuthorizationHeader,
  api,
} from "../../services/lib/login";
import { ADMIN_CATEGORY_KEY } from "pages/AdminPermission";
type Props = {
  children: ReactNode;
};

export function AuthProvider(props: Props) {
  const { children } = props;

  const [user, setUser] = useState<User>();
  const [loadingUserData, setLoadingUserData] = useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isLogout, setIsLogout] = useState<boolean>(false);
  const token = getToken();
  const isAuthenticated = Boolean(token) && !isLogout;

  async function signIn(params: SignInCredentials, callback: () => void) {
    const { email, password } = params;

    try {
      const response = await api.post("/api/v1/auth/login/admin", {
        email,
        password,
      });
      const { token, refreshToken, permissions, roles } = response.data;

      createSessionCookies({ token, refreshToken });
      setAuthorizationHeader({ request: api.defaults, token });
      setIsLogout(false);
      callback();
      return { success: true, data: response.data };
    } catch (error) {
      const err = error as AxiosError;
      return { success: false, error: err };
    }
  }

  function signOut() {
    removeSessionCookies();
    setUser(undefined);
    setLoadingUserData(false);
    navigate(paths.LOGIN_PATH);
    setIsLogout(true);
  }

  useEffect(() => {
    if (!token) {
      removeSessionCookies();
      setUser(undefined);
      setLoadingUserData(false);
    }
  }, [navigate, pathname, token]);

  useEffect(() => {
    const token = getToken();

    async function getUserData() {
      setLoadingUserData(true);

      try {
        const response = await api.get("/api/v1/account/current-account");

        if (response?.data) {
          const { fullName, avatar, phone, id, role } = response.data;
          setUser({ id, fullName, avatar, phone, role });
        }
      } catch (error) {
        /**
         * an error handler can be added here
         */
      } finally {
        setLoadingUserData(false);
      }
    }

    if (token) {
      setAuthorizationHeader({ request: api.defaults, token });
      getUserData();
    }
  }, [isAuthenticated]);

  const servicesPermission: servicesPermissionType = useMemo(() => {
    if (!user) return {
      overview: false,
      payment: false,
      userManagement: false,
      order: false,
      complaint: false,
      service: false,
      adminPermission: false
    }
    const { role } = user;
    const { ADMIN, APP_SERVICE_ADMIN,
      CUSTOMER_SERVICE_ADMIN,
      PROFILE_VERIFY_ADMIN } = ADMIN_CATEGORY_KEY;
    return {
      overview: [ADMIN].includes(role),
      payment: [ADMIN].includes(role),
      userManagement: [ADMIN, PROFILE_VERIFY_ADMIN].includes(role),
      order: [ADMIN, CUSTOMER_SERVICE_ADMIN].includes(role),
      complaint: [ADMIN, CUSTOMER_SERVICE_ADMIN].includes(role),
      service: [ADMIN, APP_SERVICE_ADMIN].includes(role),
      adminPermission: [ADMIN, PROFILE_VERIFY_ADMIN].includes(role),
    }
  }, [user])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loadingUserData,
        signIn,
        signOut,
        servicesPermission
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

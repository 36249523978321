import { Paragraph, Tittle } from "presentational/atoms";

type insightBadgeProps = {
  value: number;
  description: string;
  color: "primary" | "black" | "rose";
};

export const InsightBadge = ({
  value,
  description,
  color,
}: insightBadgeProps) => {
  return (
    <div className={`flex gap-2 p-2 justify-start items-center border-solid rounded-md bg-white max-w-full text-${color}`}>
      <Tittle>{value}</Tittle>
      <Paragraph align="left">{description}</Paragraph>
    </div>
  );
};

"use client";

import { useSession } from "businessLogic/hook";
import clsx from "clsx";
import { baseCdnImage, timeAgo } from "helpers/index";
import { Avatar } from "presentational/atoms";
import { IConversation, IMessage } from "services/entities/chat";

type MessageBoxProps = {
  data: IConversation;
  isLastMessage: boolean;
};

export const MessageBody: React.FC<MessageBoxProps> = ({
  data,
  isLastMessage,
}) => {
  const { user } = useSession()
  const isOwn = data.sender.id === user?.id ;

  const container = `flex gap-2 p-2  ${isOwn ? "justify-end" : ""} `;
  const avatar = isOwn ? "order-2" : "";
  const body = `flex flex-col gap-2 shadow-md ${
    isOwn
      ? "items-end bg-blueShade-cyan rounded-2xl rounded-tr-md"
      : "rounded-2xl rounded-tl-md bg-gray-extraLight border-gray-dark"
  }`;
  const message = `
    "text-sm w-fit overflow-hidden",
    ${isOwn ? "text-white" : ""}
    ${data.image ? "rounded-md p-0" : "rounded-full py-2 px-3"}
  `;
  return (
    <div className={container}>
      <div className={avatar}>
        <Avatar link={baseCdnImage(data.sender.avatar)} size="xs" />
      </div>
      <div
        className={`flex flex-col ${isOwn ? "items-end" : "items-start"} gap-2`}
      >
        <div className={body}>
          {/* <div className="flex items-center gap-1 "> */}
          {/* <div className="text-sm text-gray-500">{data.sender.fullName}</div> */}
          {/* </div> */}
          <div className={message}>
            {data.image ? (
              // eslint-disable-next-line jsx-a11y/img-redundant-alt
              <img
                alt="Image"
                height="288"
                width="288"
                src={data.image}
                className="
                object-cover 
                cursor-pointer 
                hover:scale-110 
                transition 
                translate 
              "
              />
            ) : (
              <div className="max-w-60 text-sm break-words">
                {data.content}
              </div>
            )}
          </div>
        </div>
        {isLastMessage ? (
          <div className="text-xs text-gray-400">
            {timeAgo(data.timeReceived)}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

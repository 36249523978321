import { ACTIVE_CATEGORY_OPTIONS } from "constants/categoriesAddition";
import {
  Subtitle,
  VerticalSpacer,
  Paragraph,
  NormalSelect,
} from "presentational/atoms";
import { Control, Controller } from "react-hook-form";

type props = {
  control: Control<any>;
};

export const ActiveCategorySection = ({ control }: props) => {
  return (
    <div>
      <Subtitle color="black">Kích hoạt</Subtitle>
      <VerticalSpacer size="m" />
      <div className="border border-red-lightShade border-solid w-full rounded-md p-3">
        <Paragraph color="blueShade-mediumLightShade" fontWeight="semibold">
          Danh mục kích hoạt sau khi tạo
        </Paragraph>
        <VerticalSpacer size="s" />
        <Controller
          name="isActivated"
          control={control}
          render={({ field }) => (
            <NormalSelect
              options={ACTIVE_CATEGORY_OPTIONS}
              value={
                field.value !== undefined
                  ? field.value === true
                    ? "1"
                    : "0"
                  : undefined
              }
              onChange={(option: { label: string; value: string }) => {
                field.onChange(option.value === "1" ? true : false);
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

import { Tittle } from "presentational/atoms"
import { useGetHistoryPaymentQuery, useGetCustomerHistoryPaymentQuery } from "../business-layer/services/lib/apis";
import React, { useState } from "react";
import { LoadingItem, ErrorItem } from "presentational/organisms";
import { paymentHistoryType } from "services/lib/paymentHistory/type";
import { PaymentHistoryTable } from "presentational/molecules/table/PaymentHistoryTable";
import { FilterComplaintModal, FilterOrderModal, Pagination } from "presentational/molecules";
import { Text, Button, Flex, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { pagingType } from "services/lib/general/type";
import { format } from 'date-fns';
import DropDown from "presentational/atoms/dropdown/DropDown";

type typePaymentHistory = 'customer' | 'freelancer'

const today = new Date();
const threeDaysAgo = new Date(today).setDate(today.getDate() - 3);

export const HistoryPayment = () => {
    const [dataPaging, setDataPaging] = useState<Record<typePaymentHistory | 'main', pagingType>>();
    const [valueSearchByHandler, setValueSearchByHandler] = React.useState<string>('')
    const [startDate, setStartDate] = useState<string>(format(threeDaysAgo, 'yyyy-MM-dd'));
    const [endDate, setEndDate] = useState<string>(format(today, 'yyyy-MM-dd'));

    const [typePayment, setTypePayment] = React.useState<typePaymentHistory>('freelancer')
    const { data, isError, isFetching } = useGetHistoryPaymentQuery({
        page: typePayment === 'freelancer' ? dataPaging?.main.currentPage ?? 1 : 1,
        name: valueSearchByHandler,
        start: startDate,
        end: endDate
    })
    const { data: dataCustomer, isError: isErrorCustomer, isFetching: isFetchingCustomer } = useGetCustomerHistoryPaymentQuery({
        page: typePayment === 'customer' ? dataPaging?.main.currentPage ?? 1 : 1,
        name: valueSearchByHandler,
        start: startDate,
        end: endDate
    })
    const [paymentHistoryData, setPaymentHistoryData] = React.useState<paymentHistoryType[]>()
    const [paymentHistoryDataCustomer, setPaymentHistoryDataCustomer] = React.useState<paymentHistoryType[]>()

    React.useEffect(() => {
        if (!isError && data) {
            const { data: dataPayment, ...paginData } = data;
            setPaymentHistoryData(dataPayment);
            setDataPaging(prev => {
                if (!prev) {
                    return {
                        freelancer: paginData,
                        main: paginData,
                        customer: paginData
                    };
                }
                return {
                    ...prev,
                    main: typePayment === 'freelancer' ? paginData : prev.main,
                    freelancer: prev.freelancer ?? paginData
                };
            });
        }
    }, [data, isError, typePayment]);
    
    React.useEffect(() => {
        if (!isErrorCustomer && dataCustomer) {
            const { data: dataPayment, ...paginData } = dataCustomer;
            setPaymentHistoryDataCustomer(dataPayment);
            setDataPaging(prev => {
                if (!prev) {
                    return {
                        freelancer: paginData,
                        main: paginData,
                        customer: paginData
                    };
                }
                return {
                    ...prev,
                    main: typePayment === 'customer' ? paginData : prev.main,
                    customer: prev.customer ?? paginData
                };
            });
        }
    }, [dataCustomer, isErrorCustomer, typePayment]);
    


    // if (isFetching || isFetchingCustomer || !paymentHistoryData || !paymentHistoryData) {
    //     return <LoadingItem />;
    // }

    // if (isError || isErrorCustomer) {
    //     return <ErrorItem />;
    // }
    console.log({ dataPaging })

    return (
        <div className=" p-5 pb-10 max-h-[90vh] overflow-y-scroll overflow-x-hidden">
            <Flex justify="space-between" className="mt-10 mb-12">

                <Tittle align="left" color="black">
                    Lịch sử thanh toán
                </Tittle>

            </Flex>
            <Flex className="w-full justify-between mb-11">
                <InputGroup size="sm" w={300} bg="white">
                    <InputLeftElement
                        cursor="pointer"
                        children={<i className="fi fi-bs-search" />}
                        onClick={() => { }}
                    />

                    <Input
                        borderRadius={5}
                        type="text"
                        onKeyPress={() => { }}
                        value={valueSearchByHandler}
                        onChange={(e) => { setValueSearchByHandler(e.target.value) }}
                        placeholder="Tìm kiếm theo người thực hiện"
                        border="1px solid #949494"
                    />
                </InputGroup>
                <Flex alignItems={"center"} gap={2}>
                    <Input
                        placeholder="Select Date and Time"
                        size="sm"
                        type="date"
                        border="1px solid #949494"
                        borderRadius={5}
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <Text>Đến</Text>
                    <Input
                        placeholder="Select Date and Time"
                        size="sm"
                        type="date"
                        border="1px solid #949494"
                        borderRadius={5}
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                    {/* <Button
                        leftIcon={<i className="fi fi-ss-filter" />}
                        bg="white"
                        size="sm"
                        border="1px solid #949494"
                        ml={3}
                        minW={"fit-content"}
                        onClick={()=>{}}
                    >
                        Lọc
                    </Button>
                    <FilterComplaintModal
                        isOpen={false}
                        onClose={()=>{}}
                        setBasicFilter={() => { }}
                    /> */}
                    <div className="flex">
                        <DropDown
                            listItem={['Nhân viên', 'Khách hàng']}
                            itemSelect={typePayment === 'freelancer' ? 'Nhân viên' : 'Khách hàng'}
                            onChange={(typeSelected) => {
                                const typeSelectedSerialized = typeSelected === 'Nhân viên' ? 'freelancer' : 'customer' as typePaymentHistory
                                setTypePayment(typeSelectedSerialized as typePaymentHistory);
                                setDataPaging(prev => {
                                    if (prev) {
                                        return {
                                            ...prev,
                                            main: typeSelectedSerialized === 'customer' ? prev.customer : prev.freelancer
                                        };
                                    }
                                    return prev;
                                });
                            }}
                        />
                    </div>
                </Flex>
            </Flex>
            {
                (isFetching || isFetchingCustomer || !paymentHistoryData || !paymentHistoryData) ?
                    <LoadingItem />
                    :

                    (isError || isErrorCustomer) ? <ErrorItem /> : <PaymentHistoryTable paymentHistory={typePayment === 'freelancer' ? paymentHistoryData! : paymentHistoryDataCustomer!} paging={
                        <Flex justifyContent="center" mt={12}>
                            <Pagination
                                indexCurrentPage={dataPaging?.main.currentPage ?? 1}
                                onChangePage={(newPageNum) => {
                                    if (dataPaging) {
                                        setDataPaging(prev => {
                                            if (prev) {
                                                return {
                                                    ...prev,
                                                    main: {
                                                        ...prev.main,
                                                        currentPage: newPageNum
                                                    }
                                                };
                                            }
                                            return prev;
                                        });
                                    }
                                }}
                                numberPage={dataPaging?.main.totalPages ?? 1}
                            />
                        </Flex>
                    } />

            }

        </div>
    )
}
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useVerifyFreelancerMutation } from "../../../business-layer/services/lib/apis";
import { verifyFreelancerType } from "services/lib/verify/type";
import { LoadingItem } from "presentational/organisms";
import { toast } from "react-toastify";

type confirmVerifyModalProps = {
  verifyParam: verifyFreelancerType;
  isOpen: boolean;
  onClose: () => void;
  callback?: () => void;
};

export function ConfirmVerifyModal({
  verifyParam,
  isOpen,
  onClose,
  callback
}: confirmVerifyModalProps) {
  const [verifyFreelancer] = useVerifyFreelancerMutation();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const toast = useToast()
  const updateStatusSuccess = () => {
    toast({
      title: `${verifyParam.verified ? "Cấp phép" : "Từ chối"} thành công`,
      status: "success",
      isClosable: true,
      position: "top"
    })
  }
  const updateStatusFail = () => {
    toast({
      title: `${verifyParam.verified ? "Cấp phép" : "Từ chối"} thất bại`,
      status: "error",
      isClosable: true,
      position: "top"
    })
  }
  const onVerify = () => {
    setIsLoading(true);

    verifyFreelancer(verifyParam)
      .then((status) => {
        if (callback) {
          callback();
        }
        if (status.error) {
          updateStatusFail()
        } else {
          updateStatusSuccess()
        }
      })
      .catch(() => {
        updateStatusFail()
      })
      .finally(() => {
        setIsLoading(false);
        onClose();
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {isLoading ? (
          <LoadingItem />
        ) : (
          <>
            <ModalCloseButton />
            <ModalBody my={12}>
              <Text textAlign={"center"} fontSize={"larger"}>
                {" "}
                Bạn có chắc muốn {verifyParam.verified
                  ? "cấp phép"
                  : "từ chối"}{" "}
                freelancer này ?
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="gray" mr={3} onClick={onClose}>
                Đóng
              </Button>
              <Button
                colorScheme={verifyParam.verified ? "green" : "red"}
                onClick={onVerify}
              >
                {verifyParam.verified ? "cấp phép" : "từ chối"}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}

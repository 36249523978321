import { combineReducers } from 'redux'
import { detoiApi } from './adminApi'

const rootReducer = combineReducers({ 
    [detoiApi.reducerPath]: detoiApi.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer

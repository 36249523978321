type insightOverview = {
  [key: string]: insightItem;
  totalProfit: insightItem;
  // totalDiscountCost: insightItem;
  totalRevenue: insightItem;
  successOrderCount: insightItem;
  failedOrderCount: insightItem;
};

type insightItem = {
  icon: JSX.Element;
  backgroundIcon: "primary" | "black" | "rose" | "green-medium" | "yellow-dark";
  description: string;
  type: 'number' | 'currency',
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const INSIGHT_OVERVIEW: insightOverview = {
  totalProfit: {
    icon: <i className="fi fi-ss-expense-bill" />,
    backgroundIcon: "primary",
    description: "Tổng lợi nhuận",
    type: 'currency',
  },
  // totalDiscountCost: {
  //   icon: <i className="fi fi-br-percentage" />,
  //   backgroundIcon: "rose",
  //   description: "Tổng chi phí khuyến mãi",
  //   type: 'currency'
  // },
  totalRevenue: {
    icon: <i className="fi fi-ss-comments-dollar" />,
    backgroundIcon: "yellow-dark",
    description: "Doanh thu đơn dịch vụ",
    type: 'currency'
  },
  successOrderCount: {
    icon: <i className="fi fi-ss-flame" />,
    backgroundIcon: "green-medium",
    description: "Số đơn dịch vụ thành công",
    type: 'number'
  },
  failedOrderCount: {
    icon: <i className="fi fi-sr-phone-slash" />,
    backgroundIcon: "rose",
    description: "Số đơn thất bại",
    type: 'number'
  },
};

export const INSIGHT_FEEDBACK: { [key: string]: string } = {
  serviceRequestCount: "Yêu cầu dịch vụ mới",
  systemErrorCount: "Báo lỗi hệ thống",
  feedbackCount: "Góp ý"
};


export const INSIGHT_NEED_RESOLVE: { [key: string]: string } = {
  unresolvedReportCount: "Đơn khiếu nại chưa giải quyết",
  freelancerRequestCount: "Chưa cấp phép nhân viên mới",
};
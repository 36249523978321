
import axios from 'axios';
import {
  deleteFileFromBunnyCDNReturnType,
  uploadBunnyParamsType,
  uploadParamsType,
  uploadReturnType,
} from './type';
import { BUNNY_API_KEY, BUNNY_USER } from 'constants/apiKeys.constants';
import { Services } from '../../service';
import { BUNNY_CDN_API_CONFIG } from '../../../services/config/cdn';
import { sirvBaseUrlTemplate, connectSirvUrl, uploadImageUrl } from '../../../services/config/apis';


const sirvUser = process.env.NEXT_PUBLIC_SIRV_USER || '';
let sirvState = {
  options: {
    method: 'POST',
    url: sirvBaseUrlTemplate + '/token',
    headers: {
      'Content-Type': 'application/json',
    },
  },
  access_token: '',
  expiresIn: 0,
  scope: [],
  tokenExpirationTimestamp: 0,
};

const sirvClientId = process.env.EXPO_PUBLIC_SIRV_CLIEND_ID || '';
const sirvClientSecret = process.env.EXPO_PUBLIC_SIRV_CLIEND_SECRET || '';

export * from './type';
export class CDNService extends Services {
  handleReconnectToSirvIfNeeded = async () => {
    try {
      // Check if the token has expired
      if (Math.floor(Date.now() / 1000) >= sirvState.tokenExpirationTimestamp) {
        const response = await axios.post(connectSirvUrl, {
          clientId: sirvClientId,
          clientSecret: sirvClientSecret,
        });
        const apiResponse = response.data;

        sirvState = {
          ...sirvState,
          access_token: apiResponse.token,
          scope: apiResponse.scope,
          expiresIn: apiResponse.expiresIn,
          tokenExpirationTimestamp:
            Math.floor(Date.now() / 1000) + apiResponse.expiresIn,
        };
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };

  uploadFileToSirv = async ({
    fileBuffer,
    name,
    type,
  }: uploadParamsType): Promise<uploadReturnType> => {
    try {
      await this.handleReconnectToSirvIfNeeded();
      const response = await axios({
        method: 'POST',
        url: uploadImageUrl,
        params: { filename: name },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sirvState.access_token}`,
        },
        data: fileBuffer,
      });
      if (response.status >= 200 && response.status < 300) {
        throw new Error('Lỗi đăng tải ảnh');
      } else {
        return {
          path: `https://${sirvUser}.sirv.com${name}`,
          type,
        };
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };

  uploadFileToBunny = async ({
    fileBuffer,
    name,
    type,
  }: uploadBunnyParamsType): Promise<uploadReturnType> => {
    try {
      const BUNNY_ACCESS_KEY = BUNNY_API_KEY;

      await axios({
        method: 'PUT',
        url: `https://${BUNNY_CDN_API_CONFIG.HOSTNAME}/${BUNNY_CDN_API_CONFIG.STORAGE_ZONE_NAME}${name}`,
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        headers: {
          AccessKey: BUNNY_ACCESS_KEY,
          'Content-Type': 'application/octet-stream',
          Accept: 'application/json',
        },
        data: fileBuffer,
      });
      return {
        path: `https://${BUNNY_USER}.b-cdn.net${name}`,
        type,
      };
    } catch (error) {
      throw this.handleError(error);
    }
  };

  deleteFileFromBunny = async (
    pathName: string
  ): Promise<deleteFileFromBunnyCDNReturnType> => {
    try {
      const BUNNY_ACCESS_KEY = BUNNY_API_KEY;
      return await axios({
        method: 'DELETE',
        url: `https://${BUNNY_CDN_API_CONFIG.HOSTNAME}/${BUNNY_CDN_API_CONFIG.STORAGE_ZONE_NAME}/${pathName}`,
        headers: {
          AccessKey: BUNNY_ACCESS_KEY,
          Accept: 'application/json',
        },
      });
    } catch (error) {
      throw this.handleError(error);
    }
  };
}

import { ApiHelper, detoiApi } from "businessLogic/redux/adminApi";

type paymentGetHistoryPayload = {
    status?: string
    page?: number
    pageSize?: number
    name?: string,
    start?: string
    end?: string 
}

const api = detoiApi.injectEndpoints({
    endpoints: (builder) => ({
        getHistoryPayment: builder.query({
            query: ({ status, page, pageSize, name, start, end }: paymentGetHistoryPayload) => ApiHelper.get(`/api/v1/admin/payment/history`, {
                params: {
                    status,
                    page,
                    pageSize,
                    name,
                    start,
                    end
                }
            }),
        }),
        getCustomerHistoryPayment: builder.query({
            query: ({ status, page, pageSize, name, start, end }: paymentGetHistoryPayload) => ApiHelper.get(`/api/v1/admin/payment/customer-history`, {
                params: {
                    status,
                    page,
                    pageSize,
                    name,
                    start,
                    end
                }
            }),
        }),
    }),
});

export const {
    useGetHistoryPaymentQuery,
    useGetCustomerHistoryPaymentQuery,
    endpoints: { getHistoryPayment, getCustomerHistoryPayment }
} = api;
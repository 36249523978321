import { IMessagePreview } from "services/entities/chat";
import { ConversationBox } from "./ConversationBox";
import { Paragraph } from "presentational/atoms";
import { useEffect } from "react";

interface ConversationListProps {
  messagePreview: IMessagePreview[];
}

const ConversationList: React.FC<ConversationListProps> = ({
  messagePreview,
}) => {
  return (
    <>
      <aside className="overflow-y-auto">
        <div className=" max-h-[90vh]">
          <div className="p-3 flex justify-between pt-4">
            <Paragraph fontWeight="medium">Tất cả tin nhắn</Paragraph>
          </div>
          {messagePreview.map((item) => (
            <ConversationBox key={item.conversationId} data={item} />
          ))}
        </div>
      </aside>
    </>
  );
};

export default ConversationList;

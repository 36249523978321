import { Box } from '@chakra-ui/react'
import { COMPLAINT_THEME } from 'pages/Complaint'
import React from 'react'
import { complainStatusType } from 'services/lib/feedbackService/type'

type boxComplaintStatusProps = {
    currComplainStatus: complainStatusType
}

export function BoxComplaintStatus(props: boxComplaintStatusProps) {
    const { currComplainStatus } = props;
    return (
        <Box
            bg={COMPLAINT_THEME[currComplainStatus].background}
            color={COMPLAINT_THEME[currComplainStatus].color}
            fontWeight="medium"
            borderRadius="full"
            textAlign="center"
            py={1}
            px={3}
            maxW={"fit-content"}
            className="text-xs"
        >
            {
                currComplainStatus === 'Pending'
                    ? "Cần giải quyết" :
                    currComplainStatus === 'Rejected' ? 'Đã từ chối'
                        : "Đã giải quyết"
            }
        </Box>
    )
}
type dividerProps = {
  color: string;
  direction: "horizontal" | "vertical";
};

export const Divider = ({
  direction,
  color,
}: dividerProps) => {
  return (
    <div
      className={`${
        direction === "horizontal" ? "w-full border-t" : "h-full border-l"
      } border-${color} `}
    ></div>
  );
};

import { ReactNode, useEffect, useRef } from "react";
import { MenuBody } from "./MenuBody";
import { MenuButton } from "./MenuButton";

type menuProps = {
  onToggle: () => void;
  isOpen: boolean;
  menuButtonContent: ReactNode;
  menuBodyContent: ReactNode;
};
export const Menu: React.FC<menuProps> = ({
  onToggle,
  isOpen,
  menuButtonContent,
  menuBodyContent,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        isOpen
      ) {
        onToggle();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onToggle]);

  return (
    <div className="relative inline-block text-left" ref={menuRef}>
      <MenuButton onToggle={onToggle}>{menuButtonContent}</MenuButton>
      <MenuBody isOpen={isOpen}>{menuBodyContent}</MenuBody>
    </div>
  );
};

import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import {
  ICustomerAccountDetail,
  IFreelancerAccountDetail,
} from "business-layer/services/entities";
import {
  Pagination,
  TableCustomerBody,
  TableFreelancerBody,
  TableUserHead,
} from "presentational/molecules";
import DropDown from "presentational/atoms/dropdown/DropDown";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import {
  useGetListCustomerQuery,
  useGetListFreelancerQuery,
  useBanAccountMutation,
  useUnbanAccountMutation,
} from "../business-layer/services/lib/apis";
import { LoadingItem, ErrorItem } from "presentational/organisms";
import { ToastContainer, toast } from "react-toastify";
import { useDisclosure } from "@chakra-ui/react";
import { ConfirmBanModal } from "presentational/molecules/modal/ConfirmBanModal";
import { pagingType } from "services/lib/general/type";

export enum userType {
  customer = "Khách Hàng",
  freelancer = "Nhân viên",
}


function UsersManagement() {
  const [currentIndexPage, setCurrentIndexPage] = useState<number>(0);
  const navigate = useNavigate();
  const [banAccount] = useBanAccountMutation();
  const [unbanAccount] = useUnbanAccountMutation();
  const [searchValue, setSearchValue] = useState("");
  const [accountTypeSelected, setAccounTypeSelected] = useState(
    userType.customer
  );

  const [listCustomer, setListCustomer] = useState<
    pagingType & { data: ICustomerAccountDetail[] }
  >();
  const [listFreelancer, setListFreelancer] = useState<
    pagingType & { data: IFreelancerAccountDetail[] }
  >();
  const [userIdBanning, setUserIdBanning] = useState<string>("");
  const [dataPaging, setDataPaging] = useState<Record<'customer' | 'freelancer' | 'main', pagingType>>({
    customer: {
      currentPage: 1,
      hasNext: false,
      hasPrevious: false,
      pageSize: 1,
      totalCount: 1,
      totalPages: 1
    },
    freelancer: {
      currentPage: 1,
      hasNext: false,
      hasPrevious: false,
      pageSize: 1,
      totalCount: 1,
      totalPages: 1
    },
    main: {
      currentPage: 1,
      hasNext: false,
      hasPrevious: false,
      pageSize: 1,
      totalCount: 1,
      totalPages: 1
    }
  });

  const {
    data: dataListCustomer,
    isFetching: isFetchingCustomer,
    isError: isErrorCustoner,
  } = useGetListCustomerQuery({
    page: accountTypeSelected === userType.customer ? dataPaging?.main.currentPage ?? 1 : 1,
  });
  const {
    data: dataListFreelancer,
    isFetching: isFetchingFreelancer,
    isError: isErrorFreelancer,
  } = useGetListFreelancerQuery({
    page: accountTypeSelected === userType.freelancer ? dataPaging?.main.currentPage ?? 1 : 1,
  });

  useEffect(() => {
    if (!isErrorCustoner) {
      if (dataListCustomer) {
        setListCustomer(dataListCustomer);
        const { data: dataPayment, ...paginData } = dataListCustomer;

        setDataPaging(prev => {
          if (prev) {
            return {
              ...prev,
              main: accountTypeSelected === userType.customer ? paginData : prev.main,
              customer: prev.customer ?? paginData
            };
          }
          return prev;
        });
      }
    }
  }, [isErrorCustoner, dataListCustomer]);

  useEffect(() => {
    if (!isErrorFreelancer) {
      setListFreelancer(dataListFreelancer);
      if (!dataListFreelancer) return;
      const { data: dataPayment, ...paginData } = dataListFreelancer;

      setDataPaging(prev => {
        if (prev) {
          return {
            ...prev,
            main: accountTypeSelected === userType.freelancer ? paginData : prev.main,
            customer: prev.customer ?? paginData
          };
        }
        return prev;
      });
    }
  }, [isErrorFreelancer, dataListFreelancer]);

  //CHECK BOX
  const [checkedState, setCheckedState] = useState<boolean[]>(
    new Array(listCustomer?.data?.length ?? 0).fill(false)
  );

  const visibleAccountListCurrent = useMemo(() => {
    if (accountTypeSelected === userType.freelancer) {
      return listFreelancer?.data.filter((account) => {
        const phone = account?.account?.phone;
        return (
          phone &&
          phone.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
        );
      });
    } else {
      return listCustomer?.data?.filter((account) => {
        const phone = account?.account.phone;
        return (
          phone &&
          phone.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
        );
      });
    }
  }, [searchValue, listFreelancer, listCustomer, accountTypeSelected]);
  //Handle CheckBox
  const onChangeCheckbox = (position: number) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };

  const handleSelectAll = (checked: boolean) => {
    let updatedCheckedState = null;
    if (accountTypeSelected === userType.freelancer)
      updatedCheckedState = new Array(listFreelancer?.data.length).fill(
        checked
      );
    else
      updatedCheckedState = new Array(listCustomer?.data.length).fill(checked);

    setCheckedState(updatedCheckedState);
  };

  const onChangeActive = (isActive: boolean) => {
    if (accountTypeSelected === userType.customer) {
      setListCustomer((prevListCustomer) => {
        if (!prevListCustomer) return prevListCustomer;
        return {
          ...prevListCustomer,
          data: !prevListCustomer
            ? prevListCustomer
            : prevListCustomer.data.map((customer) => {
              if (customer.account.id === userIdBanning) {
                return {
                  ...customer,
                  account: {
                    ...customer.account,
                    isActive: isActive,
                  },
                };
              }
              return customer;
            }),
        };
      });
    } else {
      if (accountTypeSelected === userType.freelancer) {
        setListFreelancer((prevListCustomer) => {
          if (!prevListCustomer) return prevListCustomer;
          return {
            ...prevListCustomer,
            data: !prevListCustomer
              ? prevListCustomer
              : prevListCustomer.data.map((customer) => {
                if (customer.account.id === userIdBanning) {
                  return {
                    ...customer,
                    account: {
                      ...customer.account,
                      isActive: isActive,
                    },
                  };
                }
                return customer;
              }),
          };
        });
      }
    }
  };
  console.log({ dataPaging })
  const handleUnbanAccount = (id: string) => {
    unbanAccount(id)
      .then(() => {
        toast.success("Mở tài khoản thành công");
        onChangeActive(true);
      })
      .catch(() => {
        toast.error("Mở tài khoản thất bại");
      });
  };

  const handleBanAccount = () => {
    banAccount(userIdBanning)
      .then(() => {
        toast.success("Khoá tài khoản thành công");
        onChangeActive(false);
      })
      .catch(() => {
        toast.error("Khoá tài khoản thất bại");
      });
  };

  const {
    onOpen: onOpenConfirmBan,
    onClose: onCloseConfirmBan,
    isOpen: isOpenConfirmBan,
  } = useDisclosure();

  const goToUserDetail = (id: string, isGoToFreelancer: boolean) => {
    if (isGoToFreelancer) {
      navigate(`${PAGES.USER_MANAGEMENT}\\freelancer\\${id}`);
    } else {
      navigate(`${PAGES.USER_MANAGEMENT}\\customer\\${id}`);
    }
  };

  if (isFetchingCustomer || isFetchingFreelancer) {
    return <LoadingItem />;
  }

  if (isErrorFreelancer || isErrorCustoner) {
    return <ErrorItem />;
  }

  return (
    <main className="w-full p-10">
      <ToastContainer />
      <ConfirmBanModal
        callBack={handleBanAccount}
        isOpen={isOpenConfirmBan}
        onClose={onCloseConfirmBan}
      />
      <section className="py-2 px-4 shadow-md border border-silver flex justify-between items-center bg-white rounded-md">
        <div className="flex-1">
          <div className="w-3/5 relative">
            <input
              name="searchBox"
              type="text"
              className="pl-6 pr-12 py-2 w-full text-s bg-silver rounded-full placeholder-gray outline-none"
              placeholder="Tìm kiếm theo số điện thoại..."
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <i className="fi fi-br-search absolute top-1/2 transform -translate-y-1/2 right-4 text-gray-medium" />
          </div>
        </div>
        <div className="flex">
          <DropDown
            listItem={[userType.customer, userType.freelancer]}
            itemSelect={accountTypeSelected}
            onChange={(typeSelected) => {
              setAccounTypeSelected(typeSelected as userType);

              setDataPaging(prev => {
                if (prev) {
                  return {
                    ...prev,
                    main: typeSelected === userType.freelancer ? prev.freelancer : prev.customer
                  };
                }
                return prev;
              });
            }}
          />
        </div>
      </section>

      <section className="shadow-sm  overflow-x-auto overflow-y-hidden border border-silver mt-8 rounded-md">
        <table className="w-full text-sm text-center bg-white rounded-md">
          <TableUserHead
            userSelected={accountTypeSelected}
            checkedState={checkedState}
            handleSelectAll={handleSelectAll}
          />
          <tbody>
            {visibleAccountListCurrent?.map((account, index) => {
              if (accountTypeSelected === userType.freelancer) {
                return (
                  // Nếu là Freelancer
                  <TableFreelancerBody
                    key={`account@${account.account.id}`}
                    account={account as IFreelancerAccountDetail}
                    goToUserDetail={() =>
                      goToUserDetail(account.account.id, true)
                    }
                    checked={checkedState[index]}
                    onChangeCheckbox={() => onChangeCheckbox(index)}
                    onOpenConfirmBan={onOpenConfirmBan}
                    setUserIdBanning={() =>
                      setUserIdBanning(account.account.id)
                    }
                    handleUnbanAccount={handleUnbanAccount}
                  />
                );
              } else {
                // Nếu là Customer
                return (
                  <TableCustomerBody
                    key={`account@${account.account.id}`}
                    account={account as ICustomerAccountDetail}
                    goToUserDetail={() =>
                      goToUserDetail(account.account.id, false)
                    }
                    checked={checkedState[index]}
                    onChangeCheckbox={() => onChangeCheckbox(index)}
                    onOpenConfirmBan={onOpenConfirmBan}
                    setUserIdBanning={() =>
                      setUserIdBanning(account.account.id)
                    }
                    handleUnbanAccount={handleUnbanAccount}
                  />
                );
              }
            })}
          </tbody>
        </table>
      </section>

      <Pagination
        indexCurrentPage={dataPaging?.main.currentPage ?? 1}
        onChangePage={(newPageNum) => {
          if (dataPaging) {
            setDataPaging(prev => {
              if (prev) {
                return {
                  ...prev,
                  main: {
                    ...prev.main,
                    currentPage: newPageNum
                  }
                };
              }
              return prev;
            });
          }
        }}
        numberPage={dataPaging?.main.totalPages ?? 1}
      />
    </main>
  );
}

export default UsersManagement;

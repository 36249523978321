import React from "react";
import { Avatar, Paragraph, Subtitle } from "presentational/atoms";
import { Skeleton } from "@chakra-ui/react";
import { baseCdnImage } from "helpers/baseCdnImage";

type userCardProps = {
  avatarUrl?: string;
  userName: string;
}

export const UserCard: React.FC<userCardProps> = ({ avatarUrl, userName }) => {
  return (
    <div className="flex items-center gap-4">
    {avatarUrl ?  <Avatar link={baseCdnImage(avatarUrl)} />: <Skeleton w={"2.5rem"} h={"2.5rem"}/>}
      <div className="font-medium flex flex-col items-start">
        <Subtitle color="black">{userName}</Subtitle>
        <Paragraph color="black">Quản trị viên</Paragraph>
      </div>
    </div>
  );
};


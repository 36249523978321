import { VStack, Tr, Td, Flex, HStack, Text } from "@chakra-ui/react";
import { NUMBER_ITEMS_IN_PAGE } from "constants/serviceManagement";
import { formatCurrencyVND } from "helpers/MoneyConverter";
import { mockOrderDetails } from "pages/ServiceOrder";
import { Pagination } from "presentational/molecules";
import CustomTable from "presentational/molecules/table/CustomTable";
import React, { useState } from "react";
import { ICustomerAccountDetail } from "services/entities";
import { convertIsoToReadable } from "../freelancerDetailSection";

type customerOrderedSectionProps = {
  customerDetail: ICustomerAccountDetail;
};

export const CustomerOrderedSection = React.memo(({ customerDetail }: customerOrderedSectionProps) => {
  const [currentIndexPage, setCurrentIndexPage] = useState<number>(0);
  const numberPages = Math.ceil(mockOrderDetails.length / NUMBER_ITEMS_IN_PAGE);
  return (
    <VStack
      className="bg-silver border border-solid shadow-md"
      borderRadius={5}
      p={3}
      gap={5}
      alignItems={"flex-start"}
    >
      <HStack>
        <i className="fi fi-ss-money-bill-wave" />
        <Text>Lịch sử giao dịch</Text>
      </HStack>
      <CustomTable
        rows={Array.isArray(customerDetail.paymentHistory) ? customerDetail.paymentHistory.map((payment) => {
          return (
            <Tr className="hover:bg-blueShade-lightShade cursor-pointer">
              <Td border="none" className="text-sm" textAlign="center">
                {payment.paymentType === "Add" ? "Nạp tiền" : "Rút tiền"}
              </Td>

              <Td
                border="none"
                isTruncated
                className="text-sm"
                textAlign="center"
              >
                {payment.method}
              </Td>
              <Td
                border="none"
                isTruncated
                className="text-sm"
                textAlign="center"
              >
                {formatCurrencyVND(payment.value)}
              </Td>
              <Td
                border="none"
                isTruncated
                className="text-sm"
                textAlign="center"
              >
                {convertIsoToReadable(payment.timestamp)}
              </Td>
            </Tr>
          );
        }) : []}
        title={[
          "Loại thanh toán",
          "Phương thức thanh toán",
          "Số tiền thanh toán",
          "Thời gian"
        ]}
        paging={
          <Flex justifyContent="center" mt={12}>
            <Pagination
              indexCurrentPage={currentIndexPage}
              onChangePage={setCurrentIndexPage}
              numberPage={numberPages}
            />
          </Flex>
        }
      />
    </VStack>
  );
});

import { useManageServiceRequirements } from "businessLogic/context";
import { AVAILABLE_FA_ICON } from "constants/FAIcon";
import {
  TYPE_SERVICES_ADDITION,
  serviceRequirementOptions,
} from "constants/servicesAddition";
import {
  BaseBtn,
  BorderBottomInput,
  Divider,
  IconSelect,
  NormalInput,
  NormalSwitch,
  Paragraph,
  TypeServiceSelect,
  VerticalSpacer,
} from "presentational/atoms";
import { DraggableInputGroup } from "presentational/molecules";
import { useMemo } from "react";
import { IUIServiceRequirement } from "services/entities/index";

type servicesDropdownCreationProps = {
  curretnServiceRequirement: IUIServiceRequirement;
  errors: string[];
};

export const ServicesDropdownCreation = ({
  curretnServiceRequirement,
  errors,
}: servicesDropdownCreationProps) => {
  const { id, label, placeholder, inputMethod, labelIcon } = curretnServiceRequirement;
  const { onChangeValue, onChangeType, onDuplicate, onRemove } =
    useManageServiceRequirements();
  const typeSelected: serviceRequirementOptions = useMemo(() => {
    return (
      TYPE_SERVICES_ADDITION.find(
        (service) => service.value === inputMethod.method.name
      ) ?? TYPE_SERVICES_ADDITION[0]
    );
  }, [inputMethod]);

  const isEnableAddOptions = useMemo(() => {
    return (
      inputMethod.method.name === 'select' &&
      inputMethod.method.options.every(
        (item) => item.name?.trim().length && item.description?.trim().length
      )
    );
  }, [inputMethod]);
  if(inputMethod.method.name !== 'select') return null;
  return (
    <div>
      <div className="flex gap-20 justify-between">
        <div className="w-1/3">
          <BorderBottomInput
            value={label}
            onChange={(value) => {
              onChangeValue({
                type: "dropdown-update-label",
                serviceRequirementId: id,
                newLabel: value,
              });
            }}
            placeholder="Nhập tiêu đề"
          />
        </div>
        <div className="flex items-center">
          <TypeServiceSelect
            typeServiceSelected={typeSelected}
            onChangeType={onChangeType}
            idServiceRequirement={id}
          />
        </div>
      </div>

      <VerticalSpacer size="xxl" />

      <div className="flex gap-24 w-full">
        <div className="flex gap-4 items-center">
          <Paragraph color="blueShade-mediumLightShade" fontWeight="semibold">
            Icon
          </Paragraph>
          <IconSelect
            options={AVAILABLE_FA_ICON}
            value={labelIcon ?? undefined}
            onChange={(name) => {
              onChangeValue({
                type: "dropdown-update-labelIcon",
                serviceRequirementId: id,
                newLabelIcon: name,
              });
            }}
          />
        </div>
        <div className="gap-4 items-center flex flex-1">
          <Paragraph color="blueShade-mediumLightShade" fontWeight="semibold">
            Placeholder
          </Paragraph>
          <NormalInput
            value={placeholder}
            onChange={(value) => {
              onChangeValue({
                type: "dropdown-update-placeholder",
                serviceRequirementId: id,
                newPlaceholder: value,
              });
            }}
            placeholder="Nhập placeholder"
          />
        </div>
      </div>

      <VerticalSpacer size="xxl" />
      <div>
        <Paragraph
          color="blueShade-mediumLightShade"
          align="left"
          fontWeight="semibold"
        >
          Options
        </Paragraph>
        <DraggableInputGroup
          options={inputMethod.method.options}
          serviceRequirementId={id}
          isEnableAddOptions={isEnableAddOptions}
        />
      </div>
      <VerticalSpacer size="xxl" />
      <Divider color="red-lightShade" direction="horizontal" />
      <VerticalSpacer size="xxl" />
      <div className="flex justify-between">
        <div>
          <div className={`${errors.length > 0 ? "block mt-1" : "hidden"}`}>
            <Paragraph color="rose" size="xs" fontWeight="semibold">
              Lỗi: {errors.join(", ")}
            </Paragraph>
          </div>
        </div>
        <div className="flex gap-5 items-center justify-end">
          <BaseBtn
            title=""
            onPress={() => {
              onDuplicate(id);
            }}
            icon={<i className="fi fi-rr-duplicate text-lg" />}
          />
          <BaseBtn
            title=""
            onPress={() => {
              onRemove(id);
            }}
            icon={<i className="fi fi-ss-trash text-lg" />}
          />
          <div className="h-5">
            <Divider color="black" direction="vertical" />
          </div>
          <Paragraph fontWeight="semibold">Validate</Paragraph>
          <NormalSwitch isValidate={false} setIsValidate={() => {}} />
        </div>
      </div>
    </div>
  );
};

import { useEffect, useRef, useState } from "react";

type dropDownProps = {
  listItem: string[];
  itemSelect: string;
  onChange: (selectedItem: string) => void;
};

const DropDown = ({ listItem, itemSelect, onChange }: dropDownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  const handleSelect = (selectedItem: string) => {
    onChange(selectedItem);
    setIsOpen(false);
  };

  const containerClasses = [
    "w-full py-2 flex items-center justify-center rounded-full",
    itemSelect === "Nhân viên" ? "bg-green-dark" : "bg-primary",
  ]
    .join(" ")
    .trim();

  return (
    <div className="relative w-40" ref={ref}>
      <button className={containerClasses} onClick={() => setIsOpen(!isOpen)}>
        <p className="mr-2 text-lg text-white font-medium">{itemSelect}</p>
        <i className="fi fi-rr-angle-small-down text-white w-5 h-5" />
      </button>
      {isOpen && (
        <div className="absolute top-14 right-0 w-48 shadow-md rounded bg-white border border-blueberry text-base">
          <ul>
            {listItem.map((item, index) => {
              const isLastItem = index === listItem.length - 1;
              const isFirstItem = index === 0;

              const itemClasses = [
                "py-2 px-6 cursor-pointer hover:bg-softBlueberry border-b border-blueberry",
                isLastItem ? "rounded-b border-none" : "",
                isFirstItem ? "rounded-t" : "",
              ]
                .join(" ")
                .trim();

              return (
                <li
                  key={index}
                  className={itemClasses}
                  onClick={() => handleSelect(item)}
                >
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropDown;

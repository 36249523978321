import ChatHeader from "presentational/organisms/chatSection/ChatHeader";
import ChatBody from "presentational/organisms/chatSection/ChatBody";
import { MessageForm } from "presentational/organisms/chatSection/MessageForm";
import ConversationList from "presentational/organisms/chatSection/ConversationList";
import { useChatManagement } from "businessLogic/context/chatManagement";
 import { useEffect, useState } from "react";
import { useRealtimeContext, useSocketConnect } from "businessLogic/context/SocketContext";
import { IMessage } from "services/entities/chat";
import { useChat } from "businessLogic/context/useChat";

export const UserChat = () => {
  const { messagePreview, conversation, anotherUserId, currentConversationId, onChangeConversation } = useChatManagement();
  // const { handleConnect: connectToRealtimeHub } = useSocketConnect();
  // useEffect(()=>{
  //   connectToRealtimeHub();
  // }, []);

  // const [messages, setMessages] = useState<IMessage[]>([]);
  // const { onChat } = useChat((message) => {
  //   setMessages(prevMessages => [...prevMessages, message]);
  // });

  useEffect(() => {
    if (messagePreview.length > 0 && !currentConversationId) {
      onChangeConversation(messagePreview[0].conversationId);
    }
  }, [messagePreview, currentConversationId, onChangeConversation]);
  return (
    <div className="h-full flex w-full">
      <div className="w-1/4 h-full border-r-red-lightShade border-r">
        <ConversationList messagePreview={messagePreview} />
      </div>
      <div className="h-full flex flex-col w-3/4">
        <ChatHeader user={messagePreview.find(message => message.conversationId === currentConversationId)?.sender}/>
        <ChatBody conversation={conversation ?? []}/>
        <MessageForm sendTo={anotherUserId}/>
      </div>
    </div>
  );
};

export default UserChat;
import { IMessagePreview } from "services/entities/chat";
import { Avatar, Paragraph } from "presentational/atoms";
import { useChatManagement } from "businessLogic/context/chatManagement";
import { baseCdnImage, timeAgo } from "helpers/index";

interface ConversationBoxProps {
  data: IMessagePreview;
  selected?: boolean;
}

export const ConversationBox: React.FC<ConversationBoxProps> = ({ data }) => {
  const { onChangeConversation } = useChatManagement();
  const userInfo = {
    id: "user2",
    fullName: "Bob Smith",
    avatar:
      "https://img.freepik.com/premium-photo/yellow-blue-design-with-sun-it_577115-10285.jpg",
    type: 1,
  };
  return (
    <div
      onClick={() => {
        onChangeConversation(data.conversationId);
      }}
      className="w-full flex items-center gap-2 p-3 transition cursor-pointer hover:bg-platinum"
    >
      {/* // ${selected ? "bg-neutral-100" : "bg-white"} */}

      <Avatar link={baseCdnImage(data.sender.avatar)} />

      <div className="min-w-0 flex-1">
        <div className="focus:outline-none">
          {/* <span className="absolute inset-0" aria-hidden="true" /> */}
          <div className="flex justify-start items-center mb-1">
            <Paragraph size="sm" fontWeight="medium">
              {data.sender.fullName}
            </Paragraph>
          </div>
          <div className="flex justify-between max-w-full">
            <div className="max-w-24">
              <Paragraph
                size="xs"
                truncate={true}
                //   hasSeen ? "text-gray-500" : "text-black font-medium"
              >
                {/* {data.sender.id === userInfo.id
                  ? "Bạn"
                  : data.sender.fullName.split(" ")[0]}{" "} */}
                 {data.latestMessage.content}
              </Paragraph>
            </div>
            <div className="min-w-20">
              <Paragraph size="xs" isNoWrap={true}>
                {timeAgo(data.latestMessage.timeReceived)}
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

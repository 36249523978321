import React from 'react';
import { useDisclosure, Modal, ModalOverlay, ModalContent, Text, ModalCloseButton, ModalBody, Box, ModalFooter, Button, Center, Textarea, useToast } from '@chakra-ui/react';
import { orderStatusType, SERVICES_STATUS } from 'constants/serviceOrder';
import BoxOrderStatus from 'presentational/atoms/box/BoxOrderStatus';
import { useUpdateOrderStatusMutation } from '../../../business-layer/services/lib/apis/useOrder';
import { toast } from 'react-toastify';

type updateStatusOrderProps = {
    currentOrderStatus: orderStatusType
    refetch: () => void
    orderId: string
}

export const UpdateStatusOrderModal = ({ currentOrderStatus, orderId, refetch }: updateStatusOrderProps) => {
    // const [setRoleAdmin] = useSetRoleAdminMutation();
    const [updateCategory] = useUpdateOrderStatusMutation()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast()
    const [newStatus, setNewStatus] = React.useState<orderStatusType>()
    const updateStatusSuccess = () => {
        toast({
            title: "Hủy đơn thành công",
            status: "success",
            isClosable: true,
            position: "top"
        })
    }
    const updateStatusFail = () => {
        toast({
            title: "Hủy đơn thất bại",
            status: "error",
            isClosable: true,
            position: "top"
        })
    }
    return (
        <React.Fragment>
            <span className="cursor-pointer" onClick={() => {
                if (currentOrderStatus?.Id === "8a9f22f1-3c67-49f7-bd84-ec290e4a37fd"
                    || currentOrderStatus?.Id === "a53e9887-2186-4ff8-a009-f7706c800b52"
                    || currentOrderStatus?.Id === "a888efc3-1d7b-445a-b38c-758737b67bad")
                    onOpen()
            }}>
                <BoxOrderStatus currStatus={currentOrderStatus} />
            </span>
            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={() => { onClose(); setNewStatus(undefined) }}>
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalHeader>Bạn có muốn hủy đơn không?</ModalHeader> */}
                    <ModalCloseButton />
                    <ModalBody className="flex flex-wrap gap-4">
                        {/* {
                            SERVICES_STATUS.filter(status => status.Id !== currentOrderStatus.Id).map((orderStatus, index) =>
                                <Box className={` rounded-md cursor-pointer ${newStatus?.Id === orderStatus.Id ? 'border-blue border-2' : 'border-white border-2'}`} onClick={() => setNewStatus(orderStatus)} key={orderStatus.Id}><BoxOrderStatus currStatus={orderStatus} /></Box>
                            )

                        } */}
                        <Text className=" text-center text-3xl font-thin">
                            Bạn có muốn hủy đơn không?
                        </Text>
                        <Textarea
                            placeholder='Nhập lí do hủy đơn'
                            size='xl'
                            className="mt-4 p-2"
                        />
                    </ModalBody>

                    <ModalFooter>
                        {/* <Button isDisabled={!newStatus} colorScheme='blue' onClick={() => {
                            if (!newStatus) {
                                onClose()
                                return;
                            }
                            updateCategory({
                                orderId,
                                statusId: newStatus.Id
                            }).then(() => {
                                onClose();
                                refetch();
                            })

                        }}>Cập nhật</Button> */}
                        <Center gap={5} className="w-full">
                            <Button onClick={onClose} bg="gray" color="white">
                                Đóng
                            </Button>
                            <Button
                                onClick={() => {
                                    let isFail = false;
                                    updateCategory({
                                        orderId,
                                        statusId: SERVICES_STATUS[SERVICES_STATUS.length - 1].Id
                                    }).then((status) => {
                                        if (status.error) {
                                            isFail = true
                                            updateStatusFail()
                                        } else {
                                            updateStatusSuccess()
                                        }
                                    })
                                        .catch(() => {
                                            isFail = true
                                            updateStatusFail()
                                        })
                                        .finally(() => {
                                            onClose();
                                            if (isFail === false)
                                                refetch();
                                        })
                                }}
                                bg="#E11D48"
                                color="white"
                            >
                                Hủy
                            </Button>
                        </Center>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </React.Fragment>
    )
}
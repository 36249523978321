import { Center } from '@chakra-ui/react';
import { orderStatusType } from 'constants/serviceOrder';
import React from 'react';


const BoxOrderStatus = (props: {currStatus: orderStatusType}) => {
    const { currStatus } = props;
    return (
        <Center
            bg={currStatus?.backgroundColor}
            color={currStatus?.textColor}
            fontWeight="medium"
            borderRadius={5}
            px={5}
            py={2}
            className="text-xs"
        >
            {currStatus?.Name ?? ""}
        </Center>
    );
}

export default BoxOrderStatus;
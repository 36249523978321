export const PAGES = {
	BASE: "/",
	LOGIN: "/login",
	DASHBOARD: "/dashboard",
	OVERVIEW: "/dashboard/overview",
	USER_MANAGEMENT: "/dashboard/users-management",
	USER_LICENSES: "/dashboard/users-licenses",
    USER_CHAT: "/dashboard/users-chat",
	SERVICE_ORDER: "/dashboard/service-order",
	COMPLAINT: "/dashboard/complaint",
	SERVICE_MANAGEMENT: "/dashboard/services-management",
	CATEGORIES_ADDITION: "/dashboard/services-management/categories-addition",
	CATEGORIES_EDITING: "/dashboard/services-management/categories-editing",
	SERVICES_ADDITION: "/dashboard/services-management/services-addition",
	SERVICES_EDITING: "/dashboard/services-management/services-editing",
	ADMIN_PERMISSION: "/dashboard/admin-permission",
	HISTORY_PAYMENT: "/dashboard/history",
};

import React from 'react';
import PropTypes from 'prop-types';
import {
    useDisclosure, Modal, ModalOverlay, ModalContent, Flex, ModalHeader, ModalCloseButton,
    ModalBody, Box, ModalFooter, Button, Textarea, Text, Center, Spinner,
    useRadio,
    UseRadioProps,
    HStack,
    useRadioGroup
} from '@chakra-ui/react';
import { useBanAccountMutation, useResolveComplaintMutation } from '../../../business-layer/services/lib/apis';
import { complainStatusType } from 'services/lib/feedbackService/type';
import { BoxComplaintStatus } from 'presentational/atoms/box/BoxComplaintStatus';
import { group } from 'console';

type UpdateStatusOrderProps = {
    currentComplaintStatus: complainStatusType;
    refetch: () => void;
    complainId: string;
    freelancerId: string;
};

type ActionUpdateComplaint = {
    refund: boolean;
    ban: boolean;
    mark: boolean;
    // denyFromCustomer: boolean;
};

const descriptionActionUpdateComplaint = {
    refund: 'Hoàn tiền cho khách hàng của đơn',
    ban: 'Cấm người bị phàn nàn',
    mark: 'Đánh gậy người bị phàn nàn',
    denyFromCustomer: 'Từ chối yêu cầu khiếu nại'
};

const action: ActionUpdateComplaint = {
    ban: false,
    mark: false,
    refund: false
}

export const UpdateStatusComplaintModal = ({
    currentComplaintStatus,
    complainId,
    refetch,
    freelancerId
}: UpdateStatusOrderProps) => {
    const [banAccount] = useBanAccountMutation();
    const [resolveComplaint] = useResolveComplaintMutation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [description, setDescription] = React.useState<string>('');
    const [itemSelected, setItemSelected] = React.useState<Record<keyof ActionUpdateComplaint, boolean>>({
        ban: false,
        mark: false,
        refund: false,
    })
    React.useEffect(() => {
        (async () => {
            if (!isLoading) return;
            try {
                // if (action.ban) {
                //     await banAccount(freelancerId);
                // }
                const serializeResolveAction = Object.entries(itemSelected).filter(([key, value]) => value).map(([key, val]) => key)
                console.log(serializeResolveAction.join(","))
                await resolveComplaint({
                    complainId,
                    resolvingDescription: description,
                    resolvingAction: serializeResolveAction.join(",")

                });
            } catch (error) {
                console.error(error);
            } finally {
                refetch();
                setIsLoading(false);
                onClose();
            }
        })();
    }, [isLoading]);

    const handleActionChange = (key: keyof ActionUpdateComplaint) => {
        if(key ==='mark'){
            setItemSelected(prev => ({
                ...prev,
                ban: false,
                mark: !prev.mark
            }))
        }else if(key==='ban'){
            setItemSelected(prev => ({
                ...prev,
                mark: false,
                ban: !prev.ban
            }))
        }else
        setItemSelected(prev => ({
            ...prev,
            [key]: !prev[key]
        }))
    };

 

    return (
        <React.Fragment >
            <span className="cursor-pointer" onClick={() => {
                if (currentComplaintStatus === 'Pending')
                    onOpen()
            }}>
                <BoxComplaintStatus currComplainStatus={currentComplaintStatus} />
            </span>
            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={() => { onClose() }}>
                <ModalOverlay />
                {
                    isLoading ?
                        <Center>
                            <Spinner size="xl" />
                        </Center>
                        : <ModalContent>
                            <ModalHeader>Cập nhật trạng thái khiếu nại</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody className="flex flex-wrap gap-4">
                                <Textarea
                                    placeholder='Nhập mô tả'
                                    size='sm'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value as string)}
                                />
                                <Text textAlign='left'>Hành động</Text>
                                <Flex w='full' gap={6} ml={4}>
                                    {
                                        ['ban', 'mark'].map((act, index) => (
                                            <Box onClick={() => handleActionChange(act as keyof ActionUpdateComplaint)}
                                                className={` border text-xs w-fit px-5 py-3 rounded-full cursor-pointer ${itemSelected[act as keyof ActionUpdateComplaint] ? 'bg-blue text-white' : 'border-gray-medium text-gray-medium'}`}
                                            >
                                                {act}
                                            </Box>
                                        ))
                                    }
                                </Flex>
                                <br />
                                <Flex className='flex-wrap gap-3'>

                                    <Box
                                        key={'refund'}
                                        onClick={() => handleActionChange('refund' as keyof ActionUpdateComplaint)}
                                        className={`border text-xs w-fit px-5 py-3 rounded-md cursor-pointer ${itemSelected['refund' as keyof ActionUpdateComplaint] ? 'border-blue text-blue' : 'border-gray-medium text-gray-medium'}`}
                                    >
                                        {descriptionActionUpdateComplaint['refund' as keyof ActionUpdateComplaint]}
                                    </Box>


                                </Flex>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme='gray' color='gray' mr={3} onClick={() => { onClose(); }}>
                                    Đóng
                                </Button>
                                <Button
                                    isDisabled={!description}
                                    mr={3}
                                    colorScheme='red'
                                    onClick={async () => {
                                        // TODO
                                        await resolveComplaint({
                                            complainId,
                                            resolvingDescription: description,
                                            resolvingAction: 'reject'
                                        });
                                        onClose()
                                        refetch()
                                    }
                                    }
                                >
                                    Từ chối
                                </Button>
                                <Button
                                    isDisabled={!itemSelected || !description}
                                    colorScheme='blue'
                                    onClick={() => setIsLoading(true)}
                                >
                                    Xác nhận
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                }
            </Modal>
        </React.Fragment >
    );
};

import { BaseBtn, Paragraph, Tittle } from "presentational/atoms";
import { SubmitHandler, useForm } from "react-hook-form";
import { VerticalSpacer } from "presentational/atoms/spacer/VerticalSpacer";
import { ToastContainer, toast } from "react-toastify";
import {
  ActiveCategorySection,
  ErrorItem,
  ImageCategorySection,
  KeywordCategorySection,
  LoadingItem,
  NameAndDescriptionCategorySection,
} from "presentational/organisms";
import { categoryType } from "services/lib/categoriesService/type";
import { categorySchema, useYupValidationResolver } from "validators/yup";
import {
  useGetDetailCategoryQuery,
  useUpdateCategoryMutation,
} from "../business-layer/services/lib/apis";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { baseCdnImage } from "helpers/baseCdnImage";
import { useUpload } from "../business-layer/business-logic/context/useUpload";
import { generateRandomID } from "helpers/generateRandomID";
import { PAGES } from "constants/pages";

const CategoriesEditing = () => {
  const { id } = useParams<{ id: string }>();
  const formResolver = useYupValidationResolver(categorySchema);
  const { data, isFetching, isError } = useGetDetailCategoryQuery(id ?? "id");
  const [updateCategory] = useUpdateCategoryMutation();
  const { onUpload } = useUpload();
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    reset,
  } = useForm<categoryType>({
    resolver: formResolver,
  });

  useEffect(() => {
    reset({ ...data, image: baseCdnImage(data.image) });
  }, [id, data]);

  const notifyError = (messageError?: string) =>
    toast.error(
      messageError
        ? messageError
        : "Lỗi thiếu thông tin hoặc không đúng định dạng"
    );
  const notifySuccess = () => toast.success("Cập nhật danh mục thành công");
  const navigate = useNavigate();
  const onSubmitSuccess: SubmitHandler<categoryType> = async (data) => {
    try {
      let image = data.image;
      if (baseCdnImage(data.image) !== data.image) {
        const { path } = await onUpload({
          uri: data.image,
          name: data.name,
          path: `/category/${generateRandomID()}`,
          type: "JPEG",
        });
        image = path;
      }

      await updateCategory({ ...data, image });
      notifySuccess();
      navigate(PAGES.SERVICE_MANAGEMENT);
    } catch (error) {
      notifyError("Cập nhật danh mục thất bại");
    }
  };

  const onSubmitFail = () => {
    if (errors) {
      const messageError =
        errors.name ?? errors.description ?? errors.keys ?? errors.image;
      notifyError(messageError?.message);
    }
  };

  if (isFetching) {
    return <LoadingItem />;
  }

  if (isError) {
    return <ErrorItem />;
  }

  return (
    <div className="bg-silver p-5 pb-10">
      <ToastContainer />
      <form
        className="bg-white rounded-lg shadow-md p-4"
        onSubmit={handleSubmit(onSubmitSuccess, onSubmitFail)}
      >
        <Tittle align="left" color="black">
          Cập nhật danh mục
        </Tittle>
        <VerticalSpacer size="xl" />
        <div className="addition-container">
          <div className="column-first-addition w-full">
            <NameAndDescriptionCategorySection
              control={control}
              errors={errors}
              initDescription={getValues().description}
            />
          </div>

          <div className="column-second-addition w-full ">
            {/* <ClassCategorySection
              control={control}
              error={errors.className ? errors.className.message : undefined}
            />
            <VerticalSpacer size="m" /> */}
            <ActiveCategorySection control={control} />
          </div>

          <div className="column-first-addition ">
            <div className="border border-red-lightShade border-solid w-full rounded-md p-3">
              <KeywordCategorySection
                control={control}
                errors={errors}
                initKeyword={getValues().keys}
              />
            </div>
          </div>
          <div className="column-first-addition ">
            <ImageCategorySection control={control} errors={errors} />
          </div>
          <div className="column-first-addition mt-5">
            <BaseBtn
              title="Xác nhận cập nhật danh mục"
              color="white"
              isFullWidth
              padding="15px"
              radius="rounded"
              backgroundColor="blueShade-shade"
              type="submit"
              onPress={() => {}}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CategoriesEditing;

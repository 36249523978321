import { useManageServiceRequirements } from "businessLogic/context";
import { TYPE_SPECIAL_ITEMS, selectionType } from "constants/servicesAddition";
import {
  BaseBtn,
  BorderBottomInput,
  NormalInput,
  Paragraph,
  VerticalSpacer,
} from "presentational/atoms";
import { SpecialItemSelect } from "presentational/atoms/select/SpecialItemSelect";
import { infoType } from "services/entities/index";

type specialSelectStepperProps = {
  onChangeTypeInfo: (
    newTypeSpecialItem: selectionType,
    infoIndex: number
  ) => void;
  infoItem: infoType;
  specialSelectId: string;
  optionIndex: number;
  infoIndex: number;
};
export const SpecialSelectStepper = ({
  onChangeTypeInfo,
  infoItem,
  specialSelectId,
  optionIndex,
  infoIndex,
}: specialSelectStepperProps) => {
  const { onChangeValue } = useManageServiceRequirements();
  if(infoItem.type !== 'stepper') return null;

   return (
    <div className="shadow-md border border-gray-light border-solid w-full rounded-md p-6 pr-0 flex items-center justify-between">
      <div className="w-4/5">
        <div className="flex gap-14 justify-start items-end pr-10">
          <SpecialItemSelect
            typeSpecialItemSelected={TYPE_SPECIAL_ITEMS[0]}
            onChangeTypeSpecialItem={(value) =>
              onChangeTypeInfo(value, infoIndex)
            }
          />
          <div className="flex gap-4 items-center w-48">
            <Paragraph color="blueShade-mediumLightShade" fontWeight="semibold">
              Label
            </Paragraph>
            <NormalInput
              value={infoItem.label}
              onChange={(value) =>
                onChangeValue({
                  type: "special-update-stepper-label",
                  serviceRequirementId: specialSelectId,
                  infoIndex,
                  optionIndex,
                  newLabel: value,
                })
              }
              placeholder="Nhập label"
            />
          </div>
          <div className="flex gap-4 items-center w-48">
            <Paragraph color="blueShade-mediumLightShade" fontWeight="semibold">
              Mask
            </Paragraph>
            <NormalInput
              value={infoItem.mask}
              onChange={(value) =>
                onChangeValue({
                  type: "special-update-stepper-mask",
                  serviceRequirementId: specialSelectId,
                  infoIndex,
                  optionIndex,
                  newMask: value,
                })
              }
              placeholder="Nhập mask"
            />
          </div>
        </div>

        <VerticalSpacer size="xxl" />

        <div className="flex gap-10 justify-between items-end">
          <div className="flex gap-4 items-end">
            <Paragraph color="blueShade-mediumLightShade" fontWeight="semibold">
              Min and Max
            </Paragraph>
            <div className="w-16">
              <BorderBottomInput
                size="xs"
                value={
                  infoItem.validation[0].name !== "required"
                    ? infoItem.validation[0].value.toString()
                    : ""
                }
                onChange={(value) =>
                  onChangeValue({
                    type: "special-update-stepper-minValue",
                    serviceRequirementId: specialSelectId,
                    infoIndex,
                    optionIndex,
                    newMinValue: Number(value),
                  })
                }
                placeholder="Nhập min"
              />
            </div>
            <Paragraph>and</Paragraph>
            <div className="w-16">
              <BorderBottomInput
                size="xs"
                value={
                  infoItem.validation[1].name !== "required"
                    ? infoItem.validation[1].value.toString()
                    : ""
                }
                onChange={(value) =>
                  onChangeValue({
                    type: "special-update-stepper-maxValue",
                    serviceRequirementId: specialSelectId,
                    infoIndex,
                    optionIndex,
                    newMaxValue: Number(value),
                  })
                }
                placeholder="Nhập max"
              />
            </div>
          </div>

          <div className="flex gap-4 items-center">
            <Paragraph color="blueShade-mediumLightShade" fontWeight="semibold">
              Default Value
            </Paragraph>
            <div className="w-36">
              <NormalInput
                value={infoItem.defaultValue.toString()}
                onChange={(value) =>
                  onChangeValue({
                    type: "special-update-stepper-defaultValue",
                    serviceRequirementId: specialSelectId,
                    infoIndex,
                    optionIndex,
                    newDefaultValue: Number(value),
                  })
                }
                placeholder="Nhập default value"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="h-full flex-1 w-fit gap-5 flex items-center justify-center ">
        <BaseBtn
          title=""
          onPress={() => {
            onChangeValue({
              type: "special-update-option-info-duplicate",
              infoIndex,
              optionIndex,
              serviceRequirementId: specialSelectId,
            });
          }}
          icon={<i className="fi fi-rr-duplicate" />}
        />
        <BaseBtn
          title=""
          onPress={() => {
            onChangeValue({
              type: "special-update-option-info-remove",
              infoIndex,
              optionIndex,
              serviceRequirementId: specialSelectId,
            });
          }}
          icon={<i className="fi fi-ss-trash" />}
        />
      </div>
    </div>
  );
};

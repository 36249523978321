import { useManageServiceRequirements } from "businessLogic/context";
import {
  TYPE_SERVICES_ADDITION,
  TYPE_SPECIAL_ITEMS,
  serviceRequirementOptions,
} from "constants/servicesAddition";
import { selectionType } from "constants/servicesAddition";
import { initSpecialOptionInfoStepper } from "helpers/InitServiceRequirement";

import {
  BaseBtn,
  BorderBottomInput,
  Divider,
  Paragraph,
  TypeServiceSelect,
  VerticalSpacer,
} from "presentational/atoms";
import {
  SpecialSelectButtonGroup,
  SpecialSelectStepper,
} from "presentational/molecules";
import React, { useMemo } from "react";
import {
  IUIServiceRequirement,
  optionType,
} from "services/entities/index";

type servicesSpecialSelectCreationProps = {
  curretnServiceRequirement: IUIServiceRequirement;
  errors: string[];
};

const ServiceSpecialSelectItem = ({
  specialOption,
  specialSelectId,
  optionIndex,
}: {
  specialOption: optionType;
  specialSelectId: string;
  optionIndex: number;
}) => {
  const { onChangeValue } = useManageServiceRequirements();
  const onChangeItem = (newOption: selectionType, infoIndex: number) => {
    onChangeValue({
      type: "special-update-option-info-type",
      newType: newOption.value,
      serviceRequirementId: specialSelectId,
      optionIndex,
      infoIndex,
    });
  };

  const onAddInfo = () => {
    onChangeValue({
      type: "special-add-option-info",
      serviceRequirementId: specialSelectId,
      optionIndex,
      newInfo: initSpecialOptionInfoStepper,
    });
  };
  return (
    <div className="border border-black border-solid w-full rounded-md p-3">
      <div className="flex gap-20 justify-between">
        <div className="w-1/3">
          <BorderBottomInput
            value={specialOption.name}
            onChange={(value) => {
              onChangeValue({
                type: "special-update-option-name",
                newName: value,
                serviceRequirementId: specialSelectId,
                optionIndex,
              });
            }}
            placeholder="Nhập tiêu đề"
          />
        </div>
        <div className="flex items-center gap-2">
          <BaseBtn
            icon={<i className="fi fi-br-plus" />}
            title="Thêm"
            onPress={onAddInfo}
          />
          <Divider color="block" direction="vertical" />
          <BaseBtn
            icon={<i className="fi fi-br-cross" />}
            title="Xóa"
            onPress={() => {
              onChangeValue({
                type: "special-remove-option",
                serviceRequirementId: specialSelectId,
                optionIndex,
              });
            }}
          />
        </div>
      </div>
      <VerticalSpacer size="xl" />
      {specialOption.info.map((item, infoIndex) => {
        if (item.type === TYPE_SPECIAL_ITEMS[0].value) {
          return (
            <React.Fragment key={infoIndex}>
              <SpecialSelectStepper
                key={infoIndex}
                onChangeTypeInfo={onChangeItem}
                specialSelectId={specialSelectId}
                optionIndex={optionIndex}
                infoIndex={infoIndex}
                infoItem={item}
              />
              <VerticalSpacer size="xl" />
            </React.Fragment>
          );
        }

        if (item.type === TYPE_SPECIAL_ITEMS[1].value) {
          return (
            <>
              <SpecialSelectButtonGroup
                key={infoIndex}
                onChangeTypeInfo={onChangeItem}
                specialSelectId={specialSelectId}
                optionIndex={optionIndex}
                infoIndex={infoIndex}
                infoItem={item}
              />
              <VerticalSpacer size="xl" />
            </>
          );
        }

        return <div key={infoIndex} />;
      })}
    </div>
  );
};

export const ServicesSpecialSelectCreation = ({
  curretnServiceRequirement,
  errors,
}: servicesSpecialSelectCreationProps) => {
  const { onChangeType, onChangeValue } = useManageServiceRequirements();

  const typeSelected: serviceRequirementOptions = useMemo(() => {
    return (
      TYPE_SERVICES_ADDITION.find(
        (service) =>
          service.value === curretnServiceRequirement.inputMethod.method.name
      ) ?? TYPE_SERVICES_ADDITION[0]
    );
  }, [curretnServiceRequirement]);
  return (
    <div>
      <div className="flex gap-20 justify-end items-end">
        <TypeServiceSelect
          typeServiceSelected={typeSelected}
          onChangeType={onChangeType}
          idServiceRequirement={curretnServiceRequirement.id}
        />
      </div>

      <VerticalSpacer size="xxl" />
      {curretnServiceRequirement.inputMethod.method.name ===
        'special_itemselect' &&
        curretnServiceRequirement.inputMethod.method.options.map(
          (option, optionIndex) => (
            <React.Fragment key={optionIndex}>
              <ServiceSpecialSelectItem
                optionIndex={optionIndex}
                specialOption={option}
                specialSelectId={curretnServiceRequirement.id}
              />
              <VerticalSpacer size="xl" />
            </React.Fragment>
          )
        )}

      <VerticalSpacer size="m" />
      <div>
        <div className={`${errors.length > 0 ? "block mt-1" : "hidden"}`}>
          <Paragraph color="rose" size="xs" fontWeight="semibold">
            Lỗi: {errors.join(", ")}
          </Paragraph>
        </div>
      </div>
      <VerticalSpacer size="m" />
      <BaseBtn
        backgroundColor="green-medium"
        color="white"
        title="Thêm special item"
        onPress={() => {
          onChangeValue({
            type: "special-add-option",
            newOption: {
              name: "",
              info: [initSpecialOptionInfoStepper],
            },
            serviceRequirementId: curretnServiceRequirement.id,
          });
        }}
        padding="5px 25px"
        radius="rounded"
        icon={<i className="fi fi-br-plus text-white mr-3" />}
      />
    </div>
  );
};

import { ApiHelper, detoiApi } from "businessLogic/redux/adminApi";
import { serviceType } from "../servicesService/type";

const api = detoiApi.injectEndpoints({
    endpoints: (builder) => ({
        banAccount: builder.mutation({
            query: (id: string) => ApiHelper.put(`/api/v1/account/ban`, { data: { id } }),
        }),
        unbanAccount: builder.mutation({
            query: (id: string) => ApiHelper.put(`/api/v1/account/unban`, { data: { id } }),
        }),
    }),
});

export const {
    useBanAccountMutation,
    useUnbanAccountMutation,
    endpoints: { banAccount, unbanAccount }
} = api;
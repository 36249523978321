import {
  Box,
} from "@chakra-ui/react";
import { Tittle } from "presentational/atoms";
import React, { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ICustomerAccountDetail } from "services/entities";
import {
  CustomerPersionalInfoSection,
  CustomerAddressSection,
  CustomerStatisticSection,
  CustomerOrderedSection,
  ErrorItem,
  LoadingItem,
} from "presentational/organisms";
import { useGetDetailCustomerQuery } from "../business-layer/services/lib/apis";


export default function UserDetail() {
  const { id } = useParams<{ id: string }>();
  const { data, isFetching, isError } = useGetDetailCustomerQuery(id ?? "id");

  const [customerDetail, setCustomerDetail] =
    useState<ICustomerAccountDetail>();
  useEffect(() => {
    if (!data || isError) return;
    setCustomerDetail(data);
  }, [id, data]);

  if (isFetching) {
    return <LoadingItem />;
  }

  if (isError || !customerDetail) {
    return <ErrorItem />;
  }

  return (
    <div className=" p-5 pb-10">
      <Box className="border-solid border-b-2 pb-5 mb-10">
        <Tittle align="left" color="black">
          Hồ sơ khách hàng
        </Tittle>
      </Box>

      <CustomerPersionalInfoSection customerDetail={customerDetail} />
      <Box my={10} />
      <CustomerAddressSection customerDetail={customerDetail} />
      <Box my={10} />
      {/* <CustomerStatisticSection />
      <Box my={10} /> */}
      <CustomerOrderedSection customerDetail={customerDetail}/>
    </div>
  );
}

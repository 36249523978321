import {configureStore} from '@reduxjs/toolkit'
import {useDispatch} from 'react-redux'
import { detoiApi } from './adminApi'
import rootReducer from './reducers'

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
	getDefaultMiddleware().concat(detoiApi.middleware),
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store

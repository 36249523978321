import { ApiHelper, detoiApi } from "businessLogic/redux/adminApi";
import { verifyFreelancerType } from "../verify/type";

const api = detoiApi.injectEndpoints({
    endpoints: (builder) => ({
        verifyFreelancer: builder.mutation({
            query: (data: verifyFreelancerType) => ApiHelper.post('/api/v1/auth/verify-freelance', { data }),
        }),
    }),
});

export const {
    useVerifyFreelancerMutation,
    endpoints: { verifyFreelancer }
} = api;
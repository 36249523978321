import { countWords } from "helpers/CountWord";
import {
  NormalInput,
  NormalTextArea,
  Paragraph,
  Subtitle,
  VerticalSpacer,
} from "presentational/atoms";
import React, { useEffect, useMemo, useState } from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { serviceType } from "services/lib/servicesService/type";

type props = {
  control: Control<any>;
  errors: FieldErrors<serviceType>;
  initName?: string;
  initDescription?: string;
};
export const NameAndDescriptionSection = ({
  control,
  errors,
  initName,
  initDescription,
}: props) => {
  const [description, setDescription] = useState<string>("");
  const numberWordDescription = useMemo(() => {
    return countWords(description);
  }, [description]);

  useEffect(() => {
    if (initDescription) {
      setDescription(initDescription);
    }
  }, [initName, initDescription]);
  return (
    <React.Fragment>
      <Subtitle color="black">Tên và mô tả</Subtitle>
      <VerticalSpacer size="l" />

      <div className="border border-red-lightShade border-solid w-full rounded-md p-3 pb-5">
        <Paragraph color="blueShade-mediumLightShade" fontWeight="semibold">
          Tên dịch vụ
        </Paragraph>
        <VerticalSpacer size="m" />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <NormalInput
              value={field.value}
              onChange={field.onChange}
              placeholder="Nhập tên dịch vụ"
            />
          )}
        />
        <div className={`${errors.name ? "block mt-1" : "hidden"}`}>
          <Paragraph color="rose" size="xs" fontWeight="semibold">
            {errors.name?.message}
          </Paragraph>
        </div>
        <VerticalSpacer size={errors.name ? "xs" : "l"} />
        <Paragraph color="blueShade-mediumLightShade" fontWeight="semibold">
          Mô tả dịch vụ (10 - 60 words)
        </Paragraph>
        <VerticalSpacer size="m" />
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <NormalTextArea
              rows={5}
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                setDescription(value);
              }}
              placeholder="Nhập mô tả dịch vụ"
            />
          )}
        />{" "}
        <div className="flex justify-between">
          <Paragraph
            color={
              numberWordDescription < 10 || numberWordDescription > 60
                ? "rose"
                : "gray-medium"
            }
            size="xs"
            fontWeight="semibold"
          >
            Độ dài hiện tại: {numberWordDescription}
          </Paragraph>{" "}
          <div>
            {numberWordDescription > 60 ? (
              <Paragraph color={"rose"} size="xs" fontWeight="semibold">
                Số lượng kí tự không được vượt quá 60
              </Paragraph>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

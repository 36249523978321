import { Header, Sidebar } from "presentational/organisms";
import { ReactNode } from "react";

export const DashboardTemplate = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex w-full min-h-screen justify-between relative">
      <Sidebar />

      {/* Navbar & Main Content */}
      <div className="h-full w-full">
        {/* Main Content */}
        <main className={` h-full flex-none transition-all `}>
          {/* Routes */}
          <div className="h-full w-full ">
            <Header />
            <div className="pt-5s mx-auto h-[90vh] pb-0 overflow-y-auto max-w-[83vw]">
              {children}
            </div>
            {/* <div className="p-3"> <Footer /> </div> */}
          </div>
        </main>
      </div>
    </div>
  );
};

import {
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
  } from "@chakra-ui/react";
  
  type bodyContentType = {
    type: string
    label: string,
    value: string
  }
  type tableRequestOrderProps = {
    titleContent: string[];
    bodyContent: bodyContentType[];
  };
  export const TableRequestOrder = ({
    titleContent,
    bodyContent,
  }: tableRequestOrderProps) => {
    return (
      <TableContainer border="1px">
        <Table variant="unstyled">
          <Thead bgColor="#A69AED" color="white">
            <Tr borderBottom="1px">
              {titleContent.map((title) => (
                <Th textAlign="center">{title}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {bodyContent.map((bodyItem, index) => (
              <Tr
                borderBottom={index !== bodyContent.length - 1 ? "1px" : "0px"}
                bgColor={index % 2 === 1 ? "#E8EAEA" : "transparent"}
              >
                  <Td textAlign="center">{bodyItem.label}</Td>
                  <Td textAlign="center">{bodyItem.value}</Td>

              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    );
  };
  
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Checkbox,
  Box,
  Center,
} from "@chakra-ui/react";
import { SERVICES_STATUS } from "constants/serviceOrder";
import { Paragraph } from "presentational/atoms";
import { IOrderDetail } from "services/entities";
import { ReactNode } from 'react'
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import { formatCurrencyVND } from "helpers/MoneyConverter";

const HEADERS_TABLE = [
  "Tên khách hàng",
  "Trạng thái",
  "Dịch vụ",
  // "Danh mục",
  // "Khách hàng",
  // "Freelancer",
  "Giá",
  "Ngày tạo đơn",
];

type OrderTableProps = {
  orders: IOrderDetail[];
  paging: ReactNode
};

export const OrderTable = ({ orders, paging }: OrderTableProps) => {
  const navigate = useNavigate();
  const goToOrderDetail = (orderId: string) => {
    navigate(`${PAGES.SERVICE_ORDER}/${orderId}`);
  }
  return (
    <TableContainer className="bg-white w-full rounded-md shadow-md">
      <Table className="max-w-full">
        <Thead className="bg-blueShade-lightShade max-w-full ">
          <Tr >
            {HEADERS_TABLE.map((item) => (
              <Th textTransform="none" textAlign={"center"} paddingX="0"

              >
                <Paragraph fontWeight="bold" size="xs" align="center">
                  {item}
                </Paragraph>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {orders.map((order) => {
            const currStatus = SERVICES_STATUS.find(
              (status) => status.Id === order.serviceStatus
            );
            return (
              <Tr className="hover:bg-blueShade-lightShade cursor-pointer" onClick={() => goToOrderDetail(order.id)}>
                <Td border="none" className="text-sm">{order.customer?.fullName ?? ""}</Td>
                <Td border="none" isTruncated textAlign="center">
                  <Box
                    bg={currStatus?.backgroundColor}
                    color={currStatus?.textColor}
                    fontWeight="medium"
                    borderRadius="full"
                    textAlign="center"
                    py={1}
                    px={3}
                    className="text-xs"
                  >
                    {currStatus?.Name ?? ""}
                  </Box>
                </Td>
                <Td border="none" className="text-sm" textAlign="center"> {order.serviceTypes[0].name}</Td>

                {/* <Td border="none" isTruncated className="text-sm" textAlign="center">
                  {order.serviceTypes[0].name}
                </Td> */}
                {/* <Td border="none" isTruncated className="text-sm" textAlign="center">
                  {order.customer?.fullName}
                </Td>
                <Td border="none" isTruncated className="text-sm" textAlign="center">
                  {order.freelancer?.fullName}
                </Td> */}
                <Td border="none" className="text-sm" textAlign="center"> {currStatus && (currStatus.Name === "Đang ghép cặp" || currStatus.Name === "Đơn vừa tạo") ? "Chưa chốt giá" : formatCurrencyVND(order.estimatedPrice)}</Td>
                <Td border="none" className="text-sm" textAlign="center">{new Date(order.createdAt).toLocaleDateString() + " - " + new Date(order.createdAt).toLocaleTimeString()}</Td>
              </Tr>
            );
          })}
        </Tbody>
        {orders.length < 6 &&
          [...Array(6 - orders.length)].map((_, index) => (
            <div
              key={`blank-${index}`}
              className="h-12 w-full"
            ></div>
          ))}
      </Table>
      {paging}
    </TableContainer>
  );
};
import {
  ICustomerAccountDetail,
  IFreelancerAccountDetail,
} from "business-layer/services/entities";

export const customerList: ICustomerAccountDetail[] = [
  // {
  //   id: "0",
  //   avatar: "@assets/user-avt (1).png",
  //   countryCode: "84",
  //   fullName: "Nguyễn Ba Phương",
  //   dateOfBirth: "2002-8-28",
  //   phone: "+84337839146",
  //   role: "customer",
  //   gender: "Male",
  //   isActive: true
  // },
  // {
  //   id: "1",
  //   avatar: "@assets/user-avt (2).png",
  //   countryCode: "84",
  //   fullName: "Blueberry Sensei đẹp trai",
  //   dateOfBirth: "2002-8-28",
  //   phone: "+84337839146",
  //   role: "customer",
  //   gender: "Male",
  //   isActive: true
  // },
  // {
  //   id: "3",
  //   avatar: "@assets/user-avt (3).png",
  //   countryCode: "84",
  //   fullName: "Nguyễn Ba Phương",
  //   dateOfBirth: "2002-8-28",
  //   phone: "+84337839146",
  //   role: "customer",
  //   gender: "Male",
  //   isActive: true
  // },
  // {
  //   id: "4",
  //   avatar: "@assets/user-avt (4).png",
  //   countryCode: "84",
  //   fullName: "Blueberry Sensei đẹp trai",
  //   dateOfBirth: "2002-8-28",
  //   phone: "+84337839146",
  //   role: "customer",
  //   gender: "Male",
  //   isActive: true
  // },
  // {
  //   id: "5",
  //   avatar: "@assets/user-avt (1).png",
  //   countryCode: "84",
  //   fullName: "Nguyễn Ba Phương",
  //   dateOfBirth: "2002-8-28",
  //   phone: "+84337839146",
  //   role: "customer",
  //   gender: "Male",
  //   isActive: true
  // },
  // {
  //   id: "6",
  //   avatar: "@assets/user-avt (2).png",
  //   countryCode: "84",
  //   fullName: "Blueberry Sensei đẹp trai",
  //   dateOfBirth: "2002-8-28",
  //   phone: "+84337839146",
  //   role: "customer",
  //   gender: "Male",
  //   isActive: true
  // },
  // {
  //   id: "7",
  //   avatar: "@assets/user-avt (3).png",
  //   countryCode: "84",
  //   fullName: "Nguyễn Ba Phương",
  //   dateOfBirth: "2002-8-28",
  //   phone: "+84337839146",
  //   role: "customer",
  //   gender: "Male",
  //   isActive: true
  // },
  // {
  //   id: "8",
  //   avatar: "@assets/user-avt (4).png",
  //   countryCode: "84",
  //   fullName: "Blueberry Sensei đẹp trai",
  //   dateOfBirth: "2002-8-28",
  //   phone: "+84337839146",
  //   role: "customer",
  //   gender: "Male",
  //   isActive: true
  // },
  // {
  //   id: "9",
  //   avatar: "@assets/user-avt (1).png",
  //   countryCode: "84",
  //   fullName: "Nguyễn Ba Phương",
  //   dateOfBirth: "2002-8-28",
  //   phone: "+84337839146",
  //   role: "customer",
  //   gender: "Male",
  //   isActive: true
  // },
  // {
  //   id: "10",
  //   avatar: "@assets/user-avt (2).png",
  //   countryCode: "84",
  //   fullName: "Nguyễn Ba Phương",
  //   dateOfBirth: "2002-8-28",
  //   phone: "+84337839146",
  //   role: "customer",
  //   gender: "Male",
  //   isActive: true
  // },
  // {
  //   id: "11",
  //   avatar: "@assets/user-avt (3).png",
  //   countryCode: "84",
  //   fullName: "Nguyễn Ba Phương",
  //   dateOfBirth: "2002-8-28",
  //   phone: "+84337839146",
  //   role: "customer",
  //   gender: "Male",
  //   isActive: true
  // },
];

export const freelancerList: IFreelancerAccountDetail[] = [
  //   {
  //   accountId: "1",
  //   account: {
  //     id: "1",
  //     avatar: "https://variety.com/wp-content/uploads/2021/04/Avatar.jpg?w=800",
  //     countryCode: "84",
  //     dateOfBirth: null,
  //     fullName: "John Doe",
  //     phone: "+1234567890",
  //     role: "Freelancer",
  //     gender: "Male",
  //     isActive: true
  //   },
  //   address: {
  //     id: "1",
  //     addressLine: "123 Main St",
  //     ward: "Ward 1",
  //     district: "District A",
  //     province: "Province X",
  //     country: "Country Y",
  //     lat: 123.456,
  //     lon: 789.012,
  //   },
  //   rating: 4.5,
  //   totalReviewCount: 20,
  //   balance: 1000,
  //   orderCount: 15,
  //   loveCount: 50,
  //   positiveReviewCount: 18,
  //   identityNumber: "ID123456",
  //   isTeam: false,
  //   teamMemberCount: 0,
  //   skills: [
  //     {
  //       id: "1",
  //       name: "Web Development",
  //       description: "HTML, CSS, JavaScript",
  //     },
  //     {
  //       id: "2",
  //       name: "Graphic Design",
  //       description: "Photoshop, Illustrator",
  //     },
  //   ],
  //   description: "Experienced web developer and graphic designer.",
  // },
  // {
  //   accountId: "2",
  //   account: {
  //     id: "2",
  //     dateOfBirth: null,
  //     avatar: "https://variety.com/wp-content/uploads/2021/04/Avatar.jpg?w=800",
  //     countryCode: "84",
  //     fullName: "Jane Smith",
  //     phone: "+9876543210",
  //     role: "Freelancer",
  //     gender: "Male",
  //     isActive: true
  //   },
  //   address: {
  //     id: "2",
  //     addressLine: "456 Elm St",
  //     ward: "Ward 2",
  //     district: "District B",
  //     province: "Province Y",
  //     country: "Country Z",
  //     lat: 456.789,
  //     lon: 987.654,
  //   },
  //   rating: 4.2,
  //   totalReviewCount: 25,
  //   balance: 1500,
  //   orderCount: 18,
  //   loveCount: 40,
  //   positiveReviewCount: 20,
  //   identityNumber: "ID987654",
  //   isTeam: false,
  //   teamMemberCount: 0,
  //   skills: [
  //     {
  //       id: "3",
  //       name: "Mobile App Development",
  //       description: "React Native, Flutter",
  //     },
  //     { id: "4", name: "Content Writing", description: "SEO, Blogging" },
  //   ],
  //   description: "Skilled mobile app developer and content writer.",
  // },
  // {
  //   accountId: "3",
  //   account: {
  //     id: "3",
  //     dateOfBirth: null,
  //     avatar: "https://img.lovepik.com/photo/50118/2263.jpg_wh860.jpg",
  //     countryCode: "84",
  //     fullName: "David Johnson",
  //     phone: "+1122334455",
  //     role: "Freelancer",
  //     gender: "Male",
  //     isActive: true
  //   },
  //   address: {
  //     id: "3",
  //     addressLine: "789 Oak St",
  //     ward: "Ward 3",
  //     district: "District C",
  //     province: "Province Z",
  //     country: "Country X",
  //     lat: 789.012,
  //     lon: 345.678,
  //   },
  //   rating: 4.8,
  //   totalReviewCount: 30,
  //   balance: 2000,
  //   orderCount: 20,
  //   loveCount: 60,
  //   positiveReviewCount: 25,
  //   identityNumber: "ID112233",
  //   isTeam: false,
  //   teamMemberCount: 0,
  //   skills: [
  //     { id: "5", name: "Data Analysis", description: "Python, R" },
  //     { id: "6", name: "Translation", description: "English, Spanish" },
  //   ],
  //   description: "Experienced data analyst and translator.",
  // },
  // {
  //   accountId: "4",
  //   account: {
  //     id: "4",
  //     dateOfBirth: null,
  //     avatar: "https://img.lovepik.com/photo/50118/2263.jpg_wh860.jpg",
  //     countryCode: "84",
  //     fullName: "Emily Brown",
  //     phone: "+9988776655",
  //     role: "Freelancer",
  //     gender: "Male",
  //     isActive: true
  //   },
  //   address: {
  //     id: "4",
  //     addressLine: "101 Pine St",
  //     ward: "Ward 4",
  //     district: "District D",
  //     province: "Province A",
  //     country: "Country B",
  //     lat: 101.202,
  //     lon: 303.405,
  //   },
  //   rating: 4.6,
  //   totalReviewCount: 28,
  //   balance: 1800,
  //   orderCount: 22,
  //   loveCount: 55,
  //   positiveReviewCount: 23,
  //   identityNumber: "ID998877",
  //   isTeam: false,
  //   teamMemberCount: 0,
  //   skills: [
  //     {
  //       id: "7",
  //       name: "Social Media Marketing",
  //       description: "Facebook Ads, Instagram Marketing",
  //     },
  //     {
  //       id: "8",
  //       name: "Video Editing",
  //       description: "Adobe Premiere, Final Cut Pro",
  //     },
  //   ],
  //   description: "Expert in social media marketing and video editing.",
  // },
  // {
  //   accountId: "5",
  //   account: {
  //     id: "5",
  //     dateOfBirth: null,
  //     avatar: "https://img.lovepik.com/photo/50118/2263.jpg_wh860.jpg",
  //     countryCode: "84",
  //     fullName: "Sophia Wilson",
  //     phone: "+1122334455",
  //     role: "Freelancer",
  //     gender: "Male",
  //     isActive: true
  //   },
  //   address: {
  //     id: "5",
  //     addressLine: "202 Maple St",
  //     ward: "Ward 5",
  //     district: "District E",
  //     province: "Province B",
  //     country: "Country C",
  //     lat: 202.304,
  //     lon: 506.708,
  //   },
  //   rating: 4.4,
  //   totalReviewCount: 26,
  //   balance: 1600,
  //   orderCount: 19,
  //   loveCount: 45,
  //   positiveReviewCount: 21,
  //   identityNumber: "ID112233",
  //   isTeam: false,
  //   teamMemberCount: 0,
  //   skills: [
  //     {
  //       id: "9",
  //       name: "Illustration",
  //       description: "Digital Illustration, Character Design",
  //     },
  //     {
  //       id: "10",
  //       name: "Photography",
  //       description: "Portrait Photography, Landscape Photography",
  //     },
  //   ],
  //   description: "Talented illustrator and photographer.",
  // },
  // {
  //   accountId: "6",
  //   account: {
  //     id: "5",
  //     dateOfBirth: null,
  //     avatar: "https://img.lovepik.com/photo/50118/2263.jpg_wh860.jpg",
  //     countryCode: "84",
  //     fullName: "Sophia Wilson",
  //     phone: "+1122334455",
  //     role: "Freelancer",
  //     gender: "Male",
  //     isActive: true
  //   },
  //   address: {
  //     id: "5",
  //     addressLine: "202 Maple St",
  //     ward: "Ward 5",
  //     district: "District E",
  //     province: "Province B",
  //     country: "Country C",
  //     lat: 202.304,
  //     lon: 506.708,
  //   },
  //   rating: 4.4,
  //   totalReviewCount: 26,
  //   balance: 1600,
  //   orderCount: 19,
  //   loveCount: 45,
  //   positiveReviewCount: 21,
  //   identityNumber: "ID112233",
  //   isTeam: false,
  //   teamMemberCount: 0,
  //   skills: [
  //     {
  //       id: "9",
  //       name: "Illustration",
  //       description: "Digital Illustration, Character Design",
  //     },
  //     {
  //       id: "10",
  //       name: "Photography",
  //       description: "Portrait Photography, Landscape Photography",
  //     },
  //   ],
  //   description: "Talented illustrator and photographer.",
  // },
];
